import { Select } from '@arco-design/web-react';
import { App, IUI, Text } from 'leafer-ui';
import { useState, useMemo, useCallback } from 'react';

import { getValidText } from '@/components/Editor/utils';

const sizeList = [8, 9, 10, 10.5, 11, 12, 14, 16, 18, 20, 24, 28, 32, 36, 40, 44, 48, 54, 60, 66, 72, 80, 88, 96, 104, 112, 120, 128, 136, 144, 152, 160, 168, 176, 184, 192];

const radio = 0.75;

interface EditFontSizeProps {
	target: IUI;
	app: App;
}

export default function EditFontSize({ target, app }: EditFontSizeProps) {
	const textScale = useMemo(() => target.data?.textScale || 1, [target]);
	const [size, setSize] = useState(() => {
		const text = getValidText(target);
		return Math.round((text?.fontSize ? text?.fontSize * radio : 18) * textScale * 10) / 10;
	});

	const handleChange = useCallback(
		(value: number) => {
			target.children?.forEach((item) => {
				if (item.tag == 'Text') {
					const event = item as Text;
					event.fontSize = Math.round((value / textScale / radio) * 10) / 10;
					event.data.fontSize = value;
				}
			});
			setSize(value);
			app.emit('changeData');
		},
		[app, target, textScale]
	);

	return (
		<Select
			style={{ width: 80 }}
			size="small"
			// bordered={false}
			options={sizeList}
			value={size}
			onChange={handleChange}
		/>
	);
}
