import fetch from '@/common/fetch';

// 列表
export const getMediaList = (param: any) =>
	fetch({
		url: '/res/v1/library/media/list',
		params: param,
		method: 'get'
	});

// 添加
export const addMedia = (data: any) =>
	fetch({
		url: '/res/v1/library/media',
		data,
		method: 'post'
	});

// 删除
export const delMedia = (ids: string) =>
	fetch({
		url: `/res/v1/library/media/${ids}`,
		method: 'delete'
	});

// 同步
export const postSyncMediaProd = (data: any) =>
	fetch({
		url: `/res/v1/library/media/sync/`,
		method: 'post',
		data,
		timeout: 1200000
	});
