import { useNavigate } from 'react-router-dom';
import { Menu } from '@arco-design/web-react';
import { IconHome, IconCalendar, IconPalette, IconUser, IconSettings, IconGift, IconOrderedList } from '@arco-design/web-react/icon';
import { useEffect, useState } from 'react';

export default (props: any) => {
	const { activeKey = '/' } = props;
	const navigate = useNavigate();
	const MenuItem = Menu.Item;
	const SubMenu = Menu.SubMenu;

	const [openKeys, setOpenKeys] = useState<string[]>([]);

	const onSubMeunClick = (key: string) => {
		const idx = openKeys.indexOf(key);
		if (idx > -1) {
			openKeys.splice(idx, 1);
		} else {
			openKeys.push(key);
		}
		setOpenKeys([...openKeys]);
	};

	useEffect(() => {
		const keys = activeKey.split('/');
		if (keys[1]) {
			setOpenKeys([`/${keys[1]}`]);
		}
	}, [activeKey]);

	return (
		<Menu theme="dark" openKeys={openKeys} selectedKeys={[activeKey]} onClickMenuItem={(key: string) => navigate(key)} onClickSubMenu={onSubMeunClick} style={{ width: '100%' }}>
			<MenuItem key="/">
				<IconHome />
				首页
			</MenuItem>
			<SubMenu
				key="/layout"
				title={
					<span>
						<IconCalendar />
						官方版式库
					</span>
				}>
				<MenuItem key="/layout/slides">版式库管理</MenuItem>
				<MenuItem key="/layout/template">模版管理</MenuItem>
				<MenuItem key="/layout/temp-type">模板类型</MenuItem>
				<MenuItem key="/layout/style">版式风格</MenuItem>
				<MenuItem key="/layout/structure">版式结构</MenuItem>
				<MenuItem key="/layout/colors">版式颜色</MenuItem>
				<MenuItem key="/layout/industry">行业类目</MenuItem>
			</SubMenu>
			<SubMenu
				key="/theme"
				title={
					<span>
						<IconPalette />
						主题管理
					</span>
				}>
				<MenuItem key="/theme/list">主题列表</MenuItem>
				<MenuItem key="/theme/colors">主题颜色</MenuItem>
				<MenuItem key="/theme/fonts">主题字体</MenuItem>
			</SubMenu>
			<SubMenu
				key="/resource"
				title={
					<span>
						<IconSettings />
						资源管理
					</span>
				}>
				<MenuItem key="/resource/fonts">字体管理</MenuItem>
				<MenuItem key="/resource/hotWorks">AI热词管理</MenuItem>
				<MenuItem key="/resource/gallery">图库管理</MenuItem>
			</SubMenu>
			<MenuItem key="/customer">
				<IconUser />
				客户管理
			</MenuItem>
			<MenuItem key="/order">
				<IconOrderedList />
				订单管理
			</MenuItem>
			<MenuItem key="/activity">
				<IconGift />
				活动管理
			</MenuItem>
			<SubMenu
				key="/system"
				title={
					<span>
						<IconSettings />
						系统管理
					</span>
				}>
				<MenuItem key="/system/manage">管理员</MenuItem>
			</SubMenu>
		</Menu>
	);
};
