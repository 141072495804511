import { useContext, useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useUrlState from '@ahooksjs/use-url-state';
import axios from 'axios';
import _ from 'lodash';
import { Table, Form, Input, Space, Button, Grid, Popconfirm, Image, Message } from '@arco-design/web-react';
import { IconSearch, IconPlus, IconSafe } from '@arco-design/web-react/icon';
import { GlobalContext } from '@/hooks/context';

import { getLayoutList, postlayoutSyncPre, postLayoutCloneToProd } from '@/api/layoutNew';

import { decryptBase64 } from '@shock/tool';
import { formatDate, getSessionStorage, delSessionStorage } from '@/utils';

import AuthModal from '../component/loginProd';

const envAppMode = import.meta.env.VITE_APP_MODE;

export default () => {
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const FormItem = Form.Item;
	const Row = Grid.Row;
	const Col = Grid.Col;
	const { cdnUrl } = useContext(GlobalContext);

	const [searchParams] = useSearchParams();
	const urlPage: any = searchParams.get('page');
	const urlId = searchParams.get('id');
	const urlKey = searchParams.get('keywords');

	const columns: any = [
		{
			title: 'ID',
			dataIndex: 'id',
			fixed: 'left',
			width: 80
		},
		{
			title: '缩略图',
			key: 'preview',
			fixed: 'left',
			width: 100,
			render: (_: any, record: any) => {
				let process = '';
				if (record.preview.indexOf('?') > -1) {
					process = '&x-oss-process=image/resize,m_fill,w_160,h_90';
				} else {
					process = '?x-oss-process=image/resize,m_fill,w_160,h_90';
				}
				return (
					<img
						style={{ width: 80, cursor: 'pointer' }}
						src={cdnUrl + record.preview + process}
						onClick={() => {
							setVisibleSrc(cdnUrl + record.preview);
							setVisible(true);
						}}
					/>
				);
			}
		},
		{
			title: '版式库名称',
			width: 300,
			dataIndex: 'name'
		},
		{
			title: '版式数量',
			dataIndex: 'quantity',
			width: 100
		},
		// {
		// 	title: '扩展',
		// 	key: 'extFlag',
		// 	width: 160,
		// 	render: (_: any, record: any) => (
		// 		<>
		// 			{record.status == 1 ? (
		// 				<Button type="primary" status="success">
		// 					立即扩展
		// 				</Button>
		// 			) : (
		// 				<Tag color="#86909c">无须扩展</Tag>
		// 			)}
		// 		</>
		// 	)
		// },
		{
			title: '提交人',
			dataIndex: 'creatorName',
			width: 120
		},
		{
			title: '创建时间',
			key: 'createTime',
			width: 180,
			render: (_: any, record: any) => <>{formatDate(record.createTime)}</>
		},
		{
			title: '操作',
			width: 190,
			fixed: 'right',
			key: 'action',
			render: (_: any, record: any) => (
				<>
					<Button type="text" onClick={() => navigate(`/layout/slides/${record.id}`)}>
						版式列表
					</Button>
					{envAppMode != 'prod' && (
						<>
							<Button
								type="text"
								onClick={() =>
									navigate(`/editor/template/add`, {
										state: {
											libId: record.id
										}
									})
								}>
								创建模版
							</Button>
							<Button type="text" onClick={() => navigate(`/editor/layout/append/${record.id}`)}>
								追加版式
							</Button>
							<Button type="text" onClick={() => navigate(`/editor/layout/edit/${record.id}`)}>
								编辑版式库
							</Button>

							<Popconfirm position="left" title={envAppMode == 'test' ? '是否要同步到产线？' : '是否要同步到测试？'} onOk={() => handleSyncProd(record)}>
								<Button type="text">同步{envAppMode == 'test' ? '产线' : '测试'}</Button>
							</Popconfirm>
						</>
					)}
				</>
			)
		}
	];

	const [loading, setLoading] = useState(false);
	const [list, setList] = useState<any[]>([]);
	const [total, setTotal] = useState(0);

	const [query, setQuery] = useUrlState<any>({
		page: 1,
		id: undefined,
		keywords: undefined
	});

	// const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);

	const [visible, setVisible] = useState(false);
	const [visibleSrc, setVisibleSrc] = useState('');

	const [isAuth, setIsAuth] = useState(false);
	const [showAuthModal, setShowAuthModal] = useState(false);
	const syncInfoRef = useRef<any>(null);

	// 产线授权
	const onAuthCb = () => {
		const prodToken = getSessionStorage('prod_token');
		const prodTokenExpires = getSessionStorage('prod_token_expires');
		if (prodToken && prodTokenExpires) {
			let nowTime = new Date().getTime();
			if (nowTime >= parseInt(prodTokenExpires)) {
				setIsAuth(false);
				delSessionStorage('prod_token');
				delSessionStorage('prod_token_expires');
			} else {
				setIsAuth(true);
				if (syncInfoRef.current) {
					handleSyncProd(syncInfoRef.current);
				}
			}
		} else {
			setIsAuth(false);
		}
	};
	// 遍历元素，提取资源
	const handleSyncMediaData = async (elements: any[]) => {
		const mediaObj: any = {};
		for (let i = 0; i < elements.length; i++) {
			const item = elements[i];

			if (item?.fill && item?.fill.type == 3 && item?.fill.src) {
				mediaObj[item.fill.src] = 1;
			}

			if (item.type == 'group') {
				console.log('111111-1');
				const medias: any = await handleSyncMediaData(item.elements);
				Object.keys(medias).forEach((key: any) => {
					mediaObj[key] = 1;
				});
			} else if (item.type == 'image') {
				mediaObj[item.src] = 1;
			}
		}
		return mediaObj;
	};

	// 同步到产线
	const handleSyncProd = async (record: any) => {
		try {
			const prodToken = getSessionStorage('prod_token');
			const prodTokenExpires = getSessionStorage('prod_token_expires');
			const nowTime = new Date().getTime();
			if (prodToken && prodTokenExpires && nowTime < parseInt(prodTokenExpires)) {
				setLoading(true);
				const { data } = await postlayoutSyncPre(record.id);
				const { masters, layouts } = data;
				let mediaObjMap: any = {};
				// 循环 master
				for (let i = 0; i < masters.length; i++) {
					const { data } = await axios.get(cdnUrl + masters[i]);
					const { masterList, layoutList } = decryptBase64(data);
					const list = masterList.concat(layoutList);

					for (let j = 0; j < list.length; j++) {
						const item = list[j];

						if (item?.fill && item?.fill.type == 3 && item?.fill.src) {
							mediaObjMap[item.fill.src] = 1;
						}

						if (item.elements.length > 0) {
							const medias = await handleSyncMediaData(item.elements);
							Object.keys(medias).forEach((key: any) => {
								mediaObjMap[key] = 1;
							});
						}
					}
				}
				// 循环 slides
				for (let i = 0; i < layouts.length; i++) {
					const item = layouts[i];
					const { data } = await axios.get(item);
					const slideData = decryptBase64(data);

					if (slideData?.fill && slideData?.fill.type == 3 && slideData?.fill.src) {
						mediaObjMap[slideData.fill.src] = 1;
					}

					const medias = await handleSyncMediaData(slideData.elements);
					Object.keys(medias).forEach((key: any) => {
						mediaObjMap[key] = 1;
					});
				}

				console.log('mediaKeys', Object.keys(mediaObjMap));
				await postLayoutCloneToProd(record.id, {
					accessToken: prodToken,
					mediaKeys: Object.keys(mediaObjMap)
				});
				Message.success('同步完成');
			} else {
				Message.error('请先授权');
				onAuthCb();
				syncInfoRef.current = record;
			}
		} catch (error) {
			console.log('error', error);
		} finally {
			setLoading(false);
		}
	};

	const onPageChange = (page: number) => {
		// setPage(page);
		setQuery(() => ({ page }));
	};
	const handleSearch = (val: any) => {
		Object.keys(query).forEach((key) => {
			if (val[key]) {
				query[key] = val[key];
			} else {
				query[key] = undefined;
			}
		});

		setQuery(() => ({ ...query, page: 1 }));
	};

	const getList = async () => {
		setLoading(true);
		let res = await getLayoutList({
			...query,
			// page,
			size: 10
		});
		setLoading(false);
		setList(res.data.list);
		setTotal(res.data.total);
	};
	// const handleBatchDel = async () => {
	// 	Modal.confirm({
	// 		title: '提示',
	// 		content: '确定要删除选中的记录吗？',
	// 		style: {
	// 			width: '300px'
	// 		},
	// 		onOk: async () => {},
	// 		onCancel: () => {
	// 			setSelectedRowKeys([]);
	// 		}
	// 	});
	// };

	useEffect(() => {
		getList();
	}, [query]);

	useEffect(() => {
		form.setFieldsValue({
			id: urlId ? urlId : undefined,
			keywords: urlKey ? urlKey : undefined
		});

		setQuery({
			page: urlPage ? parseInt(urlPage) : 1,
			id: urlId ? urlId : undefined,
			keywords: urlKey ? urlKey : undefined
		});
		onAuthCb();
	}, []);

	return (
		<>
			<div className="page-filter">
				<Form form={form} onSubmit={handleSearch}>
					<Row gutter={16}>
						<Col span={6}>
							<FormItem field="id" label="ID">
								<Input allowClear placeholder="请输入ID" />
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem field="keywords" label="关键字">
								<Input allowClear placeholder="请输入关键字" />
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem>
								<Space>
									<Button type="outline" icon={<IconSearch />} htmlType="submit">
										搜索
									</Button>
									<Button
										type="primary"
										icon={<IconPlus />}
										onClick={() => {
											navigate('/editor/layout/add');
										}}>
										创建版式
									</Button>
									{envAppMode != 'prod' && (
										<Button
											disabled={isAuth}
											type="primary"
											icon={<IconSafe />}
											onClick={() => {
												setShowAuthModal(true);
											}}>
											{isAuth && '已'}授权{envAppMode == 'test' ? '产线' : '测试'}
										</Button>
									)}
								</Space>
							</FormItem>
						</Col>
					</Row>
				</Form>
			</div>
			<div className="page-warp">
				<Table
					borderCell
					rowKey={(record) => record.id}
					loading={loading}
					columns={columns}
					data={list}
					scroll={{
						x: 1300
					}}
					// rowSelection={{
					// 	selectedRowKeys,
					// 	onChange: (selectedRowKeys: any[]) => {
					// 		setSelectedRowKeys(selectedRowKeys);
					// 	}
					// }}
					pagination={{
						showTotal: true,
						showJumper: true,
						total: total,
						pageSize: 10,
						current: parseInt(query.page),
						onChange: onPageChange
					}}
					// renderPagination={(paginationNode) => (
					// 	<div
					// 		style={{
					// 			display: 'flex',
					// 			justifyContent: 'space-between',
					// 			marginTop: 10
					// 		}}>
					// 		<Button type="outline" status="danger" disabled={selectedRowKeys.length == 0} onClick={handleBatchDel}>
					// 			批量删除
					// 		</Button>
					// 		{paginationNode}
					// 	</div>
					// )}
				/>
			</div>

			{envAppMode != 'prod' && <AuthModal visible={showAuthModal} setVisible={setShowAuthModal} onCallback={onAuthCb} />}

			<Image.Preview src={visibleSrc} visible={visible} onVisibleChange={setVisible} />
		</>
	);
};
