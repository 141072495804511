import { getAppJson, addAppFrame, setViewSize, SetViewSizeOption } from './app';
import ImageHandle, { getRelativeUrl, replaceFillImage, checkReplaceImage } from './image';
import renderPopoverTool, { RenderPopoverToolProps } from './renderPopoverTool';
import { getRenderFill, ColorGradientOption, getPptColor, checkSameColor } from './color';
import { Element, TextSingleData, ThemeValue } from '@/types/element';
import { ElementBaseOption } from '../types';
import { updateText, getTextSize, getValidText } from './text';
import getShape from './shape';

export {
	getAppJson,
	addAppFrame,
	setViewSize,
	getRelativeUrl,
	renderPopoverTool,
	getRenderFill,
	getPptColor,
	updateText,
	replaceFillImage,
	checkReplaceImage,
	getTextSize,
	getValidText,
	checkSameColor,
	getShape
};

export type { SetViewSizeOption, RenderPopoverToolProps, ColorGradientOption };

interface BaseOptionProps extends ColorGradientOption {
	imageHandle: ImageHandle;
}

export function debounce<T extends (...args: any[]) => void>(func: T, delay: number): (...args: Parameters<T>) => void {
	let timer: ReturnType<typeof setTimeout> | null = null;

	return function (...args: Parameters<T>) {
		if (timer !== null) {
			clearTimeout(timer);
		}
		timer = setTimeout(() => {
			func(...args);
			timer = null;
		}, delay);
	};
}

export async function getBaseOptions(data: Element, { theme, imageHandle }: BaseOptionProps) {
	const width = data.width;
	const height = data.type == 'line' ? Math.max(data.height, data.border?.width || 0) : data.height;
	const option: ElementBaseOption = {
		width,
		height,
		x: data.left,
		y: data.top,
		origin: 'top-left'
	};
	const colorOption = {
		theme,
		imageHandle,
		width,
		height
	};
	if (data.fill) {
		option.fill = await getRenderFill(data.fill, colorOption);
	}
	if (data.shadow) {
		const { dist, sx, sy, dir } = data.shadow;
		option.shadow = {
			x: Math.floor((dist - (sx ? width * (1 - sx) : 0)) * Math.cos((dir * Math.PI) / 180)),
			y: Math.floor((dist - (sy ? height * (1 - sy) : 0)) * Math.sin((dir * Math.PI) / 180)),
			blur: data.shadow.blur,
			spread: 0,
			color: getPptColor(data.shadow.color, colorOption)
		};
	}
	if (data.border?.fill) {
		option.stroke = await getRenderFill(data.border.fill, colorOption);
		option.strokeWidth = data.border.width || 1;
		if (data.border.style == 'dot') {
			const dot = Math.max(option.strokeWidth, 1);
			option.dashPattern = [dot, dot];
		}
		if (data.border.style == 'dash') {
			const dot = Math.max(option.strokeWidth, 3);
			option.dashPattern = [dot * 3.2, dot];
		}
	}
	return option;
}

export function getFontFamily(text: TextSingleData, themeFont: ThemeValue['font']): string {
	if (text.fontFamily) return text.fontFamily;
	const typeface = (text.lang != 'zh-CN' ? text.eaTypeface : text.latinTypeface) || text.latinTypeface;
	console.log('text-2', text);
	if (typeface) {
		if (typeface.match(/^\+/)) {
			const matchFont = typeface.match(/^\+mj/) ? themeFont.majorFont : themeFont.minorFont;
			const matchType = typeface.match(/-(.*)$/);
			if (matchType?.[1]) {
				const key: any = matchFont?.[matchType[1] as keyof typeof matchFont];
				console.log('key-1', key);
				if (key) {
					return key;
				}
			}
		} else {
			return typeface;
		}
	}
	return 'HarmonyOS Sans SC';
}
