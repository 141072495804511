import { App, Frame, IOverflow, IFill } from 'leafer-ui'

import leaferDataToJson from './toJson'
import { Element, ViewportSize } from '@/types/element'

export async function getAppJson(app: App) {
  const frontFrame = (app?.tree.children || []).find(item => item.id == 'front')
  const result = {
    ...app.data as Element,
    elements: await leaferDataToJson((frontFrame?.children || []))
  }
  return result
}

export function addAppFrame(app: App) {
  const frameCommonOptions: { overflow: IOverflow, zIndex: number, fill: IFill } = {
    overflow: 'hide',
    zIndex: 1,
    fill: 'transparent',
  }

  // 用于添加阴影
  const groundFrame = new Frame({
    ...frameCommonOptions,
    id: 'ground-frame',
    overflow: 'hide',
    shadow: {
      x: -2,
      y: -2,
      blur: 8,
      color: 'rgba(0, 0, 0, 0.45)'
    }
  })

  // 用于添加母版
  const backgroundFrame = new Frame({
    ...frameCommonOptions,
    id: 'background',
    zIndex: 2,
    fill: '#fff'
  })

  // 用于添加元素
  const frontFrame = new Frame({
    ...frameCommonOptions,
    id: 'front',
    zIndex: 3,
  })

  app.ground.add(groundFrame)
  app.tree.add(backgroundFrame)
  app.tree.add(frontFrame)
}

export interface SetViewSizeOption {
  viewportSize: ViewportSize
  app: App,
}

export function setViewSize({ viewportSize, app }: SetViewSizeOption) {
  const { width = 0, height = 0 } = app
  const { width: viewWidth = 0, height: viewHeight = 0 } = viewportSize || {}
  const frameX = -(viewWidth - width) / 2
  const frameY = -(viewHeight - height) / 2
  
  const groundFrame = app.ground.children.find(item => item.id == 'ground-frame')
  const backgroundFrame = app.tree.children.find(item => item.id == 'background')
  const frontFrame = app.tree.children.find(item => item.id == 'front')

  if (groundFrame) {
    groundFrame.width = viewWidth
    groundFrame.height = viewHeight
    groundFrame.x = frameX
    groundFrame.y = frameY
  }
  if (backgroundFrame) {
    backgroundFrame.width = viewWidth
    backgroundFrame.height = viewHeight
    backgroundFrame.x = frameX
    backgroundFrame.y = frameY
  }
  if (frontFrame) {
    frontFrame.width = viewWidth
    frontFrame.height = viewHeight
    frontFrame.x = frameX
    frontFrame.y = frameY
  }
  const scaleX = width / viewWidth
  const scaleY = height / viewHeight
  if (scaleX < 1 || scaleY < 1) {
    const zoom = Math.min(scaleX, scaleY)
    app.tree.zoom(zoom)
    app.ground.zoom(zoom)
  }
}