import { Polygon } from 'leafer-ui'

import { Element } from '@/types/element'
import { ElementBaseOption } from '../../types'

export default async function getTriangle(option: ElementBaseOption, data: Element) {
  const { width, height } = data
  const radius = typeof data.shape?.radius == 'number' ? data.shape.radius : 0.5

  return new Polygon({
    ...option,
    points: [
      width * radius,
      0,
      width,
      height,
      0,
      height
    ]
  })
}