import { Polygon } from 'leafer-ui'

import { Element } from '@/types/element'
import { ElementBaseOption } from '../../types'

export default async function getTrapezoid(option: ElementBaseOption, data: Element) {
  const { width, height } = data
  const xRadius = Math.min(width, height) * (typeof data.shape?.radius == 'number' ? data.shape.radius : 0.25)

  return new Polygon({
    ...option,
    points: [
      xRadius,
      0,
      width - xRadius,
      0,
      width,
      height,
      0,
      height
    ]
  })
}