import { useCallback, useContext, useEffect, useState } from 'react'
import { UI, Path, Line } from 'leafer-ui'

import { LineData } from '@/types/element'
import { getBaseOptions } from '../utils'
import { MainContext } from '../context/main'
import { SlideContext } from '../context/slide'

interface ElementLineProps {
  data: LineData
  onLoad(e?: UI): void
  editable?: boolean
}

export default function ElementLine ({ data, onLoad, editable = false }: ElementLineProps) {
  const { theme } = useContext(SlideContext).state
  const { imageHandle } = useContext(MainContext).state
  const [element, setElement] = useState<Path | Line>()

  const handleGetData = useCallback(async () => {
    if (!imageHandle) return
    const option = await getBaseOptions(data, { theme, imageHandle })

    delete option.origin

    const line = typeof option.stroke  == 'string' ? new Line({
      ...option,
      toPoint: { x: data.width, y: data.height },
      editable,
      data,
    }) : new Path({
      ...option,
      path: `
        M 0 0
        L ${data.width} ${data.height}
        Z
      `,
      editable,
      data,
    })

    if (data.rot) {
      // line.rotateOf('center', data.rot)
    }

    if (data.flipX) line.flip('x')
    if (data.flipY) line.flip('y')

    setElement(line)
  }, [data, theme, editable, imageHandle])

  useEffect(() => {
    handleGetData()
  }, [handleGetData])

  useEffect(() => {
    element && onLoad(element)
    return () => {
      element?.remove()
    }
  }, [element, onLoad])
  
  return null
}