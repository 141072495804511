import { useState, useEffect } from 'react';
import { Modal, Message, Form, Input } from '@arco-design/web-react';

import { postIndustry, putIndustry } from '@/api/industry';

import './editModal.less';

export default (props: any) => {
	const { info, visible, setVisible, onCallback } = props;
	const [form] = Form.useForm();
	const FormItem = Form.Item;

	const [loading, setLoading] = useState(false);

	const handleSave = async () => {
		try {
			const values = await form.validate();
			setLoading(true);
			if (info?.id) {
				let res = await putIndustry(info.id, {
					name: values.name
				});
				setLoading(false);
				if (res.code == 200) {
					Message.success('更新成功');
					onCallback();
					hamdleCancel();
				}
			} else {
				let res = await postIndustry({
					name: values.name
				});
				setLoading(false);
				if (res.code == 200) {
					Message.success('添加成功');
					onCallback();
					hamdleCancel();
				}
			}
		} catch (error) {}
	};
	const hamdleCancel = () => {
		setVisible(false);
		setLoading(false);
	};

	useEffect(() => {
		if (visible && info) {
			form.setFieldsValue({
				name: info.name
			});
		} else {
			form.resetFields();
		}
	}, [visible]);

	return (
		<Modal
			wrapClassName="edit-template-modal"
			title={info?.id ? '编辑行业类目' : '新增行业类目'}
			visible={visible}
			confirmLoading={loading}
			onOk={handleSave}
			onCancel={hamdleCancel}
			autoFocus={false}
			focusLock={true}
			style={{ width: '360px' }}>
			<Form form={form} layout="vertical">
				<FormItem label="行业名称" field="name" rules={[{ required: true, message: '请输入名称' }]}>
					<Input placeholder="请输入行业名称" />
				</FormItem>
			</Form>
		</Modal>
	);
};
