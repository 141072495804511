import { useContext, useEffect, useRef, useState, createContext, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useUrlState from '@ahooksjs/use-url-state';
import axios from 'axios';
import { Table, Form, Input, Space, Button, Select, Message, Grid, Popconfirm, Switch, FormInstance } from '@arco-design/web-react';
import { IconSearch, IconSafe } from '@arco-design/web-react/icon';

import { getTempList, delTemp, putTempEdit, getTempCateOptions, getTempColorOptions, postTempSyncPre, postTempCloneToProd, putTempState, putTempRecommend } from '@/api/template';
import { getSlideStyleOption } from '@/api/slideStyle';

import { decryptBase64 } from '@shock/tool';
import { formatDate, delSessionStorage, getSessionStorage } from '@/utils';
import { GlobalContext } from '@/hooks/context';

import EditModal from './component/editModal';
import AuthModal from '../component/loginProd';

import './index.less';

const envAppMode = import.meta.env.VITE_APP_MODE;

export default () => {
	const EditableContext = createContext<{ getForm?: () => FormInstance }>({});

	const navigate = useNavigate();

	const [searchParams] = useSearchParams();
	const urlPage: any = searchParams.get('page');
	const urlType = searchParams.get('type');
	const urlStyle = searchParams.get('style');
	const urlColor = searchParams.get('color');
	const urlKey = searchParams.get('keywords');

	const { cdnUrl } = useContext(GlobalContext);
	const [form] = Form.useForm();
	const FormItem = Form.Item;
	const Row = Grid.Row;
	const Col = Grid.Col;
	const columns: any = [
		{
			title: 'ID',
			dataIndex: 'id',
			fixed: 'left',
			width: 90
		},
		{
			title: '缩略图',
			dataIndex: 'preview',
			fixed: 'left',
			width: 120,
			render: (_: any, record: any) => <img style={{ width: '80px' }} src={cdnUrl + record.preview} />
		},
		{
			title: '模板名称',
			key: 'name',
			dataIndex: 'name',
			width: 200
		},
		{
			title: '状态',
			dataIndex: 'status',
			width: 120,
			render: (_: any, record: any) => (
				<Space direction="vertical">
					<Switch checked={record.status == 1} checkedText="已启用" uncheckedText="未启用" onChange={(checked: boolean) => handleStatus(record, checked)} />
					<Switch
						className="switch-recommended"
						checked={record.recommended == 1}
						checkedText="已推荐"
						uncheckedText="未推荐"
						onChange={(checked: boolean) => handleRecommend(record, checked)}
					/>
					{/* <Switch checked={record.ai == 1} checkedText="已AI推荐" uncheckedText="未AI推荐" onChange={(checked: boolean) => handleAiRecommend(record, checked)} />
					<Switch checked={record.optimiez == 1} checkedText="已美化推荐" uncheckedText="未美化推荐" onChange={(checked: boolean) => handleBeautifyRecommend(record, checked)} /> */}
				</Space>
			)
		},
		{
			title: '排序',
			key: 'sort',
			dataIndex: 'sort',
			width: 100,
			editable: true
		},
		{
			title: '页数',
			dataIndex: 'pages',
			width: 90
		},
		{
			title: '金额',
			dataIndex: 'price',
			width: 100,
			render: (_: any, record: any) => <strong style={{ color: 'red' }}>¥{record.price ? (record.price / 100).toFixed(2) : '0.00'}</strong>
		},
		{
			title: '模板类别',
			dataIndex: 'type',
			width: 140
		},
		{
			title: '模板风格',
			dataIndex: 'style',
			width: 140
		},
		{
			title: '模板颜色',
			dataIndex: 'color',
			width: 140
		},
		{
			title: '提交人',
			dataIndex: 'creatorName',
			width: 140
		},
		{
			title: '创建时间',
			key: 'createTime',
			width: 180,
			render: (_: any, record: any) => <>{formatDate(record.createTime)}</>
		},
		{
			title: '操作',
			key: 'action',
			fixed: 'right',
			width: 220,
			render: (_: any, record: any) => {
				return (
					<>
						<Button type="text" onClick={() => navigate(`/layout/template/${record.id}?page=1`)}>
							版式列表
						</Button>
						{envAppMode != 'prod' && (
							<>
								<Button type="text" onClick={() => navigate(`/editor/template/append/${record.id}`)}>
									版式追加
								</Button>
								<Button type="text" onClick={() => navigate(`/editor/template/edit/${record.id}`)}>
									模版编辑
								</Button>
								<Button
									type="text"
									onClick={() => {
										setShowEditModalInfo(record);
										setShowEditModal(true);
									}}>
									信息编辑
								</Button>
								<Popconfirm position="left" title="提示" content="是否去订删除此模版？" onOk={() => handleDel(record.id)}>
									<Button type="text">删除模版</Button>
								</Popconfirm>

								<Popconfirm position="left" title={envAppMode == 'test' ? '是否要同步到产线？' : '是否要同步到测试？'} onOk={() => handleSyncProd(record)}>
									<Button type="text">同步{envAppMode == 'test' ? '产线' : '测试'}</Button>
								</Popconfirm>
							</>
						)}
					</>
				);
			}
		}
	];

	const [showModal, setShowEditModal] = useState(false);
	const [showModalInfo, setShowEditModalInfo] = useState('');

	//
	const [isAuth, setIsAuth] = useState(false);
	const [showAuthModal, setShowAuthModal] = useState(false);
	const syncInfoRef = useRef<any>(null);

	const [styleList, setStyleList] = useState<any[]>([]);
	const [typeList, setTypeList] = useState<any[]>([]);
	const [colorOption, setColorOption] = useState<any[]>([]);

	const [loading, setLoading] = useState(false);
	const [list, setList] = useState<any[]>([]);
	const [total, setTotal] = useState(0);

	const [query, setQuery] = useUrlState<any>({
		page: 1,
		type: undefined,
		style: undefined,
		color: undefined,
		keywords: undefined
	});

	const onPageChange = (page: number) => {
		setQuery(() => ({ page }));
	};
	const handleSearch = (val: any) => {
		Object.keys(query).forEach((key) => {
			if (val[key]) {
				query[key] = val[key];
			} else {
				query[key] = undefined;
			}
		});

		setQuery(() => ({ ...query, page: 1 }));
	};

	const getList = async () => {
		setLoading(true);
		let res = await getTempList({
			...query,
			size: 10
		});

		res.data.list.forEach((it: any) => {
			if (it.preview.indexOf('?') > -1) {
				it.preview = it.preview + '&x-oss-process=image/resize,m_fill,w_160,h_90';
			} else {
				it.preview = it.preview + '?x-oss-process=image/resize,m_fill,w_160,h_90';
			}
		});

		setList(res.data.list);
		setTotal(res.data.total);
		setLoading(false);
	};

	// 模板类型
	const getTypeList = async () => {
		let res = await getTempCateOptions();
		if (res.code == 200) {
			setTypeList(res.data);
		}
	};
	// 模板风格
	const getStyleList = async () => {
		let res = await getSlideStyleOption();
		if (res.code == 200) {
			setStyleList(res.data);
		}
	};
	const getColorList = async () => {
		let res = await getTempColorOptions();
		setColorOption(res.data);
	};
	// 删除
	const handleDel = async (id: any) => {
		let res = await delTemp(id);
		if (res.code == 200) {
			Message.success('删除成功');
			getList();
		} else {
			Message.error(res.msg);
		}
	};
	// 上下架
	const handleStatus = async (item: any, checked: boolean) => {
		let status = checked ? 1 : 2;
		let res = await putTempState(item.id, status);
		if (res.code == 200) {
			getList();
		}
	};
	// 推荐
	const handleRecommend = async (item: any, checked: boolean) => {
		let status = checked ? 1 : 2;
		let res = await putTempRecommend(item.id, status);
		if (res.code == 200) {
			getList();
		}
	};

	/**
	 * 同步产线操作
	 */
	// 产线授权
	const onAuthCb = () => {
		const prodToken = getSessionStorage('prod_token');
		const prodTokenExpires = getSessionStorage('prod_token_expires');
		if (prodToken && prodTokenExpires) {
			let nowTime = new Date().getTime();
			if (nowTime >= parseInt(prodTokenExpires)) {
				setIsAuth(false);
				delSessionStorage('prod_token');
				delSessionStorage('prod_token_expires');
			} else {
				setIsAuth(true);
				if (syncInfoRef.current) {
					handleSyncProd(syncInfoRef.current);
				}
			}
		} else {
			setIsAuth(false);
		}
	};
	// 遍历元素，提取资源
	const handleSyncMediaData = async (elements: any[]) => {
		const mediaObj: any = {};
		for (let i = 0; i < elements.length; i++) {
			const item = elements[i];
			if (item?.fill && item?.fill.type == 3 && item?.fill.src) {
				mediaObj[item.fill.src] = 1;
			}
			if (item.type == 'group') {
				const medias: any = await handleSyncMediaData(item.elements);
				Object.keys(medias).forEach((key: any) => {
					mediaObj[key] = 1;
				});
			} else if (item.type == 'image') {
				mediaObj[item.src] = 1;
			} else if (item.backgroundImage) {
				mediaObj[item.backgroundImage] = 1;
			}
		}
		return mediaObj;
	};

	// 同步到产线
	const handleSyncProd = async (item: any) => {
		try {
			const prodToken = getSessionStorage('prod_token');
			const prodTokenExpires = getSessionStorage('prod_token_expires');
			const nowTime = new Date().getTime();
			if (prodToken && prodTokenExpires && nowTime < parseInt(prodTokenExpires)) {
				setLoading(true);
				const { data } = await postTempSyncPre(item.id);

				const { masters, layouts } = data;
				let mediaObjMap: any = {};
				// 循环 master
				for (let i = 0; i < masters.length; i++) {
					const { data } = await axios.get(cdnUrl + masters[i]);
					const { masterList, layoutList } = decryptBase64(data);
					const list = masterList.concat(layoutList);

					for (let j = 0; j < list.length; j++) {
						const item = list[j];
						if (item?.fill && item?.fill.type == 3 && item?.fill.src) {
							mediaObjMap[item.fill.src] = 1;
						}
						if (item.elements.length > 0) {
							const medias = await handleSyncMediaData(item.elements);
							Object.keys(medias).forEach((key: any) => {
								mediaObjMap[key] = 1;
							});
						}
					}
				}
				// 循环 slides
				for (let i = 0; i < layouts.length; i++) {
					const item = layouts[i];
					const { data } = await axios.get(item);
					const slideData = decryptBase64(data);
					if (slideData?.fill && slideData?.fill.type == 3 && slideData?.fill.src) {
						mediaObjMap[slideData.fill.src] = 1;
					}
					const medias = await handleSyncMediaData(slideData.elements);
					Object.keys(medias).forEach((key: any) => {
						mediaObjMap[key] = 1;
					});
				}

				await postTempCloneToProd(item.id, {
					accessToken: prodToken,
					mediaKeys: Object.keys(mediaObjMap)
				});
				Message.success('同步完成');
			} else {
				Message.error('请先授权');
				onAuthCb();
				syncInfoRef.current = item;
			}
		} catch (error) {
			console.log('error', error);
		} finally {
			setLoading(false);
		}
	};

	// 编辑 table
	const editableRow = (props: any) => {
		const { children, record, className, ...rest } = props;
		const refForm = useRef(null);

		const getForm: any = () => refForm.current;

		return (
			<EditableContext.Provider
				value={{
					getForm
				}}>
				<Form style={{ display: 'table-row' }} children={children} ref={refForm} wrapper="tr" wrapperProps={rest} className={`${className} editable-row`} />
			</EditableContext.Provider>
		);
	};

	const handleSave = async (row: any) => {
		console.log('handleSave', row);

		const { id, sort } = row;

		setLoading(true);
		const res = await putTempEdit(id, {
			sort
		});
		setLoading(false);
		if (res.code == 200) {
			Message.success('更新成功');
			getList();
		}
	};

	const editableCell = (props: any) => {
		const { children, className, rowData, column, onHandleSave } = props;
		const ref: any = useRef(null);
		const refInput: any = useRef(null);
		const { getForm }: any = useContext(EditableContext);
		const [editing, setEditing] = useState(false);
		const handleClick = useCallback(
			(e: any) => {
				if (editing && column.editable && ref.current && !ref.current.contains(e.target) && !e.target.classList.contains('js-demo-select-option')) {
					cellValueChangeHandler(rowData[column.dataIndex]);
				}
			},
			[editing, rowData, column]
		);
		useEffect(() => {
			editing && refInput.current && refInput.current.focus();
		}, [editing]);
		useEffect(() => {
			document.addEventListener('click', handleClick, true);
			return () => {
				document.removeEventListener('click', handleClick, true);
			};
		}, [handleClick]);

		const cellValueChangeHandler = (value: any) => {
			if (column.dataIndex === 'salary') {
				const values = {
					[column.dataIndex]: value
				};
				onHandleSave && onHandleSave({ ...rowData, ...values });
				setTimeout(() => setEditing(!editing), 300);
			} else {
				const form = getForm();
				form.validate([column.dataIndex], (errors: any, values: any) => {
					if (!errors || !errors[column.dataIndex]) {
						setEditing(!editing);
						onHandleSave && onHandleSave({ ...rowData, ...values });
					}
				});
			}
		};

		if (editing) {
			return (
				<div ref={ref}>
					<FormItem
						style={{ marginBottom: 0 }}
						labelCol={{ span: 0 }}
						wrapperCol={{ span: 24 }}
						initialValue={rowData[column.dataIndex]}
						field={column.dataIndex}
						rules={[{ required: true }]}>
						<Input ref={refInput} onPressEnter={cellValueChangeHandler} />
					</FormItem>
				</div>
			);
		}

		return (
			<div className={column.editable ? `editable-cell ${className}` : className} onClick={() => column.editable && setEditing(!editing)}>
				{children}
			</div>
		);
	};

	useEffect(() => {
		getList();
	}, [query]);

	useEffect(() => {
		form.setFieldsValue({
			type: urlType ? urlType : undefined,
			style: urlStyle ? urlStyle : undefined,
			color: urlColor ? urlColor : undefined,
			keywords: urlKey ? urlKey : undefined
		});
		setQuery({
			page: urlPage ? parseInt(urlPage) : 1,
			type: urlType ? parseInt(urlType) : undefined,
			style: urlStyle ? parseInt(urlStyle) : undefined,
			color: urlColor ? parseInt(urlColor) : undefined,
			keywords: urlKey ? urlKey : undefined
		});
		getTypeList();
		getStyleList();
		getColorList();
		onAuthCb();
	}, []);

	return (
		<>
			<div className="page-filter">
				<Form form={form} onSubmit={handleSearch}>
					<Row gutter={16}>
						<Col span={8}>
							<FormItem field="type" label="类型">
								<Select allowClear placeholder="请选择模板类型" style={{ width: '100%' }}>
									{typeList.map((item) => (
										<Select.Option key={item.value} value={item.value}>
											{item.label}
										</Select.Option>
									))}
								</Select>
							</FormItem>
						</Col>
						<Col span={8}>
							<FormItem field="style" label="风格">
								<Select allowClear placeholder="请选择模板风格" style={{ width: '100%' }}>
									{styleList.map((item) => (
										<Select.Option key={item.value} value={item.value}>
											{item.label}
										</Select.Option>
									))}
								</Select>
							</FormItem>
						</Col>
						<Col span={8}>
							<FormItem field="color" label="色系">
								<Select allowClear placeholder="请选择色系" style={{ width: '100%' }}>
									{colorOption.map((item) => (
										<Select.Option key={item.value} value={item.value}>
											{item.label}
										</Select.Option>
									))}
								</Select>
							</FormItem>
						</Col>
						<Col span={8}>
							<FormItem field="keywords" label="关键字">
								<Input placeholder="请输入关键字" />
							</FormItem>
						</Col>
						<Col span={8}>
							<FormItem>
								<Space>
									<Button type="outline" icon={<IconSearch />} htmlType="submit">
										搜索
									</Button>
									{/* <Button
										type="primary"
										icon={<IconPlus />}
										onClick={() => {
											navigate('/editor/template');
										}}>
										新增模板
									</Button>*/}
									{envAppMode != 'prod' && (
										<Button
											disabled={isAuth}
											type="primary"
											icon={<IconSafe />}
											onClick={() => {
												setShowAuthModal(true);
											}}>
											{isAuth && '已'}授权{envAppMode == 'test' ? '产线' : '测试'}
										</Button>
									)}
								</Space>
							</FormItem>
						</Col>
					</Row>
				</Form>
			</div>
			<div className="page-warp">
				<Table
					borderCell
					rowKey={(record) => record.id}
					loading={loading}
					columns={columns.map((column: any) =>
						column.editable && envAppMode != 'prod'
							? {
									...column,
									onCell: () => ({
										onHandleSave: handleSave
									})
							  }
							: {
									...column,
									editable: false
							  }
					)}
					data={list}
					components={{
						body: {
							row: editableRow,
							cell: editableCell
						}
					}}
					scroll={{
						x: 1300
					}}
					pagination={{
						showTotal: true,
						total: total,
						pageSize: 10,
						current: parseInt(query.page),
						onChange: onPageChange
					}}
				/>
			</div>
			{/*--------*/}
			<EditModal info={showModalInfo} visible={showModal} styleList={styleList} typeList={typeList} colorList={colorOption} setVisible={setShowEditModal} onCallback={getList} />
			{/*--------*/}
			{envAppMode != 'prod' && <AuthModal visible={showAuthModal} setVisible={setShowAuthModal} onCallback={onAuthCb} />}
		</>
	);
};
