import { useState, useMemo, useCallback } from 'react';
import { ModalProps } from '@arco-design/web-react';

export interface CustomModalProps extends ModalProps {
	customClose: () => void;
}

type internalCustomModalProps<T extends CustomModalProps = CustomModalProps> = Omit<T, 'customClose'>;

export type ReturnType<T extends CustomModalProps> = (initialize?: internalCustomModalProps<T>) => {
	readonly update: (props: internalCustomModalProps<T>) => void;
	readonly close: () => void;
};

export function useModal<T extends CustomModalProps>(ModalComponent: React.FC<T>): [ReturnType<T>, React.ReactNode] {
	const [open, setOpen] = useState<boolean>(false);
	const [renderEnable, setRenderEnable] = useState<boolean>(false);
	const [props, setProps] = useState<internalCustomModalProps<T> | undefined>();

	const contextHolder = useMemo(() => {
		if (renderEnable) {
			const realProps = {
				...props,
				open,
				visible: open,
				customClose: () => setOpen(false),
				afterClose: () => {
					props?.afterClose?.();
					setRenderEnable(false);
				}
			} as any;
			return <ModalComponent {...realProps} />;
		}
		return null;
	}, [open, renderEnable, props]);

	const openModal = useCallback((initializeProps?: internalCustomModalProps<T>) => {
		setRenderEnable(true);
		setOpen(true);
		setProps(initializeProps);
		return {
			update: (props: internalCustomModalProps<T>) => {
				setProps(props);
			},
			close: () => {
				setOpen(false);
			}
		};
	}, []);
	return [openModal, contextHolder] as const;
}
export interface CustomPromiseModalProps<T = any> extends CustomModalProps {
	customResolve: (value: T) => void;
	customReject: (reason?: any) => void;
}

type internalCustomPromiseModalProps<T extends CustomPromiseModalProps = CustomPromiseModalProps> = Omit<T, 'open' | 'customClose' | 'customResolve' | 'customReject'>;

export function usePromiseModal<T extends CustomPromiseModalProps>(
	ModalComponent: React.FC<T>
): [(initialize?: internalCustomPromiseModalProps<T>) => Promise<Parameters<T['customResolve']>[0]>, React.ReactNode] {
	const [openModal, contextHolder] = useModal(ModalComponent);
	const openPromiseModal = useCallback(function (props?: internalCustomPromiseModalProps) {
		return new Promise<Parameters<T['customResolve']>[0]>((resolve, reject) => {
			const { close } = openModal({
				...props,
				customResolve: (value: Parameters<T['customResolve']>[0]) => {
					close();
					resolve(value);
				},
				customReject: (reason) => {
					close();
					reject(reason);
				}
			} as T);
		});
	}, []);
	return [openPromiseModal, contextHolder] as const;
}

export default useModal;
