import { Polygon } from 'leafer-ui'

import { Element } from '@/types/element'
import { ElementBaseOption } from '../../types'

export default async function getRtTriangle(option: ElementBaseOption, data: Element) {
  return new Polygon({
    ...option,
    points: [
      0,
      0,
      data.width,
      data.height,
      0,
      data.height,
    ]
  })
}