import { useCallback, useContext, useEffect, useState } from 'react';
import { Box, UI, Text } from 'leafer-ui';
import { EditorScaleEvent } from '@leafer-in/editor';

import { TextLineData, TextData } from '@/types/element';
import { getBaseOptions, getFontFamily, getPptColor, getRenderFill, getShape } from '../utils';
import { MainContext } from '../context/main';
import { SlideContext } from '../context/slide';

interface ElementTextProps {
	data: TextData;
	onLoad(e?: UI): void;
	editable?: boolean;
}

export default function ElementText({ data, onLoad, editable = false }: ElementTextProps) {
	const { theme, app, themeFont } = useContext(SlideContext).state;
	const { imageHandle } = useContext(MainContext).state;
	const [element, setElement] = useState<Box>();

	const handleGetData = useCallback(async () => {
		if (!imageHandle) return;
		const option = await getBaseOptions(data, { theme, imageHandle });
		const { left: lInt = 5, right: rInt = 5 } = data.margin;

		const box = new Box({
			x: option.x,
			y: option.y,
			width: option.width,
			height: option.height,
			origin: option.origin,
			editable,
			data
		});
		if (data.shape && (option.fill || option.stroke)) {
			const node = await getShape(data.shape.type, data, {
				...option,
				x: 0,
				y: 0,
				locked: true
			});
			if (node) {
				box.add(node);
				if (data.flipX) node.flip('x');
				if (data.flipY) node.flip('y');
			}
		}

		let sumHeight = 0;
		const styles: {
			style: Partial<Text>;
			text: string;
			spaceBefore: TextLineData['spaceBefore'];
		}[] = [];
		for (const line of data.text) {
			const align: { [k: string]: Text['textAlign'] } = { left: 'left', right: 'right', center: 'center', 'justify-center': 'center' };
			const fontColor = line.text.find((item) => !!item.fill)?.fill;
			const fontBorder = line.text.find((item) => !!item.border)?.border;
			const fontShadow = line.text.find((item) => !!item.shadow)?.shadow;
			const firstStyle = line.text[0] || {};
			const textAlign = align[line.align] || 'justify';
			const fontSize = Math.round((((line.text[0].fontSize || 18) / 0.75) * 100) / (data.textScale || 1)) / 100;

			const style: Partial<Text> = {
				fontSize,
				textAlign,
				fontWeight: firstStyle.fontWeight as Text['fontWeight'],
				italic: firstStyle.fontStyle == 'italic',
				textDecoration: firstStyle.underline ? 'under' : firstStyle.lineThrough ? 'delete' : 'none',
				fontFamily: getFontFamily(firstStyle, themeFont),
				lineHeight: {
					type: 'percent',
					value: line.lineHeight + ((line.spaceBefore || 0) * 2 || 0.2)
				},
				data: {
					fontSize: line.text[0].fontSize,
					fill: fontColor
				}
			};
			if (fontShadow) {
				style.shadow = {
					x: fontShadow.blur,
					y: fontShadow.blur,
					blur: fontShadow.blur,
					spread: 0,
					// color: '#000'
					color: getPptColor(fontShadow.color, { width: data.width, height: data.height, theme })
				};
			}
			if (fontColor) {
				const _fill = await getRenderFill(fontColor, { width: data.width, height: data.height, theme, imageHandle });
				// 由于字体除线性之外有bug，这边强制转换成线性
				if (typeof _fill == 'object' && !Array.isArray(_fill) && _fill.type != 'linear') _fill.type = 'linear';
				style.fill = _fill || 'transparent';
			} else {
				style.fill = theme.tx1.value;
			}
			if (fontBorder?.fill) {
				style.stroke = await getRenderFill(fontBorder.fill, { width: data.width, height: data.height, theme, imageHandle });
				style.strokeWidth = fontBorder.width;
			}
			if (data.isTextBox && data.shadow && !style.shadow) {
				style.shadow = {
					x: data.shadow.blur,
					y: data.shadow.blur,
					blur: data.shadow.blur,
					spread: 0,
					// color: '#000'
					color: getPptColor(data.shadow.color, { width: data.width, height: data.height, theme })
				};
			}
			const text = line.text.map((single) => single.text).join('');
			styles.push({
				style,
				spaceBefore: line.spaceBefore,
				text
			});
		}
		styles.forEach((item) => {
			const text = new Text({
				...item.style,
				y: sumHeight,
				width: data.width,
				padding: [0, rInt, 0, lInt],
				text: item.text
			});

			const bounds = text.getBounds();
			sumHeight += bounds.height;
			if (data.flipY) text.rotateOf('center', 180);

			box.add(text);
		});

		if (data.height > sumHeight && (data.vertical == 'center' || data.autoFit)) {
			const topSpace = (data.height - sumHeight) / 2;
			box.children.forEach((item) => {
				if (item.tag == 'Text' && typeof item.y == 'number') {
					item.y += topSpace;
				}
			});
		}

		if (sumHeight > data.height) {
			const topSpace = 2;
			box.height = data.height = sumHeight + topSpace * 2;
			box.children.forEach((item) => {
				if (item.tag == 'Text' && typeof item.y == 'number') {
					item.y += topSpace;
				}
			});
		}
		if (data.rot) {
			box?.rotateOf('center', data.rot);
		}
		setElement(box);
	}, [data, theme, editable, imageHandle, themeFont]);

	useEffect(() => {
		handleGetData();
	}, [handleGetData]);

	useEffect(() => {
		const editorScaleListener = (event: EditorScaleEvent) => {
			const target = event.target;
			let sumHeight = 0;
			const children = target?.children || [];

			let topSpace = 0;
			children.forEach((item, index) => {
				if (item.tag == 'Text') {
					item.width = target.width;
					item.getBounds();
					if (index == 0) {
						topSpace = item.y || 0;
						sumHeight += item.y || 0;
					} else {
						item.y = sumHeight;
					}
					sumHeight += item.height || 0;
				}
			});
			sumHeight += topSpace;
			const height = target.height || 0;
			if (height > 0 && Math.floor(height) < Math.floor(sumHeight)) {
				target.height = sumHeight;
			}
		};
		if (element) {
			onLoad(element);
			app?.editor?.on('editor.scale', editorScaleListener);
		}
		return () => {
			app?.editor?.cancel();
			element?.remove();
			app?.editor?.off('editor.scale', editorScaleListener);
		};
	}, [element, onLoad, app]);

	return null;
}
