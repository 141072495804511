import { ImageData } from '@/types/element';
import { IImagePaint, IUI } from 'leafer-ui';

export interface ImageHandleOption {
	prefix: string;
}

export function getRelativeUrl(url: string) {
	if (!/^http[s]?/.test(url)) return url;
	const router = new URL(url);
	return `${router.pathname}${router.search}`;
}

export function checkReplaceImage(target?: IUI | null) {
	if (!target) return false;
	return target.data?.type == 'image' || !!(target.data?.type == 'text' && (target.data?.backgroundImage || target.data?.fill?.type == 3));
}

export async function replaceFillImage(url: string, target: IUI, imageHandle: ImageHandle) {
	const isBackgroundImage = !!(target.data?.type == 'text' && target.data?.backgroundImage);
	const shapeBox = isBackgroundImage ? target.children?.find((item) => item.data?.isShapeBox) : null;
	const shape = (shapeBox || target).children?.find((item) => item.data?.isElementShape) || target;
	if (typeof shape?.fill == 'object' && 'url' in shape.fill) {
		const updateData = await imageHandle.getImageFill({
			...target.data,
			rect: isBackgroundImage ? target.data?.backgroundRect : target.data?.rect,
			src: url
		} as ImageData);
		await new Promise((resolve, reject) => {
			shape.once('image.load', () => {
				resolve(true);
			});
			shape.once('image.error', (err) => {
				reject(err);
			});
			shape.set({
				fill: updateData
			});
		});
	}
}

export default class ImageHandle {
	option: ImageHandleOption;

	constructor(option: ImageHandleOption) {
		this.option = option;
	}

	public getUrl = (url: string) => {
		const { prefix } = this.option;
		return url.match(/^http/) || /^data:image/.test(url) ? url : prefix + url;
	};

	public loadImage = async (url: string) => {
		const image = new window.Image();
		image.src = this.getUrl(url);
		await new Promise((resolve, reject) => {
			image.onload = () => {
				resolve(true);
			};
			image.onerror = (err) => {
				reject(err);
			};
			if (image.complete) resolve(true);
		});
		return image;
	};

	public getImageFill = async (data: ImageData): Promise<IImagePaint> => {
		const url = this.getUrl(data.src);
		const image = await this.loadImage(url);
		const rect = data.rect || { left: 0, right: 0, top: 0, bottom: 0 };
		const { left = 0, right = 0, top = 0, bottom = 0 } = rect;
		const { width = 0, height = 0 } = data;
		const clipWidth = image.width * (1 - left - right);
		const clipHeight = image.height * (1 - top - bottom);
		const scaleX = width / clipWidth;
		const scaleY = height / clipHeight;

		return {
			type: 'image',
			url,
			mode: 'clip',
			offset: {
				x: -image.width * scaleX * left,
				y: -image.height * scaleY * top
			},
			scale: { x: scaleX, y: scaleY },
			opacity: data?.opacity || 1
		};
	};
}
