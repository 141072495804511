import Color from 'color';
import { IFill, UI } from 'leafer-ui';

import { ThemeValue, ColorInfo, Gradient, FillData, ImageData } from '@/types/element';
import ImageHandle from './image';

export interface ColorGradientOption {
	theme: ThemeValue['color'];
	imageHandle?: ImageHandle;
	width?: number;
	height?: number;
}

export function checkSameColor(val1: string, val2?: IFill) {
	if (!val2 || typeof val2 != 'string') return false;
	const v1 = Color(val1);
	const v2 = Color(val2);
	const v1Obj = v1.object();
	const v2Obj = v2.object();
	return v1.alpha() == v2.alpha() && [v1Obj.r, v1Obj.g, v1Obj.b].join(',') == [v2Obj.r, v2Obj.g, v2Obj.b].join(',');
}

export function getPptColor(color: ColorInfo, opt: Partial<ColorGradientOption>) {
	const { theme } = opt;
	// 兼容老数据
	if (typeof color == 'string') return color;
	let val: string | undefined = '#000000';
	switch (color.type) {
		case 1:
			val = color.value;
			break;
		case 2:
			val = theme?.[color.value]?.value;
			break;
		default:
	}
	let rgb: any = Color(val || '#000000');

	const { mods = {} } = color;
	const { alpha, lumMod = 0, lumOff = 0, satMod = 0, satOff = 0, tint = 0, shade = 0, hueOff = 0, hueMod = 0 } = mods;

	if ('lumMod' in mods || 'lumOff' in mods) {
		const [h, s, l] = rgb.hsl().color;
		let newL = l / 100;
		if (lumMod) {
			newL = newL * lumMod;
		}
		if (lumOff) {
			newL += lumOff;
		}
		rgb = rgb.hsl(h, s, newL * 100);
	}
	if ('hueMod' in mods || 'hueOff' in mods) {
		const [h, s, l] = rgb.hsl().color;
		let newH = h / 100;
		if (hueMod) {
			newH = newH * hueMod;
		}
		if (hueOff) {
			newH += hueOff;
		}
		rgb = rgb.hsl(newH * 100, s, l);
	}
	if ('satMod' in mods || 'satOff' in mods) {
		const [h, s, l] = rgb.hsl().color;
		let newS = s / 100;
		if (satMod) {
			newS = newS * satMod;
		}
		if (satOff) {
			newS += satOff;
		}
		rgb = rgb.hsl(h, newS * 100, l);
	}
	if ('shade' in mods) {
		const s = shade;
		const { r, g, b } = rgb.object();
		rgb = Color.rgb([r * s, g * s, b * s]);
	}
	if ('tint' in mods) {
		const t = tint;
		const { r, g, b } = rgb.object();
		rgb = Color.rgb([r + (255 - r) * t, g + (255 - g) * t, b + (255 - b) * t]);
	}
	const { color: rgbList } = rgb.rgb();

	return `rgba(${rgbList.map((item: number) => Math.round(item)).join(',')},${typeof alpha == 'number' ? alpha : 1})`;
}

export function getLineCoords(width: number, height: number, angle?: number, _scaled?: boolean) {
	if (!angle) {
		// return { x1: 0, y1: 0, x2: width, y2: 0 }
		return {
			from: 'left',
			to: 'right'
		};
	}
	const radian = (angle * Math.PI) / 180;
	const residue = ((Math.abs(90 - angle) % 90) * Math.PI) / 180;
	const radius = Math.abs(Math.sin(residue) * (width + Math.cos(residue) * (height / Math.sin(residue))));
	const xLine = Math.abs(Math.floor(Math.cos(radian) * radius));
	const yLine = Math.abs(Math.floor(Math.sin(radian) * radius));

	if (angle < 90) {
		return {
			from: {
				type: 'px',
				x: 0,
				y: 0
			},
			to: {
				type: 'px',
				x: xLine,
				y: yLine
			}
		};
	}
	if (angle == 90) {
		return {
			from: {
				type: 'px',
				x: 0,
				y: 0
			},
			to: {
				type: 'px',
				x: 0,
				y: height
			}
		};
	}
	if (angle < 180) {
		return {
			from: {
				type: 'px',
				x: width,
				y: 0
			},
			to: {
				type: 'px',
				x: width - xLine,
				y: yLine
			}
		};
	}
	if (angle == 180) {
		return {
			from: {
				type: 'px',
				x: width,
				y: 0
			},
			to: {
				type: 'px',
				x: 0,
				y: 0
			}
		};
	}
	if (angle < 270) {
		return {
			from: {
				type: 'px',
				x: width,
				y: height
			},
			to: {
				type: 'px',
				x: width - xLine,
				y: height - yLine
			}
		};
	}
	if (angle == 270) {
		return {
			from: {
				type: 'px',
				x: 0,
				y: height
			},
			to: {
				type: 'px',
				x: 0,
				y: 0
			}
		};
	}

	return {
		from: {
			type: 'px',
			x: 0,
			y: height
		},
		to: {
			type: 'px',
			x: xLine,
			y: height - yLine
		}
	};
}

export function getRadialCoords(_width: number, _height: number, data: Gradient) {
	const { fillToRect } = data;
	const { l = 0, t = 0, r = 0, b = 0 } = fillToRect;
	// const { l: tl = 0, t: tt = 0, r: tr = 0, b: tb = 0 } = data.tileRect;
	const isCenter = l == 0.5 && t == 0.5 && r == 0.5 && b == 0.5;

	return {
		// stretch: 1,
		from: {
			type: 'percent',
			x: l,
			y: t
		},
		to: {
			type: 'percent',
			x: isCenter ? 1 : r,
			y: isCenter ? 1 : b
		}
	};
}

export function getGradient(data: Gradient, opt: ColorGradientOption) {
	const { width = 0, height = 0 } = opt || {};
	return {
		type: data.type,
		stops: data.colorStops.map((item) => ({
			...item,
			color: getPptColor(item.color, opt)
		})),
		...(data.type == 'radial' ? getRadialCoords(width, height, data) : getLineCoords(width, height, data.angle))
	} as UI['fill'];
}

export async function getRenderFill(fill: NonNullable<FillData>, opt: ColorGradientOption) {
	switch (fill.type) {
		case 1:
			return fill.color && getPptColor(fill.color, opt);
		case 2:
			return fill.gradient && getGradient(fill.gradient, opt);
		case 3:
			return await opt.imageHandle?.getImageFill({
				src: fill.src,
				rect: fill.rect,
				width: opt.width,
				height: opt.height,
				opacity: 1
			} as ImageData);
	}
}
