export const viewportSize = {
	width: 1280,
	height: 720,
	type: 'custom'
};
export const layoutList = [
	{
		id: '1',
		name: 'l0',
		layoutName: '空白',
		elements: []
	}
];
export const themeMap = {
	t0: {
		name: 'Office​​',
		color: {
			dk1: {
				type: 1,
				value: '#000000',
				mods: {}
			},
			lt1: {
				type: 1,
				value: '#FFFFFF',
				mods: {}
			},
			dk2: {
				type: 1,
				value: '#0E2841',
				mods: {}
			},
			lt2: {
				type: 1,
				value: '#E8E8E8',
				mods: {}
			},
			accent1: {
				type: 1,
				value: '#156082',
				mods: {}
			},
			accent2: {
				type: 1,
				value: '#E97132',
				mods: {}
			},
			accent3: {
				type: 1,
				value: '#196B24',
				mods: {}
			},
			accent4: {
				type: 1,
				value: '#0F9ED5',
				mods: {}
			},
			accent5: {
				type: 1,
				value: '#A02B93',
				mods: {}
			},
			accent6: {
				type: 1,
				value: '#4EA72E',
				mods: {}
			},
			hlink: {
				type: 1,
				value: '#467886',
				mods: {}
			},
			folHlink: {
				type: 1,
				value: '#96607D',
				mods: {}
			}
		}
	}
};
export const masterList = [
	{
		id: '10001',
		themeName: 't0',
		layouts: ['l0'],
		elements: [],
		colorMap: {
			bg1: 'lt1',
			tx1: 'dk1',
			bg2: 'lt2',
			tx2: 'dk2',
			accent1: 'accent1',
			accent2: 'accent2',
			accent3: 'accent3',
			accent4: 'accent4',
			accent5: 'accent5',
			accent6: 'accent6',
			hlink: 'hlink',
			folHlink: 'folHlink'
		}
	}
];
export const defaultStyles = {
	defPPr: {},
	lvl1pPr: {
		fontSize: 18,
		align: 'left',
		tabSize: 96
	},
	lvl2pPr: {
		fontSize: 18,
		align: 'left',
		tabSize: 96,
		marginLeft: 48
	},
	lvl3pPr: {
		fontSize: 18,
		align: 'left',
		tabSize: 96,
		marginLeft: 96
	},
	lvl4pPr: {
		fontSize: 18,
		align: 'left',
		tabSize: 96,
		marginLeft: 144
	},
	lvl5pPr: {
		fontSize: 18,
		align: 'left',
		tabSize: 96,
		marginLeft: 192
	},
	lvl6pPr: {
		fontSize: 18,
		align: 'left',
		tabSize: 96,
		marginLeft: 240
	},
	lvl7pPr: {
		fontSize: 18,
		align: 'left',
		tabSize: 96,
		marginLeft: 288
	},
	lvl8pPr: {
		fontSize: 18,
		align: 'left',
		tabSize: 96,
		marginLeft: 336
	},
	lvl9pPr: {
		fontSize: 18,
		align: 'left',
		tabSize: 96,
		marginLeft: 384
	}
};
