import { IUI, Line, Text } from 'leafer-ui'

import { GroupData, Element, ImageData, BaseElement, TextData as ElementTextData } from '@/types/element'
import { getRelativeUrl } from './image'

type GroupOption = {
  offLeft: number,
  offTop: number,
  scaleX: number,
  scaleY: number,
}[]

function getGroupOption(group?: GroupOption) {
  const list = group || []
  const { scaleX, scaleY } = list.reduce((total, cur) => ({
    scaleX: total.scaleX * cur.scaleX,
    scaleY: total.scaleY * cur.scaleY,
  }), { scaleX: 1, scaleY: 1 })

  return {
    scaleX,
    scaleY,
    offTop: list.length ? list[list.length - 1].offTop : 0,
    offLeft: list.length ? list[list.length - 1].offLeft : 0,
  }
}

async function getBase(event: IUI, group?: GroupOption) {
  let { rotation } = event
  let { x = 0, y = 0, width = 0, height = 0 } = event.__local || {}
  const { offLeft, offTop, scaleX  } = getGroupOption(group)

  if (rotation && event.tag != 'Line') {
    if (rotation < 0) rotation += 360
    // const radian = rotation * Math.PI / 180
    // const sinValue = Math.abs(Math.sin(radian))
    // const cosValue = Math.abs(Math.cos(radian))
    const newWidth = width
    const newHeight = height
    // width = (newWidth / sinValue - newHeight / cosValue) / (cosValue / sinValue - sinValue / cosValue)
    // height = (newWidth / cosValue - newHeight / sinValue) / (sinValue / cosValue  - cosValue / sinValue)
    width = event.width || 0
    height = event.height || 0
    x += (newWidth - width) / 2
    y += (newHeight - height) / 2
  }

  const base: Partial<BaseElement> = {
    left: x + offLeft,
    top: y + offTop,
    width,
    height,
    rot: rotation || 0,
  }

  if (event?.data?.border?.fill) {
    base.border = {
      ...event.data.border,
      width: event.data.border.width * scaleX
    }
  }

  return base
}

function getFillImage(event: IUI) {
  const shape = event.children?.find(item => item.data?.isElementShape)
  const imageEvent = shape || event

  return typeof imageEvent?.fill == 'object' && 'url' in imageEvent.fill ? getRelativeUrl(imageEvent.fill.url) as string : ''
}

async function getText(event: IUI, group?: GroupOption) {
  const shapeBox = event.children?.find(item => item.data?.isShapeBox)
  const shape = (shapeBox || event).children?.find(item => item.data?.isElementShape)
  const textEvents = (event.children?.filter(item => item.tag == 'Text') || []) as Text[]
  const validText = event.children?.find(item => item.tag == 'Text') as Text

  let width = event.width || 0
  let height = event.height || 0

  const mainShape = shapeBox || shape
  if (mainShape) {
    const bounds = mainShape.__local
    width = bounds?.width || 0
    height = bounds?.height || 0
  }

  const text = structuredClone(event.data?.text || []) as ElementTextData['text']

  text.forEach((it, index) => {
    if (validText) {
      if (validText.textAlign) it.align = validText.textAlign
    }
    it.text.forEach((_it) => {
      const _event = textEvents[index]
      // _it.fontSize = (_event?.fontSize || 0) * 0.75 * (event.data?.textScale || 1)
      _it.fontSize = _event.data.fontSize
      if (_event.data?.fill) {
        _it.fill = _event.data.fill
      }
    })
  })

  const data: Partial<ElementTextData> = {
    ...event.data,
    ...await getBase(event, group),
    textScale: 1,
    text,
    width,
    height,
  }

  if (shape && data.fill) {
    const backgroundImage = getFillImage(shape)
    if (backgroundImage) data.fill = {
      ...data.fill,
      src: backgroundImage
    }
  }

  return data
}

async function getImage(event: IUI, group?: GroupOption): Promise<Partial<ImageData>> {
  return {
    ...event.data,
    ...await getBase(event, group),
    src: getFillImage(event)
  } as ImageData
}

async function getLine(event: IUI, group?: GroupOption) {
  const baseData = await getBase(event, group)
  if (event.tag == 'Line') {
    baseData.width = Math.floor((event as Line).toPoint.x * 1000) / 1000
    baseData.height = Math.floor((event as Line).toPoint.y * 1000) / 1000
    baseData.rot = 0
  }
  return {
    ...event.data,
    ...baseData,
  }
}

function getElement(event: IUI, group?: GroupOption) {
  if (event.data?.type == 'text') {
    return getText(event, group)
  }
  if (event.data?.type == 'image') {
    return getImage(event, group)
  }
  if (event.data?.type == 'line') {
    return getLine(event, group)
  }
  if (event.data?.type == 'group') {
    return getGroup(event, group)
  }
  return event.data || null
}

async function getGroup(event: IUI, group?: GroupOption): Promise<Partial<GroupData>> {
  const data = event.data || { offLeft: 0, offTop: 0 }
  const elements = []

  const baseData = await getBase(event, group)
  
  for (const item of event.children || []) {
    const groupOption: GroupOption[0] = {
      offLeft: data.offLeft,
      offTop: data.offTop,
      scaleX: data.scaleX,
      scaleY: data.scaleY,
    }
    const _data = await getElement(item, group ? [...group, groupOption] : [groupOption])
    if (_data) {
      elements.push(_data as Element)
    }
  }

  return {
    ...data,
    ...baseData,
    elements,
  }
}

export default async function leaferDataToJson(events?: IUI[]): Promise<Element[]> {
  const list: Element[] = []
  for (const event of (events || [])) {
    const data = await getElement(event)
    if (data) list.push(data as Element)
  }
  return list
}