const makeNR = {
	tit: /^这里输入页面标题/,
	desc: /^这里输入页面描述/,
	ctit: /^输入结论标题/,
	cdesc: /^结论标题描述/,
	'contents#ltit': /^输入小标题(\d{1,2})$/,
	'contents#ldesc': /^这里是描述内容(\d{1,2})/,
	'contents#seq': /^序号(\d{1,2})$/
};
const makeRWJS = {
	tit: /^这里输入页面标题/,
	desc: /^这里输入页面描述/,
	'members#name': /^姓名\s{0,1}(\d{1})/,
	'members#title': /^职称\s{0,1}(\d{1})/,
	'members#desc': /^人物介绍\s{0,1}(\d{1})/
};

// @连接符不需要取match 值，#连接符取match[1][2]值

const structs: any = {
	// 封面页
	FM: {
		tit: /总结汇报/, // 主标题
		titEn: /英文主标题/, // 英文标题
		subt: /副标题/,
		'content@author': /^少小刻$/,
		'content@company': /^少刻科技$/,
		'content@date': /^20\d{2}(\.\d{2}){0,2}/
	},
	// 目录页
	ML: {
		name: /^目录名称/, // '目录,目录名称',
		'dirs#ltit': /标题\s{0,1}(\d{1,2})/
	},
	// 过渡页
	GD: {
		tit: /^这里输入页面标题/,
		desc: /^这里输入页面描述/,
		'structure@seq': /^(\d{1})$/, // 1,2,3,
		'structure@fillSeq': /^0(\d{1})$/, //01,02,03,
		'structure@partSeq': /^part\s{0,1}\d{1}$/, // part1,part2,part3/,
		'structure@partFillSeq': /^part\s{0,1}0\d{1}$/, ///part01,part02,part03/,
		'structure@partEnSeq': /part\s{0,1}[a-z]{3,5}/i, // partone,parttwo,partthree
		'structure@ltit': /输入标题名称/ // 输入标题名称,输入小标题
	},
	// 内容页: 1-10段，NR1，NR2，NR3，NR4，NR5，NR6，NR
	NR: { ...makeNR },
	NR1: { ...makeNR },
	NR2: { ...makeNR },
	NR3: { ...makeNR },
	NR4: { ...makeNR },
	NR5: { ...makeNR },
	NR6: { ...makeNR },
	// 多项内容页
	DXNR: {
		tit: /^这里输入页面标题/,
		desc: /^这里输入页面描述/,
		ctit: /^输入结论标题/,
		'contents#ltit': /^输入小标题(\d{1})$/,
		'contents#desc': /^这里是描述内容(\d{1})/
	},
	// 人物介绍 1-多人，RWJS1，RWJS
	RWJS: { ...makeRWJS },
	RWJS1: { ...makeRWJS },
	// 多图展示
	DUZS: {
		tit: /^这里输入页面标题/,
		ctit: /^输入结论标题/,
		desc: /^这里输入页面描述/,
		'pics#ltit': /^输入小标题(\d{1,2})$/,
		'pics#ldesc': /^输入描述(\d{1,2})$/
	},
	// 时间轴页
	SJZ: {
		tit: /^这里输入页面标题/,
		// 'sections#date': /^20\d{2}(\.\d{2}|年\d{2}月)$/,
		'sections#date': /^20\d{2}[.|年]0(\d{1}).?$/,
		'sections#ltit': /^输入小标题(\d{1})$/,
		'sections#desc': /^这里是描述内容(\d{1})/,
		'sections#labels': /节点(\d{1})标签(\d{1})/
	},
	SJZS: {
		tit: /^这里输入页面标题/,
		ctit: /^输入结论标题/,
		// 'assets#ltit': '',
		// 'assets#ldesc': '',
		'assets#key': /^数据名称(\d{1})$/,
		'assets#val': /^(\d{1})0%$/
	},
	// 多LOGO页
	LOGO: {
		tit: /^合作(品牌|伙伴)/,
		desc: /^合作描述/,
		'LOGO#ltit': /LOGO(\d{1,2})小标题/,
		'LOGO#desc': /LOGO(\d{1,2})描述/
	},
	// 联系方式页
	LXFS: {
		'contact@company': /^这里输入公司名称/,
		'contact@name': /^这里输入联系人姓名/,
		'contact@tel': /^这里输入电话信息/,
		'contact@email': /^这里输入邮箱信息/,
		'contact@address': /^这里输入公司地址/,
		'contact@website': /^这里输入网址信息/
	}
};
export default structs;
