import { Path, Box } from 'leafer-ui'

import { Element } from '@/types/element'
import { ElementBaseOption } from '../../types'

export default async function getPath(option: ElementBaseOption, data: Element) {
  if (!data.shape?.path?.length) return null

  const { path: pathList } = data.shape
  
  const box = new Box({
    ...option,
    shadow: undefined,
    fill: 'transparent',
    stroke: 'transparent',
    strokeWidth: 0,
    padding: 0,
    data: {
      ...option.data,
      isElementShape: false,
      isShapeBox: true,
    }
  })

  pathList.forEach(info => {
    const { width, height, ts } = info
    const path = new Path({
      ...option,
      width,
      height,
      x: 0,
      y: 0,
      path: ts.map(item => [...item.type, ...item.pos.map((it, i) => {
        if (i % 2 == 0) {
          return it * (data.width / width)
        }
        return it * (data.height / height)
      })]).flat().join(' '),
    })
    box.add(path)
  })

  return box
}