import { IUI, App } from 'leafer-ui'
import { useCallback, useState } from 'react'
import { BackgroundColor } from '@icon-park/react'

import { getBaseOptions, getRenderFill, getShape } from '@/components/Editor/utils'
import { FillData, TextData, ThemeValue } from '@/types/element'
import SelectColor from '../SelectColor'
import ImageHandle from '@/components/Editor/utils/image'

interface EditTextBackgroundProps {
  target: IUI
  app: App
  theme: ThemeValue['color']
  imageHandle: ImageHandle
}

export default function EditTextBackground({ target, app, theme, imageHandle }: EditTextBackgroundProps) {
  const [fill, setFill] = useState<FillData | undefined>(() => target?.data?.fill)

  const handleChange = useCallback(async (value: FillData) => {
    const shape = target.children?.find(item => item.data?.isElementShape || item.data?.isShapeBox)
    if (target.data) target.data.fill = value
    if (shape) {
      const fill = await getRenderFill(value, { theme, width: shape.width, height: shape.height })
      if (shape.data?.isShapeBox) {
        for (const path of (shape.children || [])) {
          path.fill = fill
        }
      } else {
        shape.fill = fill
      }
    } else {
      const data = target.data as TextData
      const option = await getBaseOptions(data, { theme, imageHandle })
      if (data.shape) {
        const node  = await getShape(data.shape.type, data, {
          ...option,
          x: 0,
          y: 0,
          locked: true,
        })
        if (node) {
          target.add(node, 0)
          if (data.flipX) node.flip('x')
          if (data.flipY) node.flip('y')
        }
      }
    }

    setFill(value)
    app.emit('changeData')
  }, [target, app, theme, imageHandle])

  return <SelectColor
    value={fill}
    onChange={handleChange}
    theme={theme}
    tip='填充色'
    icon={<BackgroundColor />}
  />
}