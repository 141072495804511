import { App } from 'leafer-ui'

import { ISnapshotSave } from '../hook/snapshot'
import { SlideData } from '@/types/element'

export interface SnapshotListenerOption {
  app: App
  onUndo(): void
  onRedo(): void
  onSave: ISnapshotSave
}

export default class SnapshotListener {
  app: SnapshotListenerOption['app']

  onUndo: SnapshotListenerOption['onUndo']

  onRedo: SnapshotListenerOption['onRedo']

  onSave: ISnapshotSave

  constructor ({ app, onUndo, onRedo, onSave }: SnapshotListenerOption) {
    this.app = app
    this.onUndo = onUndo
    this.onRedo = onRedo
    this.onSave = onSave
  }

  private saveSnapshot = async (data: SlideData) => {
    await this.onSave(data)
  }

  public register = () => {
    const { app } = this
    if (!app?.editor) return

    app.on('undo', this.onUndo)
    app.on('redo', this.onRedo)
    app.on('saveSnapshot', this.saveSnapshot)
  }

  public off = () => {
    const { app } = this
    if (!app?.editor) return

    app.off('undo', this.onUndo)
    app.off('redo', this.onRedo)
    app.off('saveSnapshot', this.saveSnapshot)
  }
}