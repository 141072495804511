import { Polygon } from 'leafer-ui'

import { Element } from '@/types/element'
import { ElementBaseOption } from '../../types'

export default async function getStar5(option: ElementBaseOption, data: Element) {
  const { width, height } = data
  // const radius = typeof data.shape?.radius == 'number' ? data.shape.radius : 0.5
  // const angle = 36
  // const radian = (angle / 2) * Math.PI / 180
  // const oppositeX = width / Math.cos(radian)
  // const oppositeY = height / Math.cos(radian)
  // const adjacentX = width * Math.tan(radian)
  // const adjacentY = height * Math.tan(radian)
  const radiusX = 0.18
  const radiusY = 0.38
  const radian = Math.atan(width * (0.5 - radiusX) / height)
  const p1 = height * radiusY * Math.tan(radian)
  const p2 = height * (1 - radiusY) * Math.tan(radian)

  return new Polygon({
    ...option,
    points: [
      width / 2,
      0,
      width / 2 + p1,
      height * radiusY,
      width,
      height * radiusY,
      width / 2 + p2,
      height * (1 - radiusY),
      width * (1 - radiusX),
      height,
      width / 2,
      height / 2 + height * 0.26,
      width * radiusX,
      height,
      width / 2 - p2,
      height * (1 - radiusY),
      0,
      height * radiusY,
      width / 2 - p1,
      height * radiusY
    ]
  })
}