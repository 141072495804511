import { Rect } from 'leafer-ui';

import { Element } from '@/types/element';
import { ElementBaseOption } from '../../types';

export default async function getRect(option: ElementBaseOption, _data: Element) {
	const rect = new Rect({
		...option
	});

	return rect;
}
