/**
 * 行业类目
 * */
import request from '@/common/fetch';

export const getIndustryList = (params: any) =>
	request({
		url: '/res/v1/industry/list',
		method: 'get',
		params
	});

export const getIndustryOption = () =>
	request({
		url: '/res/v1/industry/options',
		method: 'get'
	});

export const postIndustry = (data: any) =>
	request({
		url: '/res/v1/industry',
		method: 'post',
		data: data
	});
export const putIndustry = (id: any, data: any) =>
	request({
		url: `/res/v1/industry/${id}`,
		method: 'put',
		data: data
	});
export const delIndustry = (id: string | number) =>
	request({
		url: `/res/v1/industry/${id}`,
		method: 'DELETE'
	});
