import { useContext, useEffect, useState } from 'react';
import { Table, Form, Input, Space, Button, Grid, Select, Image, Message, Modal } from '@arco-design/web-react';
import { IconSearch, IconPlus, IconSafe } from '@arco-design/web-react/icon';
import { GlobalContext } from '@/hooks/context';

import { formatDate, getSessionStorage, delSessionStorage } from '@/utils';

import { getMediaList, delMedia, postSyncMediaProd } from '@/api/material';
import { getIndustryOption } from '@/api/industry';

import AuthModal from '@/views/layout/component/loginProd';
import AddMediaModal from './addMediaModal';

const envAppMode = import.meta.env.VITE_APP_MODE;

export default () => {
	const [form] = Form.useForm();
	const FormItem = Form.Item;
	const Row = Grid.Row;
	const Col = Grid.Col;

	const columns: any = [
		{
			title: 'ID',
			dataIndex: 'id',
			width: 80
		},
		{
			title: '媒体资源',
			key: 'preview',
			fixed: 'left',
			width: 100,
			render: (_: any, record: any) => {
				let process = '';
				if (record.url.indexOf('?') > -1) {
					process = '&x-oss-process=image/resize,m_fill,w_160';
				} else {
					process = '?x-oss-process=image/resize,m_fill,w_160';
				}
				return (
					<img
						style={{ width: 80, cursor: 'pointer' }}
						src={cdnUrl + record.url + process}
						onClick={() => {
							setVisibleSrc(cdnUrl + record.url);
							setVisible(true);
						}}
					/>
				);
			}
		},
		{
			title: '宽高',
			dataIndex: 'width',
			render: (_: any, record: any) => (
				<>
					{record.width}px * {record.height}px
				</>
			)
		},
		{
			title: '行业名称',
			dataIndex: 'industryName'
		},
		{
			title: '提交人',
			dataIndex: 'creatorName'
		},
		{
			title: '创建时间',
			key: 'createTime',
			render: (_: any, record: any) => <>{formatDate(record.createTime)}</>
		}
	];

	const { cdnUrl } = useContext(GlobalContext);

	const [loading, setLoading] = useState(false);
	const [list, setList] = useState<any[]>([]);
	const [total, setTotal] = useState(0);

	const [industryList, setIndustryList] = useState<any[]>([]);

	const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);

	const [visible, setVisible] = useState(false);
	const [visibleSrc, setVisibleSrc] = useState('');

	const [showAddMediaModal, setShowAddMediaModal] = useState(false);
	const [showAuthModal, setShowAuthModal] = useState(false);

	const [query, setQuery] = useState<any>({
		page: 1,
		industry: undefined,
		keywords: undefined
	});

	const onPageChange = (page: number) => {
		setQuery(() => ({ ...query, page }));
	};
	const handleSearch = (val: any) => {
		console.log('val', val);
		Object.keys(query).forEach((key) => {
			if (val[key]) {
				query[key] = val[key];
			} else {
				query[key] = undefined;
			}
		});
		console.log('query', query);
		setQuery(() => ({ ...query, page: 1 }));
	};

	// 同步到产线
	const handleSyncProd = async () => {
		try {
			const prodToken = getSessionStorage('prod_token');
			const prodTokenExpires = getSessionStorage('prod_token_expires');
			const nowTime = new Date().getTime();
			if (prodToken && prodTokenExpires && nowTime < parseInt(prodTokenExpires)) {
				setLoading(true);

				await postSyncMediaProd({
					accessToken: prodToken
				});

				Message.success('同步完成');
			} else {
				delSessionStorage('prod_token');
				delSessionStorage('prod_token_expires');
				setShowAuthModal(true);
			}
		} catch (error) {
			console.log('error', error);
		} finally {
			setLoading(false);
		}
	};

	const getList = async () => {
		setLoading(true);
		let res = await getMediaList({
			...query,
			type: 'img',
			size: 10
		});
		setLoading(false);
		setList(res.data.list);
		setTotal(res.data.total);
	};

	const getIndustryList = async () => {
		let res = await getIndustryOption();
		setIndustryList(res.data);
	};

	const handleBatchDel = (type: string, id?: string) => {
		Modal.confirm({
			title: '提示',
			content: '确定要删除选中的记录吗？',
			style: {
				width: '300px'
			},
			onOk: async () => {
				if (type == 'single' && id) {
					await delMedia(id);
					getList();
				} else {
					await delMedia(selectedRowKeys.join(','));
					getList();
				}
			},
			onCancel: () => {}
		});
	};

	useEffect(() => {
		getList();
	}, [query]);

	useEffect(() => {
		getIndustryList();
	}, []);

	return (
		<>
			<div className="page-filter">
				<Form form={form} onSubmit={handleSearch}>
					<Row gutter={16}>
						<Col span={6}>
							<FormItem field="industry" label="行业">
								<Select options={industryList} placeholder="请选择行业" allowClear />
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem field="keywords" label="关键字">
								<Input allowClear placeholder="请输入关键字" />
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem>
								<Space>
									<Button type="outline" icon={<IconSearch />} htmlType="submit">
										搜索
									</Button>
									<Button type="primary" icon={<IconPlus />} onClick={() => setShowAddMediaModal(true)}>
										添加媒体资源
									</Button>
									{envAppMode != 'prod' && (
										<Button type="primary" icon={<IconSafe />} onClick={() => handleSyncProd()}>
											同步{envAppMode == 'test' ? '产线' : '测试'}
										</Button>
									)}
								</Space>
							</FormItem>
						</Col>
					</Row>
				</Form>
			</div>
			<div className="page-warp">
				<Table
					borderCell
					rowKey={(record) => record.id}
					loading={loading}
					columns={
						envAppMode != 'prod'
							? [
									...columns,
									{
										title: '操作',
										width: 100,
										key: 'action',
										render: (_: any, record: any) => (
											<>
												<Button type="text" onClick={() => handleBatchDel('single', record.id)}>
													删除
												</Button>
											</>
										)
									}
							  ]
							: columns
					}
					data={list}
					rowSelection={{
						selectedRowKeys,
						onChange: (selectedRowKeys: any[]) => {
							setSelectedRowKeys(selectedRowKeys);
						}
					}}
					pagination={{
						showTotal: true,
						showJumper: true,
						total: total,
						pageSize: 20,
						current: parseInt(query.page),
						onChange: onPageChange
					}}
					renderPagination={(paginationNode) => (
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								marginTop: 10
							}}>
							<Button type="outline" status="danger" disabled={selectedRowKeys.length == 0} onClick={() => handleBatchDel('batch')}>
								批量删除
							</Button>
							{paginationNode}
						</div>
					)}
				/>
			</div>

			{envAppMode != 'prod' && <AuthModal visible={showAuthModal} setVisible={setShowAuthModal} onCallback={() => handleSyncProd()} />}

			{envAppMode != 'prod' && <AddMediaModal visible={showAddMediaModal} setVisible={setShowAddMediaModal} industryList={industryList} onCallback={() => getList()} />}

			<Image.Preview src={visibleSrc} visible={visible} onVisibleChange={setVisible} />
		</>
	);
};
