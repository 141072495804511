import request from '@/common/fetch';

// 列表
export const getLayoutList = () =>
	request({
		url: '/res/v1/template/layout/structs/1',
		method: 'get',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	});
// 编辑
export const putLayoutEdit = (id: any, data: any) =>
	request({
		url: `/res/v1/template/layout/struct/${id}`,
		data: data,
		method: 'put'
	});
