import { Ellipse } from 'leafer-ui';

import { Element } from '@/types/element';
import { ElementBaseOption } from '../../types';

export default async function getEllipse(option: ElementBaseOption, _data: Element) {
	// option.rx = data.width / 2
	// option.ry = data.height / 2
	return new Ellipse(option);
}
