import { Path } from 'leafer-ui';

import { Element } from '@/types/element';
import { ElementBaseOption } from '../../types';

export default async function getSnip2SameRect(option: ElementBaseOption, data: Element) {
	const { width, height } = data;
	const radius = typeof data.shape?.point1 == 'number' ? Math.floor(Math.min(width, height) * data.shape.point1) : 15;
	const radius1 = typeof data.shape?.point2 == 'number' ? Math.floor(Math.min(width, height) * data.shape.point2) : 0;
	const path = `M ${radius} 0 L ${width - radius} 0 L ${width} ${radius} L ${width} ${height - radius1} L ${width - radius1} ${height} L ${radius1} ${height} L 0 ${
		height - radius1
	} L 0 ${radius} Z`;

	return new Path({
		...option,
		path
	});
}
