import React, { useMemo, useState } from 'react'
import { App, IUI } from 'leafer-ui'

import { SlideData, ViewportSize, Theme } from '@/types/element'
import { MainContext } from './context/main'
import ImageHandle from './utils/image'
import Slide from './Slide'
import UndoButton from './Button/undo'
import RedoButton from './Button/redo'
import ReplaceImageButton, { ReplaceImageContentProps } from './Button/replaceImage'

interface PptEditorProps {
  children: React.ReactNode
  viewportSize?: ViewportSize
  layoutList?: SlideData[]
  masterList?: SlideData[]
  themeMap?: Theme
  cdnPrefix?: string
}

export default function Editor({
  children,
  viewportSize = { width: 1280, height: 720, type: 'blank' },
  layoutList,
  masterList,
  themeMap,
  cdnPrefix = 'https://gaippt-test-pub.res.gaippt.com/',
}: PptEditorProps) {
  const [canRedo, setCanRedo] = useState(false)
  const [canUndo, setCanUndo] = useState(false)
  const [editApp, setEditApp] = useState<App | undefined>()
  const [target, setTarget] = useState<IUI | null>()
  const imageHandle = useMemo(() => new ImageHandle({ prefix: cdnPrefix }), [cdnPrefix])

  return <MainContext.Provider
    value={{
      state: {
        canUndo,
        canRedo,
        viewportSize,
        layoutList,
        masterList,
        themeMap,
        imageHandle,
        editApp,
        target,
      },
      reducers: {
        setCanRedo,
        setCanUndo,
        setEditApp,
        setTarget,
      }
    }}
  >{children}</MainContext.Provider>
}

Editor.Slide = Slide

Editor.UndoButton = UndoButton

Editor.RedoButton = RedoButton

Editor.ReplaceImageButton = ReplaceImageButton

export type {
  ReplaceImageContentProps
}