/**
 * 版式
 */
import request from '@/common/fetch';

// 版式详情
export const getSlideDetail = (id: any) =>
	request({
		url: `/res/v1/template/${id}/layout`,
		method: 'get'
	});
// 编辑版式
export const putSlideEdit = (id: any, data: any) =>
	request({
		url: `/res/v1/template/layout/${id}`,
		data: data,
		method: 'put'
	});
// 添加版式
export const postSlide = (data: any) =>
	request({
		url: '/res/v1/template/layout',
		data: data,
		method: 'post'
	});

// 版式删除
export const delSlide = (id: string | number) =>
	request({
		url: `/res/v1/template/layout/${id}`,
		method: 'DELETE'
	});
// 版式上下架
export const putSlideStatus = (type: string | number, id: string | number) =>
	request({
		url: `/res/v1/template/layout/state/${type}/${id}`,
		method: 'put'
	});
