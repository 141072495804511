import ReactDOM from 'react-dom/client'
import { App, IUI } from 'leafer-ui'

import PopoverTool, { PopoverToolProps } from '@/components/PopoverTool'

export interface RenderPopoverToolProps {
  target: IUI
  canvas: HTMLCanvasElement
  container: HTMLDivElement
  onUploadImage?: PopoverToolProps['onUploadImage']
  imageUploadRender: PopoverToolProps['imageUploadRender']
  imageHandle: PopoverToolProps['imageHandle']
  app: App
  theme: PopoverToolProps['theme']
}

export default function renderPopoverTool({ target, canvas, container, imageUploadRender, app, imageHandle, theme }: RenderPopoverToolProps) {
  const { data } = target
  if (!['image', 'text'].includes(data?.type)) return null
  // if (data?.type == 'text' && (!data.text.length && !data.backgroundImage)) return null
  const div = document.createElement('div')
  const dom = ReactDOM.createRoot(div!)
  container.append(div)
  dom.render(<PopoverTool
    imageHandle={imageHandle}
    target={target}
    canvas={canvas}
    imageUploadRender={imageUploadRender}
    app={app}
    theme={theme}
  />)

  return {
    onClose: () => {
      container.removeChild(div)
      
      setTimeout(() => {
        dom.unmount()
      }, 0)  
    }
  }
}