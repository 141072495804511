import { Rect } from 'leafer-ui'

import { Element } from '@/types/element'
import { ElementBaseOption } from '../../types'

export default async function getRoundRect(option: ElementBaseOption, data: Element) {
  const radius = typeof data.shape?.radius == 'number' ? Math.floor(Math.min(data.width, data.height) * data.shape.radius) : 15

  return new Rect({
    ...option,
    cornerRadius: radius
  })
}