import { Polygon } from 'leafer-ui'

import { Element } from '@/types/element'
import { ElementBaseOption } from '../../types'

export default async function getDownArrow(option: ElementBaseOption, data: Element) {
  const { width, height } = data
  const radius = Math.max(height, width)
  const xRadius = width * (typeof data.shape?.point1 == 'number' ? data.shape.point1 : 0.5)
  let yRadius = Math.min(height, width) * (typeof data.shape?.point2 == 'number' ? data.shape.point2 : 0.5)
  if (radius - yRadius < 2) yRadius = radius

  return new Polygon({
    ...option,
    points: [
      (width - xRadius) / 2,
      0,
      (width - xRadius) / 2 + xRadius,
      0,
      (width - xRadius) / 2 + xRadius,
      height - yRadius,
      width,
      height - yRadius,
      0,
      height - yRadius,
      (width - xRadius) / 2,
      height - yRadius
    ]
  })
}