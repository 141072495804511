import { useCallback, useMemo, useState } from 'react'
import { UI } from 'leafer-ui'

import { Element } from '@/types/element'
import SlideElement, { SlideElementProps } from '../Element'

export interface SlideElementListProps {
  list: Element[]
  onAdd(e: UI | null, data: Element, isLast: boolean): void
  editable?: boolean
  baseIndex?: number
}

export default function SlideElementList({ list, onAdd, editable = false, baseIndex = 0 }: SlideElementListProps) {
  const [loadIndex, setLoadIndex] = useState(0)
  const loadList = useMemo(() => list.slice(0, loadIndex + 1), [list, loadIndex])
  const handleOnLoad: SlideElementProps['onLoad'] = useCallback((element, data, index, isLast) => {
    if (element) {
      element.zIndex = index
    }
    onAdd?.(element, data, isLast)
    setLoadIndex(i => i < list.length - 1 ? i + 1 : i)
  }, [onAdd, list])

  return loadList.map((item, index) => <SlideElement
    key={item.id || index}
    data={item}
    index={baseIndex + index}
    onLoad={handleOnLoad}
    editable={editable}
    isLast={index == list.length - 1}
  />)
}