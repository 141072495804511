import { forwardRef, useContext, useState, useMemo, useRef, useCallback, useImperativeHandle, useEffect } from 'react';
import { nanoid } from 'nanoid';
import { IExportOptions, UI } from 'leafer-ui';

import { SlideData, ThemeValue } from '@/types/element';
import { MainContext } from '../context/main';
import { SlideContext } from '../context/slide';
import useApp from '../hook/app';
import useSnapshot from '../hook/snapshot';
import { EditorListenerOption } from '../Listener/editor';
import SlideElementList, { SlideElementListProps } from '../List';
import { RenderPopoverToolProps, getAppJson, renderPopoverTool, getRenderFill, replaceFillImage } from '../utils';

export interface SlideProps {
	data: SlideData;
	editable?: boolean;
	onChange?(data: SlideData): void;
	imageUploadRender?: RenderPopoverToolProps['imageUploadRender'];
	onInitLoaded?(): void;
}

export interface SlideComponentRef {
	getData(): Promise<SlideData>;
	exportImage(type?: string, opt?: Partial<IExportOptions>): Promise<string>;
	replaceCurrentImage(url: string): Promise<void>;
}

export default forwardRef(function Slide({ data: propData, editable = false, onChange, imageUploadRender, onInitLoaded }: SlideProps, ref) {
	const [isInit, setIsInit] = useState(false);
	const mainContext = useContext(MainContext);
	const { layoutList, masterList, themeMap, viewportSize, imageHandle, target } = mainContext.state;
	const { setTarget } = mainContext.reducers;
	const [data, setData] = useState(propData);
	// const [target, setTarget] = useState<IUI | null>()
	const canvasKey = useMemo(() => nanoid(), []);
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const containerRef = useRef<HTMLDivElement>(null);
	const [layoutFinish, setLayoutFinish] = useState(false);
	const { onSnapshotInit, onSnapshotSave, onSnapshotRedo, onSnapshotUndo } = useSnapshot({ editable });
	const layout = useMemo(() => (layoutList || []).find((item) => item.name == data?.layoutRef), [data, layoutList]);
	const theme = useMemo(() => {
		if (!masterList || !themeMap || !data?.layoutRef) return {};
		for (const master of masterList) {
			if (master.themeName && master.colorMap && master.layouts?.includes(data.layoutRef)) {
				const res: ThemeValue['color'] = { ...themeMap[master.themeName].color };
				const map = themeMap[master.themeName].color;
				Object.entries(master.colorMap).forEach(([key, value]) => {
					if (value && map[value]) res[key] = map[value];
				});
				return res;
			}
		}
		return {};
	}, [data?.layoutRef, masterList, themeMap]);

	const themeFont = useMemo(() => {
		if (!masterList || !themeMap || !data?.layoutRef) return {};
		for (const master of masterList) {
			if (master.themeName && master.colorMap && master.layouts?.includes(data.layoutRef)) {
				return themeMap[master.themeName].font;
			}
		}
		return {};
	}, [data?.layoutRef, masterList, themeMap]);

	const onChangeData = useCallback<NonNullable<EditorListenerOption['onChangeData']>>(
		async (data) => {
			onChange?.(data);
		},
		[onChange]
	);

	const onUndo = useCallback(async () => {
		const d = await onSnapshotUndo();
		if (d) {
			setData(d);
			onChange?.(d);
		}
	}, [onSnapshotUndo, onChange]);

	const onRedo = useCallback(async () => {
		const d = await onSnapshotRedo();
		if (d) {
			setData(d);
			onChange?.(d);
		}
	}, [onSnapshotRedo, onChange]);

	const app = useApp({
		canvasRef,
		viewportSize,
		canvasKey,
		editable,
		onSelect: setTarget,
		onChangeData,
		onUndo,
		onRedo,
		onSnapshotSave
	});

	const onAdd: SlideElementListProps['onAdd'] = useCallback(
		(element, _data, isLast) => {
			if (!app || (app.id && app.id != canvasKey)) return;
			if (element) {
				const front = app.tree.children.find((item) => item.id == 'front');
				front && front.add(element);
			}
			if (isLast && !isInit) {
				onInitLoaded?.();
				setIsInit(true);
			}
		},
		[app, canvasKey, onInitLoaded, isInit]
	);

	const onLayoutAdd: SlideElementListProps['onAdd'] = useCallback(
		(element: UI, _data, isLast) => {
			if (!app || (app.id && app.id != canvasKey)) return;
			if (element) {
				const background = app.tree.children.find((item) => item.id == 'background');
				background && background.add(element);
			}
			if (isLast) setLayoutFinish(true);
		},
		[app, canvasKey]
	);

	useImperativeHandle(
		ref,
		(): SlideComponentRef => ({
			getData: async () => {
				if (!app) throw new Error('获取app失败');
				const result = await getAppJson(app);
				return result;
			},
			exportImage: async (type: string = 'jpg', { quality = 1, scale = 1, ...args } = {}) => {
				if (!app) throw new Error('app读取错误');
				// const frontFrame = (app?.tree.children || []).find(item => item.id == 'front')

				const result = await app.tree.export(type, {
					quality,
					scale,
					...args
					// screenshot: {
					//   x: frontFrame?.__world?.x || 0,
					//   y: frontFrame?.__world?.y || 0,
					//   width: frontFrame?.__world?.width || 0,
					//   height: frontFrame?.__world?.height || 0,
					// },
				});
				return result.data;
			},
			replaceCurrentImage: async (url) => {
				if (!target) throw new Error('未找到选中对象');
				if (!imageHandle) throw new Error('图片处理器初始化失败');
				await replaceFillImage(url, target, imageHandle);
			}
		}),
		[app, target, imageHandle]
	);

	useEffect(() => {
		setIsInit(false);
		setData(propData);
		editable && onSnapshotInit(propData);
	}, [propData, editable, onSnapshotInit]);

	const handleSetBackground = useCallback(async () => {
		if (!data || !app || (app.id && app.id != canvasKey) || !imageHandle) return;
		app.data = data;
		const background = data?.fill || layout?.fill;
		const backgroundFrame = app.tree.children.find((item) => item.id == 'background');
		if (backgroundFrame) {
			if (background) {
				const fill = await getRenderFill(background, { theme, width: viewportSize?.width, height: viewportSize?.height, imageHandle });
				if (fill && app.data.id == data.id) backgroundFrame.fill = fill;
			} else {
				backgroundFrame.fill = '#fff';
			}
		}
	}, [app, canvasKey, data, imageHandle, theme, viewportSize, layout]);

	useEffect(() => {
		handleSetBackground();
	}, [handleSetBackground]);

	useEffect(() => {
		let popover = null;
		if (editable && app && target && canvasRef.current && containerRef.current && imageHandle) {
			popover = renderPopoverTool({
				target,
				canvas: canvasRef.current,
				container: containerRef.current,
				imageUploadRender,
				app,
				imageHandle,
				theme
			});
		}
		return () => {
			popover?.onClose();
		};
	}, [target, canvasRef, containerRef, imageUploadRender, app, imageHandle, editable, theme]);

	return (
		<SlideContext.Provider
			value={{
				state: {
					theme,
					themeFont,
					app,
					target
				}
			}}>
			<div style={{ position: 'relative', height: '100%' }} ref={containerRef}>
				<canvas key={canvasKey} ref={canvasRef} style={{ width: '100%', height: '100%' }} />
			</div>
			{data && app && app?.id == canvasKey ? (
				<>
					{layout ? <SlideElementList list={layout.elements} onAdd={onLayoutAdd} baseIndex={10} /> : null}
					{layoutFinish || !layout?.elements.length ? <SlideElementList list={data.elements} onAdd={onAdd} editable={editable} /> : null}
				</>
			) : null}
		</SlideContext.Provider>
	);
});
