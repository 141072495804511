import { useContext } from 'react'

import { MainContext } from '../context/main'

interface RedoButtonProps {
  children: React.ReactElement
}

export default function RedoButton({ children }: RedoButtonProps) {
  const { canRedo, editApp } = useContext(MainContext).state

  return <children.type {...children.props} disabled={!canRedo} onClick={() => editApp?.emit('redo')} />
}