import { ShapeType, Element } from '@/types/element'
import { ElementBaseOption } from '../../types'

import getPath from './path'
import getRect from './rect'
import getRoundRect from './roundRect'
import getFlowChartTerminator from './flowChartTerminator'
import getEllipse from './ellipse'
import getArc from './arc'
import getTriangle from './triangle'
import getFlowChartExtract from './flowChartExtract'
import getFlowChartMerge from './flowChartMerge'
import getUpArrow from './upArrow'
import getDownArrow from './downArrow'
import getLeftArrow from './leftArrow'
import getRightArrow from './rightArrow'
import getHomePlate from './homePlate'
import getHexagon from './hexagon'
import getParallelogram from './parallelogram'
import getTrapezoid from './trapezoid'
import getDiamond from './diamond'
import getPentagon from './pentagon'
import getDonut from './donut'
import getChevron from './chevron'
import getStar5 from './star5'
import getRound2DiagRect from './round2DiagRect'
import getRound2SameRect from './round2SameRect'
import getRound1Rect from './round1Rect'
import getSnip1Rect from './snip1Rect'
import getSnip2SameRect from './snip2SameRect'
import getSnip2DiagRect from './snip2DiagRect'
import getSnipRoundRect from './snipRoundRect'
import getMathPlus from './mathPlus'
import getMathMinus from './mathMinus'
import getMathMultiply from './mathMultiply'
import getPlus from './plus'
import getCorner from './corner'
import getDiagStripe from './diagStripe'
import getWedgeRectCallout from './wedgeRectCallout'
import getRtTriangle from './rtTriangle'

export default async function getShape(type: ShapeType, data: Element, option: ElementBaseOption) {
  if (option.data) {
    option.data.isElementShape = true
  } else {
    option.data = {
      isElementShape: true
    }
  }
  switch (type) {
  case 'path':
    return await getPath(option, data)
  case 'rect':
    return await getRect(option, data)
  case 'roundRect':
    return await getRoundRect(option, data)
  case 'flowChartTerminator':
    return await getFlowChartTerminator(option, data)
  case 'ellipse':
    return await getEllipse(option, data)
  case 'arc':
    return await getArc(option, data)
  case 'triangle':
    return await getTriangle(option, data)
  case 'flowChartExtract':
    return await getFlowChartExtract(option, data)
  case 'flowChartMerge':
    return await getFlowChartMerge(option, data)
  case 'rtTriangle':
    return await getRtTriangle(option, data)
  case 'upArrow':
    return await getUpArrow(option, data)
  case 'downArrow':
    return await getDownArrow(option, data)
  case 'leftArrow':
    return await getLeftArrow(option, data)
  case 'rightArrow':
    return await getRightArrow(option, data)
  case 'homePlate':
    return await getHomePlate(option, data)
  case 'hexagon':
    return await getHexagon(option, data)
  case 'parallelogram':
    return await getParallelogram(option, data)
  case 'trapezoid':
    return await getTrapezoid(option, data)
  case 'diamond':
    return await getDiamond(option, data)
  case 'pentagon':
    return await getPentagon(option, data)
  case 'donut':
    return await getDonut(option, data)
  case 'chevron':
    return await getChevron(option, data)
  case 'star5':
    return await getStar5(option, data)
  case 'round2DiagRect':
    return await getRound2DiagRect(option, data)
  case 'round2SameRect':
    return await getRound2SameRect(option, data)
  case 'round1Rect':
    return await getRound1Rect(option, data)
  case 'snip1Rect':
    return await getSnip1Rect(option, data)
  case 'snip2SameRect':
    return await getSnip2SameRect(option, data)
  case 'snip2DiagRect':
    return await getSnip2DiagRect(option, data)
  case 'snipRoundRect':
    return await getSnipRoundRect(option, data)
  case 'mathPlus':
    return await getMathPlus(option, data)
  case 'mathMinus':
    return await getMathMinus(option, data)
  case 'mathMultiply':
    return await getMathMultiply(option, data)
  case 'plus':
    return await getPlus(option, data)
  case 'corner':
    return await getCorner(option, data)
  case 'diagStripe':
    return await getDiagStripe(option, data)
  case 'wedgeRectCallout':
    return await getWedgeRectCallout(option, data)
  default:
    return null
  }
}