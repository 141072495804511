export const EMU_PER_PX = 9525
const PT_PX_RATIO = 1

export function emuToPixel(val: number) {
  return Math.floor(val / EMU_PER_PX * 100) / 100
}

export function ptToPixel(val: number) {
  return Math.floor(val / PT_PX_RATIO) / 100
}

export function emuToPercent(val?: number) {
  return (val || 0) / 100000
}

export function emuToAngle(val?: number) {
  return Math.floor((val || 0) / 60000)
}

export function emuToPound(val: number) {
  return Math.floor(val / 12700 * 100) / 100
}