import React, { useMemo, useCallback } from 'react';
import { Dropdown, Divider, ColorPicker, Tooltip, Button } from '@arco-design/web-react';
import cx from 'classnames';

import { FillData, ThemeValue } from '@/types/element';
import { getPptColor, checkSameColor } from '@/components/Editor/utils';

import './style.less';

interface SelectColorProps<T = FillData> {
	value?: T;
	onChange(v: T): void;
	theme: ThemeValue['color'];
	tip: string;
	icon: React.ReactNode;
}

const themeKeyList = [
	{
		value: 'bg1',
		label: '背景1'
	},
	{
		value: 'tx1',
		label: '文字1'
	},
	{
		value: 'bg2',
		label: '背景2'
	},
	{
		value: 'tx2',
		label: '文字2'
	},
	{
		value: 'accent1',
		label: '着色1'
	},
	{
		value: 'accent2',
		label: '着色2'
	},
	{
		value: 'accent3',
		label: '着色3'
	},
	{
		value: 'accent4',
		label: '着色4'
	},
	{
		value: 'accent5',
		label: '着色5'
	},
	{
		value: 'accent6',
		label: '着色6'
	}
];

const standardList = [
	{
		value: 'rgb(192, 0, 0)',
		label: '标准深红'
	},
	{
		value: 'rgb(255, 0, 0)',
		label: '标准红'
	},
	{
		value: 'rgb(255, 192, 0)',
		label: '标准橙'
	},
	{
		value: 'rgb(255, 255, 0)',
		label: '标准黄'
	},
	{
		value: 'rgb(146, 208, 80)',
		label: '标准浅绿'
	},
	{
		value: 'rgb(0, 176, 80)',
		label: '标准绿'
	},
	{
		value: 'rgb(0, 176, 240)',
		label: '标准浅蓝'
	},
	{
		value: 'rgb(0, 112, 192)',
		label: '标准蓝'
	},
	{
		value: 'rgb(0, 32, 96)',
		label: '标准深蓝'
	},
	{
		value: 'rgb(112, 48, 160)',
		label: '标准紫'
	}
];

export default function SelectColor({ value, theme, onChange, tip, icon }: SelectColorProps) {
	const [currentColor, currentKey] = useMemo(() => {
		if (!value) return [undefined, undefined];
		if (value.type == 1 && value.color) {
			const color = getPptColor(value.color, { theme });
			return [color, value.color.type == 2 ? value.color.value : color];
		}
		if (value.type == 2 && value.gradient) {
			return [`linear-gradient(to right, ${getPptColor(value.gradient.colorStops[0].color, { theme })}, ${getPptColor(value.gradient.colorStops[1].color, { theme })})`, undefined];
		}
		return [undefined, undefined];
	}, [value, theme]);

	const handleChange = useCallback(
		(type: 1 | 2, value: string) => {
			const val: FillData = {
				type: 1,
				color: {
					type,
					value,
					mods: {}
				}
			};
			onChange?.(val);
		},
		[onChange]
	);

	return (
		<>
			<Dropdown
				trigger={'click'}
				unmountOnExit
				droplist={
					<div className="ppt-editor-font-color-popover">
						<div className="ppt-editor-font-color-card">
							{themeKeyList.map((item) => {
								const val = theme[item.value].value;
								return (
									<a
										key={item.value}
										className={cx('ppt-editor-font-color-item', { active: currentColor != currentKey && item.value == currentKey })}
										style={{ background: val }}
										onClick={() => handleChange(2, item.value)}
									/>
								);
							})}
						</div>
						<div className="ppt-editor-font-color-card-title">标准色</div>
						<div className="ppt-editor-font-color-card">
							{standardList.map((item) => (
								<a
									key={item.value}
									className={cx('ppt-editor-font-color-item', { active: currentColor == currentKey && checkSameColor(item.value, currentColor) })}
									style={{ background: item.value }}
									onClick={() => handleChange(1, item.value)}
								/>
							))}
						</div>
						<Divider style={{ margin: '8px 0' }} />
						<ColorPicker
							triggerProps={{
								position: 'rt',
								trigger: 'hover',
								popupAlign: {
									right: 0
								}
							}}
							unmountOnExit
							format="rgb"
							value={currentColor}
							onChange={(val) => handleChange(1, val)}
							triggerElement={() => {
								return <a className="ppt-editor-font-color-popover-btn">更多颜色</a>;
							}}
						/>
					</div>
				}>
				<Tooltip content={tip}>
					<Button size="small" type="text" className="ppt-editor-font-color-btn">
						{icon}
						<span className="ppt-editor-font-color-line" style={{ background: currentColor }}></span>
					</Button>
				</Tooltip>
			</Dropdown>
		</>
	);
}
