import { Ellipse  } from 'leafer-ui'

import { Element } from '@/types/element'
import { ElementBaseOption } from '../../types'

export default async function getDonut(option: ElementBaseOption, data: Element) {
  const { width, height } = data
  const radius = typeof data.shape?.radius == 'number' ? data.shape.radius : 0.25

  let innerRx = width * (0.5 - radius)
  let innerRy = height * (0.5 - radius)

  if (width < height) {
    innerRy = Math.max(innerRy, height / 2 - (width / 2 - innerRx))
  } else {
    innerRx = Math.max(innerRx, width / 2 - (height / 2 - innerRy))
  }

  const circle = new Ellipse({
    ...option,
    windingRule: 'evenodd',
  })
  
  circle.pen.drawEllipse(width / 2, height / 2, width / 2, height / 2).drawEllipse(width / 2, height / 2, innerRx, innerRy)

  return circle
}