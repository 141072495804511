import { createContext } from 'react';
import { App, IUI } from 'leafer-ui';

import { ThemeValue } from '@/types/element';

export const SlideContext = createContext<{
	state: {
		theme: ThemeValue['color'];
		themeFont: ThemeValue['font'];
		app?: App | null;
		target?: IUI | null;
	};
}>({
	state: {
		theme: {},
		themeFont: {}
	}
});
