import { Polygon } from 'leafer-ui'

import { Element } from '@/types/element'
import { ElementBaseOption } from '../../types'

export default async function getDiamond(option: ElementBaseOption, data: Element) {
  const { width, height } = data

  return new Polygon({
    ...option,
    points: [
      0,
      height / 2,
      width / 2,
      0,
      width,
      height / 2,
      width / 2,
      height
    ]
  })
}