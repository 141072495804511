import axios from 'axios';
import { decode } from 'js-base64';
import { getUploadKey } from '@/api';

export enum typeEnum {
	pptx = 'pptx',
	image = 'image',
	preview = 'preview',
	typeface = 'typeface',
	layout = 'layout',
	master = 'master',
	media = 'lib_media'
}

export default async (file: File, type: typeEnum, isStream: boolean = false) => {
	let res: any = await getUploadKey({
		fileName: file.name,
		fileSize: file.size,
		type: type
	});

	const ossInfo = JSON.parse(decode(res.data.token));
	const { conditions } = JSON.parse(decode(ossInfo.policy));

	let isOssForbid = false;

	Array.isArray(conditions) &&
		conditions.forEach((it: any) => {
			if (typeof it === 'object' && it !== null && !Array.isArray(it)) {
				if (Object.keys(it).includes('x-oss-forbid-overwrite')) {
					isOssForbid = it['x-oss-forbid-overwrite'];
				}
			}
		});

	const formData = new FormData();
	formData.append('name', file.name);
	formData.append('policy', ossInfo.policy);
	formData.append('OSSAccessKeyId', ossInfo.ossAccessKeyId);
	formData.append('success_action_status', '200');
	formData.append('signature', ossInfo.signature);
	formData.append('key', ossInfo.dir);
	if (ossInfo.callback) {
		formData.append('callback', ossInfo.callback);
	}
	if (isOssForbid) {
		formData.append('x-oss-forbid-overwrite', 'true');
	}
	formData.append('file', file);

	let result: any = null;
	if (isStream) {
		formData.append('requestType', 'fileStream');
		result = await axios.post(ossInfo.host, formData, {
			headers: {
				'Content-Type': 'application/octet-stream'
			}
		});
	} else {
		result = await axios.post(ossInfo.host, formData);
	}

	if (result.status >= 200 && result.status < 300) {
		return {
			key: ossInfo.dir
		};
	} else {
		return Promise.reject(result.msg || '上传失败');
	}
};
