import { useEffect, useState } from 'react';

import { Button, Modal, Space, Trigger } from '@arco-design/web-react';
import { IconCaretDown } from '@arco-design/web-react/icon';

import { getFormatStructs, getFormatStructsDetail } from '@/api/slides';

import { getSessionStorage } from '@/utils';

import authStructs from '@/common/structsNew';

import './index.less';

interface iProps {
	slideId: string; // 当前的loayoutId
	relInfo: any; // 当前的绑定数据
	slideElement: any;
	onRelBindCb: (rel: any, num?: number | null) => void;
	onImgBindCb: (count: number | string) => void;
}

export default (props: iProps) => {
	const { slideId, relInfo, slideElement, onRelBindCb, onImgBindCb } = props;
	const cdnUrl = getSessionStorage('cdnUrl');

	const imgNum = [
		{
			label: '0张',
			value: 0
		},
		{
			label: '1张',
			value: 1
		},
		{
			label: '2张',
			value: 2
		},
		{
			label: '3张',
			value: 3
		},
		{
			label: '4张',
			value: 4
		},
		{
			label: '5张',
			value: 5
		},
		{
			label: '6张',
			value: 6
		},
		{
			label: '7张',
			value: 7
		},
		{
			label: '8张',
			value: 8
		},
		{
			label: '9张',
			value: 9
		},
		{
			label: '10张',
			value: 10
		},
		{
			label: '10张以上',
			value: 11
		}
	];

	const [popupVisible, setPopupVisible] = useState(false);
	// 版式类型列表
	const [structList, setStructList] = useState<any[]>([]);

	const [imgCount, setImgCount] = useState<number>(0);
	// 当前操作的关系
	const [currentRel, setCurrentRel] = useState<any>(null);
	// 当前页面的所有可绑定元素
	const [curElement, setCurElement] = useState<any[]>([]);

	// 编辑时 - 获取当前 layout 的绑定关系数据
	const getBindInfo = async (elements: any[]) => {
		const pds = relInfo.pd;

		if (pds) {
			console.log('pds', pds);
			const arr = pds.split('!');
			console.log('arr', arr);
			// 版式绑定关系类型ID
			const id = arr[0];
			const relObj: any = {};
			const elementRelObj: any = {};
			// 多个绑定关系
			if (arr[1].indexOf('@') > -1) {
				let b = arr[1].split('@');
				b.forEach((v: any) => {
					let c = v.split(':');
					relObj[c[0]] = c[1];
					elementRelObj[c[1]] = c[0];
				});
			} else {
				if (arr[1].indexOf(':') > -1) {
					let c = arr[1].split(':');
					relObj[c[0]] = c[1];
					elementRelObj[c[1]] = c[0];
				}
			}
			// 获取版式详情
			let result: any = await getFormatStructsDetail('1', id);
			if (result.code == 200) {
				result.data.models.forEach((item: any) => {
					if (item.type == 8) {
						item.children.forEach((val: any) => {
							if (relObj[val.id]) {
								val.bindId = relObj[val.id];
							} else {
								val.bindId = '';
							}
						});
					} else {
						if (relObj[item.id]) {
							item.bindId = relObj[item.id];
						} else {
							item.bindId = '';
						}
					}
				});
				elements.forEach((item) => {
					if (elementRelObj[item.id]) {
						item.bindId = elementRelObj[item.id];
					}
				});

				setCurrentRel(result.data);
			}
		}
		setCurElement(elements);
	};

	// 获取版式类型列表
	const getStyleList = async () => {
		let res = await getFormatStructs('1');
		const filterArr: string[] = [
			'金句页',
			'对比内容页',
			'文字云页',
			'地图页',
			'表格页',
			'数据展示页',
			'多图展示页',
			'逻辑图示页',
			'流程图页',
			'架构图页',
			'提问回答页',
			'截图展示页',
			'数据图表页',
			'多图标页',
			'循环',
			'层级/架构',
			'矩阵',
			'棱锥',
			'模块',
			'并列/列表'
		];
		res.data = res.data.filter((item: any) => !filterArr.includes(item.label));
		setStructList(res.data);
	};

	// 获取版式类型详情
	const getDetail = async (id: any) => {
		try {
			const res = await getFormatStructsDetail('1', id);
			res.data.models.forEach((item: any) => {
				if (item.type == 8) {
					item.children.forEach((val: any) => {
						val.bindId = '';
					});
				} else {
					item.bindId = '';
				}
			});
			const relInfo = JSON.parse(JSON.stringify(res.data));

			// 自动绑定  curElement
			const relSymbol = relInfo.symbol;
			const symbolStruct = authStructs[relSymbol];

			// 获取Symbol的集合
			const objStruct = symbolStruct ? Object.keys(symbolStruct) : [];
			const symbolMap: any = {};
			objStruct.forEach((key) => {
				symbolMap[key] = {
					regex: symbolStruct[key],
					isMatch: false
				};
			});
			// 遍历元素
			curElement.forEach((item) => {
				item.bindId = '';
				if (item.type == 'text') {
					// 正则匹配
					for (let key in symbolMap) {
						let regexItem = symbolMap[key];
						if (!regexItem.isMatch) {
							const match = (item.text || '').trim().toLowerCase().match(regexItem.regex);
							if (match) {
								let seq: any = -1;
								let symbolArr = key.split('#');
								if (key.indexOf('@') > -1) {
									symbolArr = key.split('@');
								} else if (key.indexOf('#') > -1) {
									symbolArr = key.split('#');
									seq = match[1];
								} else {
									symbolArr = [key];
								}

								relInfo.models.forEach((val: any) => {
									const symbol1 = seq > -1 ? `${symbolArr[0]}#${parseInt(seq)}` : symbolArr[0];
									if (val.symbol == symbol1) {
										if (val?.children) {
											val?.children.forEach((child: any) => {
												if (child.symbol == symbolArr[1]) {
													item.bindId = child.id;
													child.bindId = item.id;
												}
											});
										} else {
											// 元素绑定
											item.bindId = val.id;
											// 绑定关系绑定
											val.bindId = item.id;
										}
									}
								});

								if (seq > -1) {
									symbolMap[key].isMatch = false;
								} else {
									symbolMap[key].isMatch = true;
								}
							}
						}
					}
				}
			});
			setCurrentRel(relInfo);
			setCurElement([...curElement]);
			setPopupVisible(false);
		} catch (error) {
			console.log('error', error);
		}
	};

	// 选择版式
	const onChangeFormat = (item: any) => {
		if (currentRel?.id) {
			Modal.confirm({
				title: '提示',
				content: '是否切换版式结构？切换后绑定关系将会清除!',
				okText: '确认',
				cancelText: '取消',
				onOk() {
					getDetail(item.value);
				},
				onCancel() {}
			});
		} else {
			getDetail(item.value);
		}
	};

	// 绑定
	const handleSelect = (item: any, idx: number) => {
		// 元素上绑定
		curElement[idx].bindId = item.id;
		// 绑定关系上绑定
		const newRel = Object.assign({}, currentRel);
		newRel?.models.forEach((val: any) => {
			if (item.id == val.id) {
				val.bindId = curElement[idx].id;
			} else {
				if (val?.children) {
					val?.children.forEach((child: any) => {
						if (item.id == child.id) {
							child.bindId = curElement[idx].id;
						}
					});
				}
			}
		});
		setCurElement([...curElement]);
		setCurrentRel(newRel);
	};
	// 取消绑定
	const handleUnSelect = (item: any) => {
		// 元素上绑定
		curElement.forEach((val) => {
			if (val.bindId == item.id) {
				val.bindId = '';
			}
		});
		// 绑定关系上绑定
		const newRel = Object.assign({}, currentRel);
		newRel?.models.forEach((val: any) => {
			if (item.id == val.id) {
				val.bindId = '';
			} else {
				if (val?.children) {
					val?.children.forEach((child: any) => {
						if (item.id == child.id) {
							child.bindId = '';
						}
					});
				}
			}
		});
		setCurElement([...curElement]);
		setCurrentRel(newRel);
	};

	// 清除当前绑定关系
	const clearRelation = () => {
		// currentRel.models.forEach((item: any) => {
		// 	if (item.type == 8) {
		// 		item.children.forEach((val: any) => {
		// 			val.bindId = '';
		// 		});
		// 	} else {
		// 		item.bindId = '';
		// 	}
		// });

		curElement.forEach((item) => {
			if (item.type == 'text') {
				item.bindId = '';
			}
		});

		setCurrentRel(null);
		setCurElement([...curElement]);
	};
	// 获取关联关系，返回给父组件
	const getBindRelation = () => {
		if (currentRel) {
			let relKeys = Object.keys(currentRel);
			if (relKeys.length > 0) {
				let rel: any[] = [];
				curElement.forEach((item) => {
					if (item.bindId) {
						rel.push(`${item.bindId}:${item.id}`);
					}
				});

				const relArr = rel.map((it) => it.split(':')[0]);
				if (new Set(relArr).size !== relArr.length) {
					Modal.error({
						title: '提示',
						content: '当前绑定关系中有重复项，请手动重新绑定或重新上传模板！',
						onOk: () => {
							clearRelation();
						}
					});
				} else {
					let relStr = `${currentRel.id}!${rel.join('@')}`;
					onRelBindCb(relStr);
				}
			}
		}
	};

	/**
	 * ppt元素文本提取
	 */
	// 提取文本
	const extractText = (list: any) => {
		let text = '';
		list.forEach((item: any) => {
			if (item.text && Array.isArray(item.text) && item.text.length > 0) {
				text += extractText(item.text);
			} else {
				text += item.text.replace(/\s*/g, '');
			}
		});
		return text;
	};
	// 解析出所有的元素
	const getElementList = async (list: any) => {
		const eleList: any[] = [];
		list.forEach(async (item: any) => {
			console.log('item', item);
			if (item.elements && Array.isArray(item.elements) && item.elements.length > 0) {
				const arr = await getElementList(item.elements);
				arr.forEach((item) => eleList.push(item));
			} else {
				// 文本
				if (item.type == 'text' && item.text.length > 0) {
					let info: any = {
						id: item.id, // 元素 ID
						type: item.type, // 元素类型
						name: item.name, // 元素名称
						text: extractText(item.text), // 元素文本
						bindId: '' // 绑定ID
					};

					eleList.push(info);
				}
				// 填充图片
				if (item.type == 'text' && item.text.length == 0) {
					if (item?.fill && item?.fill?.type == 3 && item?.fill?.src) {
						let info: any = {
							id: item.id,
							type: 'image',
							name: item.name,
							text: item?.fill?.src,
							bindId: ''
						};
						eleList.push(info);
					}
				}
				// 图片
				if (item.type == 'image') {
					let info: any = {
						id: item.id,
						type: item.type,
						name: item.name,
						text: item.src,
						bindId: ''
					};
					eleList.push(info);
				}
			}
		});
		return eleList;
	};

	/**
	 * 渲染 版式类型
	 */
	const renderStructList = () => {
		return (
			<div className="select-struct-list flex flex-wrap">
				{structList.map((item) => (
					<Button key={`select-struct-list-${item.value}`} style={{ margin: '0 5px 5px 0' }} size="mini" onClick={() => onChangeFormat(item)}>
						{item.label}
					</Button>
				))}
			</div>
		);
	};

	// 渲染绑定关系结构
	const RenderStructRel = (props: any) => {
		const { idx } = props;
		return (
			<div className="trigger-struct">
				{currentRel?.models &&
					currentRel?.models.map((item: any, index: number) => {
						return (
							<div className="structs-list" key={`models_${index}_${item.id}`}>
								{/* 1:图片 2:图标 3:logo 4:纯文字 5:文本框 6:文本域 7:日期 8:复合类型 9:背景图片*/}
								{item.type == 8 ? (
									<div className="structs-child">
										<div className="structs-item-title">{item.title}</div>
										{item?.children.map((val: any, ind: number) => {
											return (
												<div className="structs-item flex justify-between" key={`children_${ind}_${val.id}`}>
													{val.title || val.ph}
													{val.bindId ? (
														<Button size="small" onClick={() => handleUnSelect(val)}>
															取消选中
														</Button>
													) : (
														<Button type="primary" size="small" onClick={() => handleSelect(val, idx)}>
															选中
														</Button>
													)}
												</div>
											);
										})}
									</div>
								) : (
									<div className="structs-item flex justify-between">
										{item.title}
										{item.bindId ? (
											<Button size="small" onClick={() => handleUnSelect(item)}>
												取消选中
											</Button>
										) : (
											<Button type="primary" size="small" onClick={() => handleSelect(item, idx)}>
												选中
											</Button>
										)}
									</div>
								)}
							</div>
						);
					})}
			</div>
		);
	};

	// 渲染元素列表
	const renderElement = (list: any[]) => {
		return list.map((item, index) => {
			return (
				<div className="element-item flex justify-between items-center" key={`element-${item.id}`}>
					{item.type == 'text' && <div className="item-name">{item.text}</div>}
					{item.type == 'image' && (
						<div className="item-image">
							<img src={cdnUrl + item.text} />
						</div>
					)}
					<Trigger popup={() => <RenderStructRel idx={index} />} trigger="focus" showArrow position="lt" className="trigger-popup">
						<Button size="small" type={item.bindId ? 'default' : 'primary'}>
							{item.bindId ? '已' : ''}绑定
						</Button>
					</Trigger>
				</div>
			);
		});
	};

	const init = async () => {
		let elements = await getElementList(slideElement);
		if (relInfo?.pd) {
			getBindInfo(elements);
			setImgCount(relInfo?.imgCount || 0);
		} else {
			setCurElement(elements);
			setCurrentRel({});
			setImgCount(0);
		}
	};

	// 监听绑定关系回调
	useEffect(() => {
		if (curElement) {
			getBindRelation();
		}
	}, [curElement]);

	useEffect(() => {
		if (slideId && slideElement) {
			console.log('slideElement', slideElement);
			init();
		}
	}, [slideId, slideElement, relInfo]);

	useEffect(() => {
		getStyleList();
	}, []);

	return (
		<>
			<div className="panel-head">绑定关系</div>
			<div className="panel-content">
				<div className="structs-panel">
					{/* 目录版式，填写绑定的目录个数 */}
					<div style={{ marginBottom: '10px' }}>
						<Space wrap>
							<span>图片数量：</span>
							{imgNum.map((it: any) => (
								<Button
									key={`imgBtn-${it.value}`}
									size="mini"
									type={imgCount == it.value ? 'primary' : 'secondary'}
									onClick={() => {
										setImgCount(it.value);
										onImgBindCb(it.value);
									}}>
									{it.label}
								</Button>
							))}
						</Space>
					</div>

					<div className="structs-title flex items-center justify-between">
						<span>版式选择：</span>
						<div className="flex items-center">
							<Trigger
								showArrow
								arrowProps={{
									style: {
										backgroundColor: '#fff',
										boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)'
									}
								}}
								trigger="click"
								position="br"
								popupVisible={popupVisible}
								onClickOutside={() => setPopupVisible(false)}
								popup={() => (
									<div
										style={{
											padding: '10px',
											width: '300px',
											textAlign: 'center',
											backgroundColor: 'var(--color-bg-popup)',
											boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)'
										}}>
										{renderStructList()}
									</div>
								)}>
								<Button type="secondary" onClick={() => setPopupVisible(true)}>
									{currentRel?.name || '请选择'}
									<IconCaretDown />
								</Button>
							</Trigger>
						</div>
					</div>
					<div className="element-list">{renderElement(curElement)}</div>
				</div>
			</div>
		</>
	);
};
