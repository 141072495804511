import { useState } from 'react'
import { Modal, Input } from '@arco-design/web-react'

import { CustomModalProps } from '../useModal'
import { TextData } from '@/types/element'

import './style.less'

interface ModalEditTextProps extends CustomModalProps {
  data: TextData
  onSuccess?(d: TextData): void
  onClose?(): void
}

export default function ModalEditText({ customClose, data, onSuccess, onClose, ...props }: ModalEditTextProps) {
  const [value, setValue] = useState(data.text.map(item => item.text.map(item => item.text).join('')).join('\n'))
  const handleSubmit = () => {
    const lines = value.split('\n')

    data.text = data.text.slice(0, lines.length)

    lines.forEach((str, index) => {
      if (data.text[index]) {
        data.text[index].text = [{ ...data.text[index].text[0], text: str }]
      } else {
        data.text[index] = {
          ...data.text[data.text.length - 1],
          text: [{ ...data.text[data.text.length - 1].text[0], text: str }]
        }
      }
    })
    onSuccess?.(data)
    customClose()
  }
  return <Modal
    {...props}
    onCancel={() => {
      customClose()
      onClose?.()
    }}
    onOk={handleSubmit}
    simple
  >
    <div className='ppt-editor-text-edit-modal-content'>
      <Input.TextArea
        value={value}
        autoSize={{ minRows: 6 }}
        onChange={setValue}
      />
    </div>
  </Modal>
}