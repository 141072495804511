import { Path } from 'leafer-ui'

import { Element } from '@/types/element'
import { ElementBaseOption } from '../../types'

export default async function getMathMinus(option: ElementBaseOption, data: Element) {
  const { width, height } = data
  const paddingRadio = 0.15
  const paddingX = width * paddingRadio
  const radius = typeof data.shape?.point1 == 'number' ? data.shape.point1 : 0.24
  const plusSize = height * radius || 1
  const path = `
    M ${paddingX} ${height / 2 - plusSize / 2} 
    L ${width - paddingX} ${height / 2 - plusSize / 2}
    L ${width - paddingX} ${height / 2 + plusSize / 2}
    L ${paddingX} ${height / 2 + plusSize / 2}
    Z`

  return new Path({
    ...option,
    path,
  })
}