import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Layout } from '@arco-design/web-react';

import { isLogin, delStorage, delCookies } from '@/utils';

import LayoutMenu from './components/layout-menu';
import LayoutHeader from './components/layout-header';

import Logo from '@/assets/logo.png';

import './index.less';

export default () => {
	const location = useLocation();
	const navigate = useNavigate();
	const Sider = Layout.Sider;

	const [collapsed, setCollapsed] = useState(false);
	const [activeKey, setActiveKey] = useState('');

	const handleCollapsed = () => {
		setCollapsed(!collapsed);
	};

	useEffect(() => {
		let path = location.pathname;
		if (path.split('/').length > 3) {
			setActiveKey(path.split('/').splice(0, 3).join('/'));
		} else {
			setActiveKey(path);
		}
		if (path.indexOf('/login') == -1 && !isLogin()) {
			delStorage('refresh_token');
			delCookies('access_token');
			navigate('/login');
		}
	}, [location]);

	return (
		<div className="layout">
			<Sider className="layout-sider" theme="dark" collapsed={collapsed} trigger={null} onCollapse={handleCollapsed} collapsible breakpoint="xl">
				<div className="logo flex items-center">
					<img src={Logo} alt="logo" />
					{!collapsed && <span>少刻科技</span>}
				</div>
				<div className="menu-wrapper">
					<LayoutMenu activeKey={activeKey} />
				</div>
			</Sider>
			<div className="layout-content">
				<LayoutHeader collapse={collapsed} onCollapse={handleCollapsed} />
				<div className="pages-content">
					<Outlet />
				</div>
			</div>
		</div>
	);
};
