import { App, IUI, IEventListener } from 'leafer-ui';

import { getAppJson } from '../utils';
import { SlideData } from '@/types/element';

export interface EditorListenerOption {
	app: App;
	canvas: HTMLCanvasElement;
	onSelect?(e: IUI | null): void;
	onChangeData?(data: SlideData, app: App): void;
}

function getValidTarget(e: any) {
	if (e.tag == 'Frame') {
		return null;
	}
	if (e.data.id) {
		return e;
	}
	if (e.parent) {
		return getValidTarget(e.parent);
	}
	return null;
}

export default class EditorListener {
	app: EditorListenerOption['app'];

	canvas: EditorListenerOption['canvas'];

	onSelect: EditorListenerOption['onSelect'];

	onChangeData: EditorListenerOption['onChangeData'];

	constructor({ app, canvas, onSelect, onChangeData }: EditorListenerOption) {
		this.app = app;
		this.canvas = canvas;
		this.onSelect = onSelect;
		this.onChangeData = async () => {
			const data = await getAppJson(app);
			app.emit('saveSnapshot', data);
			onChangeData?.(data, app);
		};
	}

	private keydownListener = (e: KeyboardEvent) => {
		const { app } = this;

		if (e.key == 'Escape') {
			app.editor.cancel();
		}
		if ((e.metaKey || e.ctrlKey) && e.key == 'z') {
			e.preventDefault();
			e.stopPropagation();
			app.emit('undo');
		}
		if ((e.metaKey || e.ctrlKey) && e.key == 'y') {
			e.preventDefault();
			e.stopPropagation();
			app.emit('redo');
		}
		if (e.key === 'Backspace' && !['INPUT', 'TEXTAREA'].includes((e.target as any)?.tagName)) {
			const target = app.editor.target;

			if (app.editor.innerEditor?.inBody) return;
			if (target) {
				if (Array.isArray(target)) {
					target.forEach((item) => item.remove());
				} else {
					app.emit('changeData');
					target.remove();
				}
			}
			app.editor.cancel();
		}
	};

	// private doubleTap: IEventListener = (e) => {
	// 	const { app } = this;

	// 	const target = getValidTarget(e.target);
	// 	if (target) {
	// 		target.editable = true;
	// 		app.editor.openInnerEditor(target);
	// 	}
	// };

	private dragListener = async () => {
		const { app } = this;

		app.emit('changeData');
	};

	private selectListener: IEventListener = (e) => {
		const target = e.value as IUI;
		const oldTarget = e.oldValue;
		if (target && !target.data?.id) {
			const validTarget = getValidTarget(target);
			if (validTarget) {
				this.app.editor.select(validTarget);
			} else {
				this.app.editor.cancel();
			}
			return;
		}
		console.log('select::', target);

		if (oldTarget?.data?.groupIds?.length) {
			oldTarget.editable = false;
		}
		this.onSelect?.(target);
	};

	private innerEditorBeforeOpen = () => {
		const { app } = this;
		if (app.editor.innerEditor) app.editor.innerEditor.config = { selectAll: false };
	};

	tap: IEventListener = (event) => {
		const { app } = this;

		const valid = getValidTarget(event.target);
		if (valid) {
			// const parent = getValidTarget(valid.parent)
			// console.log(parent)
			app.editor.select(valid);
		} else {
			app.editor.cancel();
		}
	};

	public register() {
		const { app } = this;
		if (!app?.editor) return;

		window.addEventListener('keydown', this.keydownListener);

		app.on('changeData', this.onChangeData);

		// app.tree.on('double_tap', this.doubleTap)
		app.tree.on('tap', this.tap);

		app.editor.on('innerEditor.before_open', this.innerEditorBeforeOpen);
		app.editor.on('drag.end', this.dragListener);
		app.editor.on('editor.select', this.selectListener);
	}

	public off() {
		const { app } = this;
		if (!app?.editor) return;

		window.removeEventListener('keydown', this.keydownListener);

		app.off('changeData', this.onChangeData);

		// app.tree.off('double_tap', this.doubleTap)
		app.tree.off('tap', this.tap);

		app.editor.off('innerEditor.before_open', this.innerEditorBeforeOpen);
		app.editor.off('drag.end', this.dragListener);
		app.editor.off('editor.select', this.selectListener);
	}
}
