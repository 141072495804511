import { Path } from 'leafer-ui';

import { Element } from '@/types/element';
import { ElementBaseOption } from '../../types';

export default async function getWedgeRectCallout(option: ElementBaseOption, data: Element) {
	const { width, height } = data;
	// const point1 = typeof data.shape?.point1 == 'number' ? data.shape.point1 : 0.25
	// const point2 = typeof data.shape?.point1 == 'number' ? data.shape.point2 : 0.25

	const path = `
    M 0 0 
    L ${width} 0 
    L ${width} ${height} 
    L 0 ${height}
    Z`;

	return new Path({
		...option,
		path
	});
}
