import Dexie, { type EntityTable } from 'dexie'

type SnapshotType = 'slide' | 'theme'

interface Snapshot {
  time_at: number
  data: string
  type: SnapshotType
}

const db = new Dexie('PPTDatabase')  as Dexie & {
  snapshot: EntityTable<
    Snapshot
  >
}

db.version(1).stores({
  snapshot: '++time_at,type,data',
})

export type { Snapshot }

export default db