import { Rect } from 'leafer-ui'

import { Element } from '@/types/element'
import { ElementBaseOption } from '../../types'

export default async function getFlowChartTerminator(option: ElementBaseOption, data: Element) {
  const radius = data.height * 0.5

  return new Rect({
    ...option,
    cornerRadius: radius,
  })
}