import { ReactNode, useEffect } from 'react';

import { Button, Form, Input, Select, Space, InputNumber } from '@arco-design/web-react';
import { IconLeft } from '@arco-design/web-react/icon';

interface IProps {
	info: any;
	editorType: string;
	saveLoad: boolean;
	typeList: any[];
	colosList: any[];
	styleList: any[];
	children: ReactNode;
	onCb: (data: any) => void;
}
const Header = (props: IProps) => {
	const { info, editorType, saveLoad, typeList, colosList, styleList, children, onCb } = props;

	const [form] = Form.useForm();
	const FormItem = Form.Item;
	const handleSave = async (values: any) => {
		onCb({
			type: 'save',
			data: values
		});
	};

	useEffect(() => {
		if (info) {
			if (editorType == 'slide') {
				form.setFieldsValue({
					name: info.name
				});
			}
			if (editorType.indexOf('layout') > -1) {
				form.setFieldsValue({
					name: info.name,
					style: info.style
				});
			}
			if (editorType.indexOf('template') > -1) {
				form.setFieldsValue({
					name: info.name,
					style: info.style,
					type: info.type,
					color: info.color,
					price: info.price / 100
				});
			}
		}
	}, [info, editorType]);

	return (
		<div className="editor-form flex">
			<Button icon={<IconLeft />} onClick={() => onCb({ type: 'back' })} />
			<div className="flex-1" style={{ marginLeft: '15px' }}>
				<Form form={form} autoComplete="off" layout="inline" onSubmit={handleSave}>
					<FormItem field="name" rules={[{ required: true, message: '请输入名称' }]}>
						<Input style={{ width: '240px' }} placeholder="请输入名称" disabled={!['layoutAdd', 'templateAdd'].includes(editorType)} />
					</FormItem>
					{editorType != 'slide' && (
						<FormItem field="style" rules={[{ required: true, message: '请选择风格' }]}>
							<Select style={{ width: '160px' }} options={styleList} placeholder="请选择风格" allowClear disabled={!['layoutAdd', 'templateAdd'].includes(editorType)} />
						</FormItem>
					)}
					{editorType.indexOf('template') > -1 && (
						<>
							<FormItem field="type" rules={[{ required: true, message: '请选择模板类型' }]}>
								<Select style={{ width: '160px' }} options={typeList} placeholder="请选择模板类型" allowClear disabled={!['layoutAdd', 'templateAdd'].includes(editorType)} />
							</FormItem>
							<FormItem field="color" rules={[{ required: true, message: '请选择色系' }]}>
								<Select style={{ width: '160px' }} options={colosList} placeholder="请选择色系" allowClear disabled={!['layoutAdd', 'templateAdd'].includes(editorType)} />
							</FormItem>
							<FormItem field="price" rules={[{ required: true }]}>
								<InputNumber prefix="¥" style={{ width: 150 }} placeholder="请输入定价" disabled={!['layoutAdd', 'templateAdd'].includes(editorType)} />
							</FormItem>
						</>
					)}
					<FormItem>
						<Space>
							<Button type="primary" htmlType="submit" loading={saveLoad}>
								保存
							</Button>
							{children}
						</Space>
					</FormItem>
				</Form>
			</div>
		</div>
	);
};

export default Header;
