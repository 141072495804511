import { useCallback, useEffect } from 'react'
import { UI } from 'leafer-ui'

import { Element } from '@/types/element'
import ElementText from './Text'
import ElementGroup from './Group'
import ElementImage from './Image'
import ElementLine from './Line'

export interface SlideElementProps {
  data: Element
  onLoad(e: UI | null, d: Element, i: number, isLast: boolean): void
  index: number
  editable?: boolean
  isLast: boolean
}

function EmptyElement({ onLoad, isLast, index, data }: { data: SlideElementProps['data'], onLoad: SlideElementProps['onLoad'], isLast: SlideElementProps['isLast'], index: SlideElementProps['index'] }) {
  useEffect(() => {
    onLoad(null, data, index, isLast)
  }, [onLoad, isLast, index, data])

  return null
}

export default function SlideElement({ data, onLoad, index, editable = false, isLast }: SlideElementProps) {
  const onElementLoad = useCallback((element: UI) => {
    onLoad(element, data, index, isLast)
  }, [data, onLoad, index, isLast])

  if (data.hidden || (data.type == 'text' && data.paragraphType) || (data.width == 0 && data.height == 0 && !data.fill && !data.border?.fill)) return <EmptyElement onLoad={onElementLoad} isLast={isLast} index={index} data={data} />

  if (data.type == 'text') {
    return <ElementText data={data} onLoad={onElementLoad} editable={editable} />
  }
  if (data.type == 'group') {
    return <ElementGroup data={data} onLoad={onElementLoad} editable={editable} />
  }
  if (data.type == 'image') {
    return <ElementImage data={data} onLoad={onElementLoad} editable={editable} />
  }
  if (data.type == 'line') {
    return <ElementLine data={data} onLoad={onElementLoad} editable={editable} />
  }

  return <EmptyElement onLoad={onElementLoad} isLast={isLast} index={index} data={data} />
}