import { Ellipse } from 'leafer-ui'

import { Element } from '@/types/element'
import { ElementBaseOption } from '../../types'

export default async function getArc(option: ElementBaseOption, data: Element) {
  return new Ellipse({
    ...option,
    innerRadius: 1,
    startAngle: data.shape?.startAngle || -90,
    endAngle: data.shape?.endAngle || 0,

  })
}