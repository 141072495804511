/**
 * 官方版式库
 * */
import request from '@/common/fetch';
// 列表
export const getLayoutList = (params: any) =>
	request({
		url: '/res/v1/library/layouts/list',
		method: 'get',
		params
	});
// 详情
export const getLayoutDetail = (id: any) =>
	request({
		url: `/res/v1/library/layouts/${id}`,
		method: 'get'
	});
// 版式库样式集
export const getLayoutDetailList = (libId: any, params: any) =>
	request({
		url: `/res/v1/library/layouts/${libId}/set`,
		method: 'get',
		params
	});
// 版式库创建
export const postLayout = (data: any) =>
	request({
		url: '/res/v1/library/layouts/',
		method: 'post',
		data
	});
// 版式库修改
export const putLayout = (id: any, data: any) =>
	request({
		url: `/res/v1/library/layouts/${id}`,
		method: 'put',
		data
	});
// 版式库版式追加
export const postLayoutAppend = (id: any, data: any) =>
	request({
		url: `/res/v1/library/layouts/${id}/append`,
		method: 'post',
		data
	});
// 版式库版式移除
export const postLayoutRemove = (id: any, data: any) =>
	request({
		url: `/res/v1/library/layouts/${id}/remove`,
		method: 'post',
		data: {
			layoutIds: data
		}
	});
// 一键同步至派生模版前置
export const getLayoutSetting = (id: any) =>
	request({
		url: `/res/v1/library/layouts/${id}/extension/pre`,
		method: 'get'
	});
// 一键同步至派生模板
export const getLayoutExtension = (id: any, data: any) =>
	request({
		url: `/res/v1/library/layouts/${id}/extension`,
		method: 'post',
		data
	});
// 同步至产线前置
export const postlayoutSyncPre = (id: any) =>
	request({
		url: `/res/v1/library/layouts/sync/pre/${id}`,
		method: 'post'
	});
// 同步至产线
export const postLayoutCloneToProd = (id: any, data: any) =>
	request({
		timeout: 1200000,
		url: `/res/v1/library/layouts/sync/${id}`,
		method: 'post',
		data
	});

/**
 *
 */
// 获取版式的绑定关系数据
export const getLayoutRelData = (id: any) =>
	request({
		url: `/res/v1/template/layout/struct/data/${id}`,
		method: 'get'
	});

/**
 * 模版
 */
// 列表
export const getTemplateList = (param: any) =>
	request({
		url: '/res/v1/template/list',
		method: 'get',
		params: param
	});
// 构建模版
export const postTemplate = (id: any, data: any) =>
	request({
		url: `/res/v1/library/layouts/${id}/build/template`,
		method: 'post',
		data
	});
