import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Group, UI } from 'leafer-ui';

import { GroupData } from '@/types/element';
import { SlideContext } from '../context/slide';
import { MainContext } from '../context/main';
import { getBaseOptions } from '../utils';
import SlideElementList, { SlideElementListProps } from '../List';

interface ElementGroupProps {
	data: GroupData;
	onLoad(e?: UI): void;
	editable?: boolean;
	groupData?: GroupData[];
}

export default function ElementGroup({ data, onLoad, editable }: ElementGroupProps) {
	const { theme } = useContext(SlideContext).state;
	const { imageHandle } = useContext(MainContext).state;
	const [element, setElement] = useState<Group>();
	const [finish, setFinish] = useState(false);
	const childElement = useMemo(() => {
		return data.elements.map((item) => {
			const d = structuredClone({
				...item,
				left: item.left - data.offLeft,
				top: item.top - data.offTop,
				originLeft: item.left,
				originTop: item.top
			});
			if (d.type == 'text') {
				d.textScale = (d.textScale || 1) * data.scaleX;
				// d.text.forEach(item => {
				//   item.text.forEach(it => {
				//     it.fontSize /= data.scaleX
				//   })
				// })
			}

			if (d.border?.width) {
				d.border.width /= data.scaleX;
			}
			if (d.fill?.type == 5 && data.fill) {
				d.fill = data.fill;
			}
			return d;
		});
	}, [data]);

	const handleGetData = useCallback(async () => {
		if (!imageHandle) return;
		const option = await getBaseOptions(data, { theme, imageHandle });
		const group = new Group({
			...option,
			scaleX: data.scaleX,
			scaleY: data.scaleY,
			editable,
			data
		});

		if (data.rot) {
			group.rotateOf({ x: data.width / data.scaleX / 2, y: data.height / data.scaleY / 2 }, data.rot);
		}
		setElement(group);
	}, [data, theme, editable, imageHandle]);

	const onAdd: SlideElementListProps['onAdd'] = useCallback(
		(ele, _data, isLast) => {
			if (!element) return;
			ele && element.add(ele);
			if (isLast) setFinish(true);
		},
		[element]
	);

	useEffect(() => {
		handleGetData();
	}, [handleGetData]);

	useEffect(() => {
		element && finish && onLoad(element);
		return () => {
			element?.remove();
		};
	}, [element, onLoad, finish]);

	return element ? <SlideElementList list={childElement} onAdd={onAdd} editable={false} /> : null;
}
