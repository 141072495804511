/**
 * 版式风格
 * */
import request from '@/common/fetch';

// 创建版式风格
export const postSlideStyle = (data: any) =>
	request({
		url: '/res/v1/template/style',
		data: data,
		method: 'post'
	});
// 版式风格分页列表
export const getSlideStyleList = (param: any) =>
	request({
		url: '/res/v1/template/style/list',
		params: param,
		method: 'get'
	});
// 版式风格列表
export const getSlideStyleOption = () =>
	request({
		url: '/res/v1/template/style/options',
		method: 'get'
	});
// 删除版式风格
export const delSlideStyle = (id: string | number) =>
	request({
		url: `/res/v1/template/style/${id}`,
		method: 'DELETE'
	});
// 编辑版式风格
export const putSlideStyle = (id: string, data: any) =>
	request({
		url: `/res/v1/template/style/${id}`,
		data: data,
		method: 'put'
	});
