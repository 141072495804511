import { useContext, useMemo } from 'react'

import { MainContext } from '../context/main'
import { checkReplaceImage, replaceFillImage } from '../utils'

export interface ReplaceImageContentProps {
  disabled: boolean
  onReplace(url: string): Promise<void>
}

interface ReplaceImageButtonProps {
  getContent: (props: ReplaceImageContentProps) => React.ReactElement
}

export default function ReplaceImageButton({ getContent }: ReplaceImageButtonProps) {
  const { target, imageHandle } = useContext(MainContext).state
  const canReplace = useMemo(() => checkReplaceImage(target), [target])

  return getContent({
    disabled: !canReplace,
    onReplace: async (url: string) => {
      if (!target) throw new Error('未找到选中对象')
      if (!imageHandle) throw new Error('图片处理器初始化失败')
      await replaceFillImage(url, target, imageHandle)
    }
  })
}