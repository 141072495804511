import { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Form, Input, Button, ColorPicker, Grid, Message } from '@arco-design/web-react';

import { postThemeColor, putThemeColor } from '@/api/theme';

import { parseThemeXML } from '../utils';

export default () => {
	const navigator = useNavigate();

	const { id }: any = useParams();
	const { state } = useLocation();

	const [form] = Form.useForm();
	const Row = Grid.Row;
	const Col = Grid.Col;

	const [loading, setLoading] = useState<boolean>(false);

	const handleLocalTheme = async (event: any) => {
		const file = event.target.files[0];
		const reader = new FileReader();

		reader.onload = async (e: any) => {
			const data = e.target.result;
			const { colors } = await parseThemeXML(data);
			form.setFieldsValue(colors);
		};

		reader.readAsArrayBuffer(file);
	};

	// 提交
	const handleSave = async (values: any) => {
		setLoading(true);
		try {
			if (id) {
				await putThemeColor(id, values);
				Message.success('编辑成功');
			} else {
				await postThemeColor(values);
				Message.success('添加成功');
			}
			setLoading(false);
			navigator(-1);
		} catch (error) {
			setLoading(false);
			Message.error('提交失败');
		}
	};

	// 获取详情
	useEffect(() => {
		if (id && state) {
			form.setFieldsValue(state);
		}
	}, [id, state]);

	return (
		<div className="page-warp">
			<div className="page-warp-title">{id ? '编辑' : '新增'}主题色</div>
			<Form form={form} style={{ maxWidth: 650 }} onSubmit={handleSave}>
				<Row gutter={8}>
					<Col span={20}>
						<Form.Item label="主题色名称" field="name" rules={[{ required: true, message: '请输入主题色名称' }]}>
							<Input placeholder="请输入主题色名称" />
						</Form.Item>
					</Col>
					<Col span={4}>
						<Button type="primary" style={{ position: 'relative' }}>
							选择主题文件
							<input
								style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, opacity: 0 }}
								type="file"
								accept=".thmx"
								onChange={handleLocalTheme}
							/>
						</Button>
					</Col>
				</Row>

				<Form.Item label="文字/背景">
					<Row gutter={8}>
						<Col span={12}>
							<Form.Item label="深色1" field="dk1" rules={[{ required: true, message: '请选择颜色' }]}>
								<ColorPicker showText disabledAlpha format="hex" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="浅色1" field="lt1" rules={[{ required: true, message: '请选择颜色' }]}>
								<ColorPicker showText disabledAlpha format="hex" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="深色2" field="dk2" rules={[{ required: true, message: '请选择颜色' }]}>
								<ColorPicker showText disabledAlpha format="hex" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="浅色2" field="lt2" rules={[{ required: true, message: '请选择颜色' }]}>
								<ColorPicker showText disabledAlpha format="hex" />
							</Form.Item>
						</Col>
					</Row>
				</Form.Item>
				<Form.Item label="主题色/着色">
					<Row gutter={8}>
						<Col span={12}>
							<Form.Item label="着色1" field="accent1" rules={[{ required: true, message: '请选择颜色' }]}>
								<ColorPicker showText disabledAlpha format="hex" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="着色2" field="accent2" rules={[{ required: true, message: '请选择颜色' }]}>
								<ColorPicker showText disabledAlpha format="hex" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="着色3" field="accent3" rules={[{ required: true, message: '请选择颜色' }]}>
								<ColorPicker showText disabledAlpha format="hex" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="着色4" field="accent4" rules={[{ required: true, message: '请选择颜色' }]}>
								<ColorPicker showText disabledAlpha format="hex" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="着色5" field="accent5" rules={[{ required: true, message: '请选择颜色' }]}>
								<ColorPicker showText disabledAlpha format="hex" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="着色6" field="accent6" rules={[{ required: true, message: '请选择颜色' }]}>
								<ColorPicker showText disabledAlpha format="hex" />
							</Form.Item>
						</Col>
					</Row>
				</Form.Item>
				<Form.Item label="超链接">
					<Row gutter={8}>
						<Col span={12}>
							<Form.Item label="初始" field="hlink" rules={[{ required: true, message: '请选择颜色' }]}>
								<ColorPicker showText disabledAlpha format="hex" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="已访问" field="folHlink" rules={[{ required: true, message: '请选择颜色' }]}>
								<ColorPicker showText disabledAlpha format="hex" />
							</Form.Item>
						</Col>
					</Row>
				</Form.Item>

				<Form.Item wrapperCol={{ offset: 5 }}>
					<Button type="primary" htmlType="submit" loading={loading} style={{ marginRight: 24 }}>
						提交
					</Button>
					<Button
						style={{ marginRight: 24 }}
						onClick={() => {
							form.resetFields();
						}}>
						重置
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
};
