import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useUrlState from '@ahooksjs/use-url-state';
import { Table, Form, Input, Space, Button, Popconfirm, Select, Tag, Message, Grid, Modal, Image, Popover } from '@arco-design/web-react';
import { IconSearch, IconPlus } from '@arco-design/web-react/icon';
import { GlobalContext } from '@/hooks/context';

import { getLayoutDetailList, postLayoutRemove } from '@/api/layoutNew';
import { putSlideEdit, putSlideStatus } from '@/api/slidesNew';
import { getSlideStyleOption } from '@/api/slideStyle';
import { getSlideStructs } from '@/api/slideStruces';

import { formatDate } from '@/utils';

const envAppMode = import.meta.env.VITE_APP_MODE;

export default () => {
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const FormItem = Form.Item;
	const Row = Grid.Row;
	const Col = Grid.Col;
	const { cdnUrl } = useContext(GlobalContext);

	const { libId }: any = useParams();
	const [searchParams] = useSearchParams();
	const urlPage: any = searchParams.get('page');
	const urlId = searchParams.get('id');
	const urlKey = searchParams.get('keywords');
	const urlStyle = searchParams.get('style');
	const urlLayout = searchParams.get('layout');

	const columns: any = [
		{
			title: 'ID',
			dataIndex: 'id',
			fixed: 'left',
			width: 100
		},
		{
			title: '缩略图',
			key: 'preview',
			fixed: 'left',
			width: 120,
			render: (_: any, record: any) => {
				let process = '';
				if (record.preview.indexOf('?') > -1) {
					process = '&x-oss-process=image/resize,m_fill,w_160,h_90';
				} else {
					process = '?x-oss-process=image/resize,m_fill,w_160,h_90';
				}
				return (
					<img
						style={{ width: 80, cursor: 'pointer' }}
						src={cdnUrl + record.preview + process}
						onClick={() => {
							setVisibleSrc(cdnUrl + record.preview);
							setVisible(true);
						}}
					/>
				);
			}
		},
		{
			title: '版式名称',
			width: 300,
			dataIndex: 'name'
		},
		{
			title: '版式风格',
			dataIndex: 'styleName',
			width: 100
		},
		{
			title: '图片数量',
			dataIndex: 'imgCount',
			width: 100
		},
		{
			title: '结构类型',
			key: 'structs',
			width: 120,
			render: (_: any, record: any) => <>{record.structs ? record.structs.join(',') : ''}</>
		},
		{
			title: '状态',
			key: 'status',
			width: 160,
			render: (_: any, record: any) => <>{record.status == 1 ? <Tag color="#00b42a">已启用</Tag> : <Tag color="#86909c">已下架</Tag>}</>
		},
		{
			title: '提交人',
			dataIndex: 'creatorName',
			width: 160
		},
		{
			title: '创建时间',
			key: 'createTime',
			width: 180,
			render: (_: any, record: any) => <>{formatDate(record.createTime)}</>
		},
		{
			title: '操作',
			width: 200,
			fixed: 'right',
			key: 'action',
			render: (_: any, record: any) => (
				<>
					{envAppMode != 'prod' && (
						<>
							<Space>
								<Button
									type="text"
									onClick={() => {
										navigate(`/editor/slide/${record.id}`, {
											state: {
												master: libMaster,
												pageType: 'layout'
											}
										});
									}}>
									编辑
								</Button>
								<Popover position="left" title="设置版式图片数量" trigger="focus" content={renderImgCount(record.id, record.imgCount)}>
									<Button type="text">图片数量</Button>
								</Popover>
							</Space>
							<Space>
								<Popconfirm position="left" title="是否要移除此版式？" onOk={() => handleDel(record)}>
									<Button type="text">移除</Button>
								</Popconfirm>
								<Popconfirm position="left" title={`是否${record.status == 1 ? '下架' : '上架'}此版式？`} onOk={() => handleStatus(record)}>
									<Button type="text">{record.status == 1 ? '下架' : '上架'}</Button>
								</Popconfirm>
							</Space>
						</>
					)}
				</>
			)
		}
	];

	const [styleList, setStyleList] = useState<any[]>([]);
	const [layoutOption, setLayoutOptions] = useState<any[]>([]);

	const [loading, setLoading] = useState(false);
	const [list, setList] = useState<any[]>([]);
	const [total, setTotal] = useState(0);
	const [libMaster, setLibMaster] = useState<string>('');

	const [query, setQuery] = useUrlState<any>({
		page: 1,
		id: undefined,
		keywords: undefined,
		style: undefined,
		layout: undefined
	});

	const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);

	const [visible, setVisible] = useState(false);
	const [visibleSrc, setVisibleSrc] = useState('');
	const imgNum = [
		{
			label: '0张',
			value: 0
		},
		{
			label: '1张',
			value: 1
		},
		{
			label: '2张',
			value: 2
		},
		{
			label: '3张',
			value: 3
		},
		{
			label: '4张',
			value: 4
		},
		{
			label: '5张',
			value: 5
		},
		{
			label: '6张',
			value: 6
		},
		{
			label: '7张',
			value: 7
		},
		{
			label: '8张',
			value: 8
		},
		{
			label: '9张',
			value: 9
		},
		{
			label: '10张',
			value: 10
		},
		{
			label: '10张以上',
			value: 11
		}
	];
	const renderImgCount = (id: number, imgTotal: number) => {
		return (
			<div style={{ width: 200, paddingTop: '10px' }}>
				<Space wrap>
					{imgNum.map((it: any) => (
						<Button
							key={`imgBtn-${it.value}`}
							size="mini"
							type={imgTotal == it.value ? 'primary' : 'secondary'}
							onClick={() => {
								handleEditImgCount(id, it.value);
							}}>
							{it.label}
						</Button>
					))}
				</Space>
			</div>
		);
	};
	// 编辑图片数量
	const handleEditImgCount = async (id: number, imgCount: number) => {
		let info: any = await putSlideEdit(id, {
			imgCount: imgCount || 0
		});
		if (info.code == 200) {
			Message.success('设置成功');
			getList();
		}
	};

	const onPageChange = (page: number) => {
		setQuery(() => ({ page }));
	};
	const handleSearch = (val: any) => {
		Object.keys(query).forEach((key) => {
			if (val[key]) {
				query[key] = val[key];
			} else {
				query[key] = undefined;
			}
		});

		setQuery(() => ({ ...query, page: 1 }));
	};

	const getList = async () => {
		setLoading(true);
		let res = await getLayoutDetailList(libId, {
			...query,
			size: 10
		});
		setLoading(false);
		setLibMaster(res.data.libMaster);
		setList(res.data.page.list);
		setTotal(res.data.page.total);
	};

	// 风格
	const getStyleList = async () => {
		let res: any = await getSlideStyleOption();
		if (res.code == 200) {
			setStyleList(res.data);
		}
	};
	// 版式类型
	const getLayoutList = async () => {
		let res = await getSlideStructs('1');
		setLayoutOptions(res.data);
	};

	// 上下架
	const handleStatus = async (item: any) => {
		let status = item.status == 1 ? 2 : 1;
		let res = await putSlideStatus(status, item.id);
		if (res.code == 200) {
			getList();
		}
	};
	// 删除
	const handleDel = async (item: any) => {
		let res = await postLayoutRemove(libId, [item.id]);
		if (res.code == 200) {
			Message.success('移除成功');
			getList();
		} else {
			Message.error(res.msg);
		}
	};
	const handleBatchDel = async () => {
		Modal.confirm({
			title: '提示',
			content: '确定要移除选中的记录吗？',
			style: {
				width: '300px'
			},
			onOk: async () => {
				let res = await postLayoutRemove(libId, selectedRowKeys);
				if (res.code == 200) {
					Message.success('移除成功');
					getList();
				} else {
					Message.error(res.msg);
				}
			},
			onCancel: () => {
				setSelectedRowKeys([]);
			}
		});
	};

	useEffect(() => {
		getList();
	}, [query]);

	useEffect(() => {
		getStyleList();
		getLayoutList();

		form.setFieldsValue({
			style: urlStyle ? parseInt(urlStyle) : undefined,
			layout: urlLayout ? parseInt(urlLayout) : undefined,
			id: urlId ? urlId : undefined,
			keywords: urlKey ? urlKey : undefined
		});

		setQuery({
			page: urlPage ? parseInt(urlPage) : 1,
			id: urlId ? urlId : undefined,
			keywords: urlKey ? urlKey : undefined,
			style: urlStyle ? parseInt(urlStyle) : undefined,
			layout: urlLayout ? parseInt(urlLayout) : undefined
		});
	}, []);

	return (
		<>
			<div className="page-filter">
				<Form form={form} onSubmit={handleSearch}>
					<Row gutter={16}>
						<Col span={6}>
							<FormItem field="style" label="风格">
								<Select allowClear placeholder="请选择风格类型">
									{styleList.map((item) => (
										<Select.Option key={item.value} value={item.value}>
											{item.label}
										</Select.Option>
									))}
								</Select>
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem field="layout" label="类型">
								<Select allowClear placeholder="请选择版式类型">
									{layoutOption.map((item) => (
										<Select.Option key={item.value} value={item.value}>
											{item.label}
										</Select.Option>
									))}
								</Select>
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem field="id" label="ID">
								<Input allowClear placeholder="请输入ID" />
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem field="keywords" label="关键字">
								<Input allowClear placeholder="请输入关键字" />
							</FormItem>
						</Col>
						<Col span={6}>
							<FormItem>
								<Space>
									<Button type="outline" icon={<IconSearch />} htmlType="submit">
										搜索
									</Button>
									<Button
										type="primary"
										icon={<IconPlus />}
										onClick={() => {
											navigate(`/editor/layout/append/${libId}`);
										}}>
										追加版式
									</Button>
								</Space>
							</FormItem>
						</Col>
					</Row>
				</Form>
			</div>
			<div className="page-warp">
				<Table
					borderCell
					rowKey={(record) => record.id}
					loading={loading}
					columns={columns}
					data={list}
					scroll={{
						x: 1300
					}}
					rowSelection={{
						selectedRowKeys,
						onChange: (selectedRowKeys: any[]) => {
							setSelectedRowKeys(selectedRowKeys);
						}
					}}
					pagination={{
						showTotal: true,
						showJumper: true,
						total: total,
						pageSize: 10,
						current: parseInt(query.page),
						onChange: onPageChange
					}}
					renderPagination={(paginationNode) => (
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								marginTop: 10
							}}>
							<Button type="outline" status="danger" disabled={selectedRowKeys.length == 0} onClick={handleBatchDel}>
								批量移除
							</Button>
							{paginationNode}
						</div>
					)}
				/>
			</div>

			<Image.Preview src={visibleSrc} visible={visible} onVisibleChange={setVisible} />
		</>
	);
};
