import { useCallback } from 'react'
import { Button } from '@arco-design/web-react'
import { App, IUI } from 'leafer-ui'

import useModal from '../useModal'
import ModalEditText from '../ModalEditText'
import { updateText } from '../../Editor/utils'
import { TextData } from '@/types/element'

interface EditTextButtonProps {
  target: IUI
  app: App
}

export default function EditTextButton({ target, app }: EditTextButtonProps) {
  const [openTextModal, textHolder] = useModal(ModalEditText)

  const handleEditText = useCallback(() => {
    const data = target.data as TextData
    target.locked = true
    openTextModal({
      data: structuredClone(data),
      onSuccess: async (data) => {
        await updateText(data, target)
        app.emit('changeData')
        target.locked = false
      },
      onClose: () => {
        target.locked = false
      }
    })
  }, [target, app, openTextModal])

  return <>
    {textHolder}
    <Button type='text' size='small' onClick={handleEditText}>
      编辑
    </Button>
  </>
}