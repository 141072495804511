import { Polygon } from 'leafer-ui'

import { Element } from '@/types/element'
import { ElementBaseOption } from '../../types'

export default async function getHexagon(option: ElementBaseOption, data: Element) {
  const { height, width } = data
  const yRadius = height / 2
  const xRadius = Math.min(height, width) * (typeof data.shape?.radius == 'number' ? data.shape.radius : 0.25)

  return new Polygon({
    ...option,
    points: [
      0,
      yRadius,
      xRadius,
      0,
      width - xRadius,
      0,
      width,
      yRadius,
      width - xRadius,
      height,
      xRadius,
      height
    ]
  })
}