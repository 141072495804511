import { createContext } from 'react'
import { App, IUI } from 'leafer-ui'

import { SlideData, Theme, ViewportSize } from '@/types/element'
import ImageHandle from '../utils/image'

export const MainContext = createContext<{
  state: {
    themeMap?: Theme
    viewportSize?: ViewportSize
    layoutList?: SlideData[]
    masterList?: SlideData[]
    imageHandle?: ImageHandle
    canUndo: boolean
    canRedo: boolean
    editApp?: App
    target?: IUI | null
  },
  reducers: {
    setCanUndo?(v: boolean): void
    setCanRedo?(v: boolean): void
    setEditApp?(v: App | undefined): void
    setTarget?(v: IUI | undefined | null): void
  }
}>({
  state: {
    canUndo: false,
    canRedo: false,
  },
  reducers: {}
})