/**
 * 版式类型
 * */
import request from '@/common/fetch';

// 版式结构化列表
export const getSlideStructs = (type: string) =>
	request({
		url: `/res/v1/template/structs/${type}`,
		method: 'get'
	});
// 版式结构化详情
export const getSlideStructsDetail = (type: string, layoutId: string) =>
	request({
		url: `/res/v1/template/structs/${type}/${layoutId}`,
		method: 'get'
	});
// 获取绑定的结构化数据
export const getSlideBindStructs = (id: any) =>
	request({
		url: `/res/v1/template/layout/struct/data/${id}`,
		method: 'get'
	});
