import { useCallback, useContext, useEffect, useState } from 'react';
import { Image, UI, Platform } from 'leafer-ui';

import { ImageData } from '@/types/element';
import { getBaseOptions, getShape } from '../utils';
import { MainContext } from '../context/main';
import { SlideContext } from '../context/slide';

Platform.image.crossOrigin = 'anonymous';

interface ElementImageProps {
	data: ImageData;
	onLoad(e?: UI): void;
	editable?: boolean;
}

export default function ElementImage({ data, onLoad, editable = false }: ElementImageProps) {
	const { imageHandle } = useContext(MainContext).state;
	const { theme } = useContext(SlideContext).state;
	const [element, setElement] = useState<UI>();

	const handleGetData = useCallback(async () => {
		if (!imageHandle) return;
		const option = await getBaseOptions(data, { theme, imageHandle });

		if (data.shape) {
			const shape = await getShape(data.shape.type, data, {
				...option,
				fill: await imageHandle.getImageFill(data),
				editable
			});
			if (shape) {
				shape.data = data;
				if (data.rot) {
					shape.rotateOf('center', data.rot);
				}
				if (data.flipX) shape.flip('x');
				if (data.flipY) shape.flip('y');
				setElement(shape);
				return;
			}
		}

		const image = new Image({
			...option,
			opacity: data.opacity,
			url: imageHandle.getUrl(data.src)
		});

		setElement(image);
	}, [data, theme, editable, imageHandle]);

	useEffect(() => {
		handleGetData().catch((err) => {
			console.error('image load error:', err);
		});
	}, [handleGetData]);

	useEffect(() => {
		element && onLoad(element);
		return () => {
			element?.remove();
		};
	}, [element, onLoad]);

	return null;
}
