import { IUI, App, Text } from 'leafer-ui'
import { useCallback, useState } from 'react'
import { FontSizeTwo } from '@icon-park/react'

import { getValidText, getRenderFill } from '@/components/Editor/utils'
import { FillData, ThemeValue } from '@/types/element'
import SelectColor from '../SelectColor'

interface EditFontColorProps {
  target: IUI
  app: App
  theme: ThemeValue['color']
}

export default function EditFontColor({ target, app, theme }: EditFontColorProps) {
  const [fill, setFill] = useState<FillData | undefined>(() => {
    const text = getValidText(target)
    
    return text?.data?.fill
  })

  const handleChange = useCallback(async (value: FillData) => {
    const texts = (target.children || [])?.filter(item => item.tag == 'Text') as Text[]
    for (const item of texts) {
      item.fill = await getRenderFill(value, { theme, width: item.width, height: item.height })
      item.data.fill = value
    }

    setFill(value)
    app.emit('changeData')
  }, [target, app, theme])

  return <SelectColor
    value={fill}
    onChange={handleChange}
    theme={theme}
    tip='字体颜色'
    icon={<FontSizeTwo />}
  />
}