import { Button, Tooltip } from '@arco-design/web-react';
import { IUI, App, Text } from 'leafer-ui';
import { AlignTextLeft, AlignTextRight, AlignTextCenter, AlignTextBoth } from '@icon-park/react';
import React, { useCallback, useState } from 'react';

import { getValidText } from '@/components/Editor/utils';

interface EditTextAlignProps {
	target: IUI;
	app: App;
}

const alignList: {
	key: Text['textAlign'];
	tip: string;
	icon: React.ReactNode;
}[] = [
	{
		key: 'left',
		tip: '左对齐',
		icon: <AlignTextLeft />
	},
	{
		key: 'center',
		tip: '居中对齐',
		icon: <AlignTextCenter />
	},
	{
		key: 'right',
		tip: '右对齐',
		icon: <AlignTextRight />
	},
	{
		key: 'justify',
		tip: '两端对齐',
		icon: <AlignTextBoth />
	}
];

export default function EditTextAlign({ target, app }: EditTextAlignProps) {
	const [current, setCurrent] = useState(() => {
		const text = getValidText(target);
		return text ? text.textAlign : 'center';
	});

	const handleChange = useCallback(
		(type: Text['textAlign']) => {
			target.children?.forEach((item) => {
				if (item.tag == 'Text') {
					const d = item as Text;
					d.textAlign = type;
				}
			});
			app.emit('changeData');
			setCurrent(type);
		},
		[target, app]
	);

	return alignList.map((item) => (
		<Tooltip key={item.key} content={item.tip}>
			<Button type={current == item.key ? 'secondary' : 'text'} onClick={() => handleChange(item.key)} size="small">
				{item.icon}
			</Button>
		</Tooltip>
	));
}
