import { useEffect, useState } from 'react';
import { Modal, Form, Input, Button, Message, DatePicker, Radio, Grid, Select } from '@arco-design/web-react';
import { addActivity, putActivityEdit, getActivityDetail } from '@/api/activity';
import uploadFile, { typeEnum } from '@/utils/uploadFile';
import { getSessionStorage, formatDate } from '@/utils';

import './index.less';

interface IProps {
	visible: boolean;
	info: any;
	isDetail?: boolean;
	onOk: () => void;
	onCancel: () => void;
}

export default (props: IProps) => {
	const { visible, info, isDetail, onOk, onCancel } = props;
	const [form] = Form.useForm();
	const FormItem = Form.Item;

	const { RangePicker } = DatePicker;
	const RadioGroup = Radio.Group;
	const TextArea = Input.TextArea;

	const cdnUrl = getSessionStorage('cdnUrl');

	const [loading, setLoading] = useState(false);
	const [upBtnload, setUpBtnload] = useState(false);

	const [detail, setDetails] = useState<any>(null);
	const [picsUrl, setPicsUrl] = useState('');

	const condType = Form.useWatch('condType', form);
	const pics = Form.useWatch('pics', form);

	const getDetail = async () => {
		try {
			const res = await getActivityDetail(info.id);
			if (res.code == 200) {
				let { name, startTime, endTime, pics, remark, buttonName, rewards } = res.data;

				const condType = rewards[0].conds[0].condType;

				const rewardValue = rewards[0].details[0].rewardValue;

				const rangeTime = [formatDate(startTime, 'YYYY-MM-DD'), formatDate(endTime, 'YYYY-MM-DD')];

				let param: any = {
					name,
					rangeTime,
					condType,
					frequencyType: rewards[0].frequencyType,
					frequency: rewards[0].frequency,
					rewardValue,
					remark,
					buttonName,
					pics
				};

				if (condType != 'FREE') {
					param.condVal = rewards[0].conds[0].condVal;
				}

				setDetails(res.data);
				setPicsUrl(pics);
				form.setFieldsValue(param);
			}
		} catch (error) {}
	};

	// 上传图片
	const handleUpImage = async (e: Event) => {
		try {
			setUpBtnload(true);
			const el: any = e.target as HTMLInputElement;
			const file = el.files[0];
			let info: any = await uploadFile(file, typeEnum.image);
			form.setFieldsValue({
				pics: info.data.key
			});
			setPicsUrl(info.data.key);
			setUpBtnload(false);
		} catch (error: any) {
			console.log(error);
			Message.error(error?.msg || error);
			setUpBtnload(false);
		}
	};

	const handleSave = async () => {
		try {
			await form.validate();
			setLoading(true);
			const value = await form.getFieldsValue();

			const param: any = {
				name: value.name,
				startTime: '',
				endTime: '',
				type: 'DEF',
				buttonName: value.buttonName,
				pics: picsUrl,
				remark: value.remark,
				allowedUserType: 'NORMAL',
				style: 'DIALOG',
				container: 'OPTIMIZE',
				rewards: [
					{
						claimMethod: 'MANUAL',
						// 达成条件
						conds: [
							{
								condType: value.condType, // 类型：FREE-免费，INVITE-邀请,可用值:FREE,INVITE
								condVal: value.condType == 'FREE' ? null : value.condVal // 条件值 null
							}
						],
						// 奖励明细
						details: [
							{
								userType: 'NORMAL',
								rewardValue: value.rewardValue,
								rewardType: 'DIAMOND'
							}
						],
						frequency: value.frequency,
						frequencyType: value.condType == 'INVITE' ? 'PERIODS' : value.frequencyType
					}
				]
			};

			if (value.rangeTime && value.rangeTime.length == 2) {
				param.startTime = new Date(`${value.rangeTime[0]} 00:00:00`).getTime();
				param.endTime = new Date(`${value.rangeTime[1]} 23:59:59`).getTime();
			}

			if (info?.id) {
				param.rewards[0].id = detail.rewards[0].id;
				param.rewards[0].conds[0].id = detail.rewards[0].conds[0].id;
				param.rewards[0].details[0].id = detail.rewards[0].details[0].id;

				const res = await putActivityEdit(info.id, param);
				setLoading(false);
				if (res.code === 200) {
					Message.success('修改成功');
					onOk();
					onCancel();
				}
			} else {
				const res = await addActivity(param);
				setLoading(false);
				if (res.code === 200) {
					Message.success('添加成功');
					onOk();
					onCancel();
				}
			}
		} catch (e) {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (visible) {
			if (info?.id) {
				getDetail();
			} else {
				form.setFieldsValue({
					condType: 'FREE',
					frequencyType: 'PERIODS'
				});
			}
		} else {
			form.resetFields();
		}
	}, [visible, info]);

	return (
		<Modal
			style={{ width: '600px' }}
			wrapClassName="activity-modal"
			title={info?.id ? (isDetail ? '查看活动' : '编辑活动') : '新增活动'}
			footer={null}
			visible={visible}
			onCancel={onCancel}>
			<Form
				disabled={isDetail}
				form={form}
				autoComplete="off"
				labelCol={{ span: 4, offset: 0 }}
				wrapperCol={{ span: 20, offset: 0 }}>
				<FormItem label="活动名称" field="name" rules={[{ required: true, message: '请输入活动名称' }]}>
					<Input placeholder="请输入活动名称" />
				</FormItem>
				<FormItem label="起止时间" field="rangeTime" rules={[{ required: true, message: '请输入起止时间' }]}>
					<RangePicker />
				</FormItem>

				<FormItem label="活动配置" style={{ marginBottom: '0px' }} rules={[{ required: true }]}>
					<Form.Item field="condType" rules={[{ required: true }]}>
						<RadioGroup>
							<Radio value="FREE">登录领取</Radio>
							<Radio value="INVITE" disabled>
								邀请领取
							</Radio>
						</RadioGroup>
					</Form.Item>

					{/* 登录 */}
					{condType == 'FREE' && (
						<Grid.Row gutter={4}>
							<Grid.Col span={12}>
								<Form.Item field="frequencyType" rules={[{ required: true }]}>
									<Select addBefore="每个账号" style={{ width: '100%' }}>
										<Select.Option value="PERIODS">活动期间</Select.Option>
										<Select.Option value="DAYS">每天</Select.Option>
									</Select>
								</Form.Item>
							</Grid.Col>
							<Grid.Col span={12}>
								<Form.Item
									field="frequency"
									extra="-1为不限制次数"
									rules={[{ required: true, type: 'number', message: '请输入可领取次数' }]}>
									<Input style={{ width: '100%' }} addBefore="可领取" addAfter="次" />
								</Form.Item>
							</Grid.Col>
							<Grid.Col span={12}>
								<Form.Item
									field="rewardValue"
									rules={[{ required: true, type: 'number', message: '请输入每次可领取数量' }]}>
									<Input style={{ width: '100%' }} addBefore="每次可领取" addAfter="钻石" />
								</Form.Item>
							</Grid.Col>
						</Grid.Row>
					)}

					{/* 邀请  condType = 'INVITE'   frequencyType = 'PERIODS' */}
					{condType == 'INVITE' && (
						<Grid.Row gutter={4}>
							<Grid.Col span={12}>
								<Form.Item field="condVal" rules={[{ required: true, type: 'number', message: '请输入数量' }]}>
									<Input style={{ width: '100%' }} addBefore="每邀请" addAfter="人注册账号" />
								</Form.Item>
							</Grid.Col>
							<Grid.Col span={12}>
								<Form.Item field="rewardValue" rules={[{ required: true, type: 'number', message: '请输入数量' }]}>
									<Input style={{ width: '100%' }} addBefore="可领取" addAfter="钻石" />
								</Form.Item>
							</Grid.Col>
							<Grid.Col span={12}>
								<Form.Item
									field="frequency"
									extra="-1为不限制次数"
									rules={[{ required: true, type: 'number', message: '请输入数量' }]}>
									<Input style={{ width: '100%' }} addBefore="总共可领取次数：" addAfter="次" />
								</Form.Item>
							</Grid.Col>
						</Grid.Row>
					)}
				</FormItem>

				<FormItem label="活动说明" field="remark" rules={[{ required: true, message: '请输入起止时间' }]}>
					<TextArea placeholder="请输入活动说明" style={{ minHeight: 64 }} />
				</FormItem>
				<FormItem label="按钮名称" field="buttonName" rules={[{ required: true, message: '请输入起止时间' }]}>
					<Input placeholder="请输入按钮名称" />
				</FormItem>
				<FormItem label="上传图片" field="pics" rules={[{ required: true, message: '请上传活动图片' }]}>
					{pics ? <img className="upload-img" src={cdnUrl + pics} /> : null}
					<Button className="upload-btn" type="primary" loading={upBtnload}>
						<input className="upload-file" type="file" onChange={(e: any) => handleUpImage(e)} />
						<span>{pics ? '修改' : '上传'}图片</span>
					</Button>
				</FormItem>

				<FormItem wrapperCol={{ offset: 4 }}>
					<Button type="primary" loading={loading} style={{ marginRight: 24 }} onClick={handleSave}>
						提交
					</Button>
					<Button
						style={{ marginRight: 24 }}
						onClick={() => {
							form.resetFields();
						}}>
						重置
					</Button>
				</FormItem>
			</Form>
		</Modal>
	);
};
