import { useContext } from 'react'

import { MainContext } from '../context/main'

interface UndoButtonProps {
  children: React.ReactElement
}

export default function UndoButton({ children }: UndoButtonProps) {
  const { canUndo, editApp } = useContext(MainContext).state

  return <children.type {...children.props} disabled={!canUndo} onClick={() => editApp?.emit('undo')} />
}