import { useState, useEffect } from 'react';
import { Modal, Message, Form, Input, ColorPicker } from '@arco-design/web-react';

import { postTempColor, putTempColorEdit } from '@/api/template';

import './editModal.less';

export default (props: any) => {
	const { info, visible, setVisible, onCallback } = props;
	const [form] = Form.useForm();
	const FormItem = Form.Item;

	const [loading, setLoading] = useState(false);

	const handleSave = async () => {
		try {
			const values = await form.validate();
			setLoading(true);
			if (info?.id) {
				let res = await putTempColorEdit(info.id, values);
				setLoading(false);
				if (res.code == 200) {
					Message.success('更新成功');
					onCallback();
					hamdleCancel();
				}
			} else {
				let res = await postTempColor(values);
				setLoading(false);
				if (res.code == 200) {
					Message.success('添加成功');
					onCallback();
					hamdleCancel();
				}
			}
		} catch (error) {}
	};
	const hamdleCancel = () => {
		setVisible(false);
		setLoading(false);
	};

	const rgbTohex = (rgbString: string) => {
		const match = rgbString.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);

		if (!match) {
			throw new Error("输入格式不正确，应该是类似 'rgb(255, 0, 0)' 的格式");
		}

		// 提取 RGB 分量
		const r = parseInt(match[1], 10);
		const g = parseInt(match[2], 10);
		const b = parseInt(match[3], 10);

		// 确保 RGB 的值在 0-255 的范围内
		const clamp = (value: number) => Math.max(0, Math.min(255, value));

		const r1 = clamp(r);
		const g1 = clamp(g);
		const b1 = clamp(b);

		// 转换为十六进制字符串，并确保两位长度
		const toHex = (value: number) => value.toString(16).padStart(2, '0');

		return `#${toHex(r1)}${toHex(g1)}${toHex(b1)}`;
	};

	useEffect(() => {
		if (visible && info) {
			form.setFieldsValue({
				name: info.name,
				color: info.color.indexOf('rgb') > -1 ? rgbTohex(info.color) : info.color
			});
		} else {
			form.resetFields();
		}
	}, [visible]);

	return (
		<Modal
			wrapClassName="edit-template-modal"
			title={info?.id ? '编辑版式颜色' : '新增版式颜色'}
			visible={visible}
			confirmLoading={loading}
			onOk={handleSave}
			onCancel={hamdleCancel}
			autoFocus={false}
			focusLock={true}
			style={{ width: '360px' }}>
			<Form form={form} layout="vertical">
				<FormItem label="颜色名称" field="name" rules={[{ required: true, message: '请输入名称' }]}>
					<Input placeholder="请输入类型名称" />
				</FormItem>
				<FormItem label="颜色" field="color" rules={[{ required: true, message: '请选择颜色' }]}>
					<ColorPicker showText />
				</FormItem>
			</Form>
		</Modal>
	);
};
