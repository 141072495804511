import { Polygon } from 'leafer-ui'

import { Element } from '@/types/element'
import { ElementBaseOption } from '../../types'

export default async function getLeftArrow(option: ElementBaseOption, data: Element) {
  const { width, height } = data
  const xRadius = Math.min(height, width) * (typeof data.shape?.point2 == 'number' ? data.shape.point2 : 0.5)
  const yRadius = height * (typeof data.shape?.point1 == 'number' ? data.shape.point1 : 0.5)

  return new Polygon({
    ...option,
    points: [
      0,
      height / 2,
      xRadius,
      0,
      xRadius,
      (height - yRadius) / 2,
      width,
      (height - yRadius) / 2,
      width,
      (height - yRadius) / 2 + yRadius,
      xRadius,
      (height - yRadius) / 2 + yRadius,
      xRadius,
      height,
    ]
  })
}