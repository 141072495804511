import { RefObject, useContext, useEffect, useState } from 'react';
import { App, IUI, Text, Box } from 'leafer-ui';
import '@leafer-in/editor';
import '@leafer-in/view';
// import '@leafer-in/text-editor'
// import { HTMLText } from '@leafer-in/html'

import { ViewportSize } from '@/types/element';
import EditorListener, { EditorListenerOption } from '../Listener/editor';
import { addAppFrame, setViewSize } from '../utils';
import SnapshotListener, { SnapshotListenerOption } from '../Listener/snapshot';
import type { ISnapshotSave } from './snapshot';
import { MainContext } from '../context/main';

interface UseAppProps {
	canvasRef: RefObject<HTMLCanvasElement>;
	viewportSize?: ViewportSize;
	canvasKey: string;
	editable: boolean;
	onSelect?(e: IUI): void;
	onChangeData: EditorListenerOption['onChangeData'];
	onUndo: SnapshotListenerOption['onUndo'];
	onRedo: SnapshotListenerOption['onRedo'];
	onSnapshotSave: ISnapshotSave;
}

Text.setEditConfig({
	editSize: 'size'
});

Box.setEditConfig({
	editSize: 'size'
});

export default function useApp({ canvasRef, viewportSize, canvasKey, editable, onSelect, onChangeData, onUndo, onRedo, onSnapshotSave }: UseAppProps) {
	const [app, setApp] = useState<App | null>(null);
	const { setEditApp } = useContext(MainContext).reducers;

	useEffect(() => {
		let app: App | null = null;
		let listener: EditorListener | null = null;

		if (canvasRef.current) {
			console.log('%cinit app', 'color: green');
			app = new App({
				width: canvasRef.current.offsetWidth,
				height: canvasRef.current.offsetHeight,
				view: canvasRef.current,
				// fill: 'rgba(0, 0, 0, .8)',
				cursor: false,
				sky: {},
				ground: {},
				tree: {
					move: {
						drag: false
					},
					wheel: {
						disabled: true,
						preventDefault: false
					},
					pointer: {
						touch: false,
						through: true
					}
				},
				move: {
					disabled: true
				},
				editor: editable
					? {
							hover: false,
							select: undefined,
							selector: editable,
							stroke: 'rgb(1, 136, 251)',
							strokeWidth: 1,
							boxSelect: false,
							editSize: 'scale'
					  }
					: undefined
			});
			app.id = canvasKey;
			app.tree.removeAll();
			app.ground.removeAll();

			addAppFrame(app);

			setApp(app);
			editable && setEditApp?.(app);
			if (editable) {
				listener = new EditorListener({
					app,
					canvas: canvasRef.current,
					onSelect,
					onChangeData
				});
				listener.register();
			}
		}

		return () => {
			listener?.off();
			app?.editor?.cancel();
			setApp(null);
			editable && setEditApp?.(undefined);
		};
	}, [canvasRef, canvasKey, editable, onSelect, onChangeData, setEditApp]);

	useEffect(() => {
		if (app && viewportSize) {
			setViewSize({ app, viewportSize });
		}
	}, [viewportSize, app]);

	useEffect(() => {
		let snapShotListener: SnapshotListener;
		if (app && editable) {
			snapShotListener = new SnapshotListener({ app, onRedo, onUndo, onSave: onSnapshotSave });
			snapShotListener.register();
		}
		return () => {
			snapShotListener?.off();
		};
	}, [app, onRedo, onUndo, onSnapshotSave, editable]);

	return app;
}
