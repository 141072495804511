import { IUI, Box, Text } from 'leafer-ui';

import { TextData } from '@/types/element';
import CustomText from '../Ui/text';

export async function updateText(data: TextData, target: IUI) {
	const { data: targetData } = target;
	if (targetData?.type != 'text') throw new Error('target 参数错误');
	const targetTexts = (target.children?.filter((item) => item.tag == 'Text') || []) as Text[];
	let sumHeight = 0;
	let oldSumHeight = 0;
	for (let i = 0; i < targetTexts.length; i++) {
		const item = targetTexts[i];
		oldSumHeight += item.height || 0;
		if (data.text[i]) {
			const t = data.text[i].text.map((it) => it.text).join('');
			// const oldHeight = item.height || 0
			item.text = t || ' ';
			// 用于更新高度
			item.getBounds();
			sumHeight += item.__local?.height || 0;
		} else {
			item.remove();
		}
	}

	const space = Math.max(((target.height || 0) - oldSumHeight) / 2, 2);
	const addTexts = data.text.slice(targetTexts.length);

	sumHeight += space;
	if (addTexts.length) {
		const lastText = targetTexts[targetTexts.length - 1];
		addTexts.forEach((item) => {
			const text = lastText.clone() as Text;
			const t = item.text.map((it) => it.text).join('');
			text.text = t;
			text.y = sumHeight;
			target.add(text);
			text.getBounds();
			sumHeight += lastText.__local?.height || 0;
		});
	}
	sumHeight += space;

	target.height = sumHeight;
	target.data = data;
}

export async function getTextSize(_data: TextData, text: string, sumWidth: number, style: Partial<CustomText>, box: Box, lInt: number) {
	let sum = 0;
	let y = style.y || 0;

	let textList: CustomText[] = [];
	text.split('').forEach((item) => {
		const textEvent = new CustomText({
			...style,
			text: item,
			x: sum,
			y
		});
		textEvent.getBounds();
		textEvent.x = sum;

		textEvent.width = Math.ceil(textEvent.width || 0);

		if (sum + textEvent.width < sumWidth) {
			sum += textEvent.width || 0;
			textList.push(textEvent);
		} else {
			const space = (sumWidth - sum) / 2;
			textList.forEach((item, index) => {
				if (style.textAlign == 'justify') {
					const w = sumWidth / textList.length;
					item.x = w * index;
				} else {
					item.x = (item.x || 0) + (style.textAlign == 'center' ? space : lInt);
				}
				box.add(item);
			});
			sum = textEvent.width;
			y += textList[0].height || 0;
			textList = [];
			textEvent.x = 0;
			textEvent.y = y;
			textList.push(textEvent);
		}
	});
	if (textList.length) {
		const space = (sumWidth - sum) / 2;
		textList.forEach((item) => {
			item.x = (item.x || 0) + (style.textAlign == 'center' ? space : lInt);
			box.add(item);
		});
		sum = 0;
		y = textList[0].height || 0;
		textList = [];
	}
}

export function getValidText(target: IUI) {
	const text = target.children?.find((item) => item.tag == 'Text') as Text | undefined;
	return text;
}
