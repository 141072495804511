import { useCallback, useEffect, useState, useRef, useContext } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';
import { Button, Message, Modal, Spin } from '@arco-design/web-react';

import Header from './component/header';
import StructureBind from './component/structureBind';
import Editor from '@/components/Editor';

import PptParse from '@/utils/PptParse';
import { SlideData, ViewportSize, Theme, DefaultStyle } from '@/types/element';

import { GlobalContext } from '@/hooks/context';

import { postAnalysisPPT } from '@/api';
import { getTempCateOptions, getTempColorOptions, postAppendTempLayout, putTempEdit, getTempDetail } from '@/api/template';
import { getLayoutDetail, postLayout, putLayout, postLayoutAppend, postTemplate } from '@/api/layoutNew';
import { getSlideDetail, postSlide, putSlideEdit } from '@/api/slidesNew';
import { getSlideStyleOption } from '@/api/slideStyle';

import { encryptBase64, decryptBase64, base64ToBlob } from '@shock/tool';

import { addFontFamily } from '@/utils/common';

import uploadFile, { typeEnum } from '@/utils/uploadFileOss';

import * as defaultJson from '@/common/default';

// import Layout001 from './layout001.json';
// import Template001 from './template001.json';

import './index.less';

/**
 * 1、新增版式库 /editor/layout/add
 * 2、编辑版式库 /editor/layout/edit/:id
 * 3、追加版式库 /editor/layout/append/:id（追加的版式可以设置绑定关系，其他不可）
 *
 * 4、单个版式编辑 /editor/slide/:id（版式库，模版通用）
 *
 * 5、新增模版 /editor/template/add
 * 6、编辑模版 /editor/template/edit/:id
 * 7、追加模版 /editor/template/append/:id（追加的版式可以设置绑定关系，其他不可）
 */

export default () => {
	const { cdnUrl, fontMap } = useContext(GlobalContext);

	const navigate = useNavigate();
	const location = useLocation();
	const { id }: any = useParams();
	// 新增模板时
	const routerState: any = location?.state;

	const [loading, setLoading] = useState<boolean>(false);
	const [loadTips, setLoadTips] = useState<any>('加载中...');
	const [loadMore, setLoadMore] = useState<boolean>(false);

	// 相关类型接口数据
	const [styleList, setStyleList] = useState<any>([]);
	const [typeList, setTypeList] = useState([]);
	const [colosList, setColosList] = useState([]);

	// 编辑
	const [editorType, setEditorType] = useState<any>('slide');
	const [editInfo, setEditInfo] = useState<any>(null);
	const [saveLoad, setSaveLoad] = useState<boolean>(false);

	// 编辑时，缓存母版、版式、字体的值，判断是否修改
	const [cacheMaster, setCacheMaster] = useState<SlideData[]>([]);
	const [cacheLayout, setCacheLayout] = useState<SlideData[]>([]);
	const [cacheFonts, setCacheFonts] = useState<string[]>([]);

	// 画布
	const thumbCanvasRef = useRef<any>({});
	// 缓存版式库、模版版式库数据
	const [cacheSlideList, setCacheSlideList] = useState<any[]>([]);
	const [cacheSlidePage, setCacheSlidePage] = useState<number>(0);
	const [isShowMoreBtn, setIsShowMoreBtn] = useState<boolean>(false);

	// ppt 数据相关
	const [slideList, setSlideList] = useState<SlideData[]>([]);
	const [themeMap, setThemeMap] = useState<Theme>({});
	const [layoutList, setLayoutList] = useState<SlideData[]>([]);
	const [viewportSize, setViewportSize] = useState<ViewportSize>({ width: 1280, height: 720, type: 'custom' });
	const [masterList, setMasterList] = useState<SlideData[]>([]);

	const [_defaultStyles, setDefaultStyles] = useState<{ [k: string]: DefaultStyle }>({});
	// const [picMap, setPicMap] = useState<any>({});
	const [fonts, setFonts] = useState<string[]>([]);

	// 当前操作的幻灯片
	const [curSlideId, setCurSlideId] = useState<string>('');
	const [slideIdx, setSlideIdx] = useState(0);
	const [curSlide, setCurSlide] = useState<any>(null);
	const [curSlideEles, setCurSlideEles] = useState<any>(null);
	const [curSlideRel, setCurSlideRel] = useState<any>(null);

	// 绑定相关
	const [isShowRelBindTool, setIsShowRelBindTool] = useState<boolean>(false);
	// 所有版式的绑定关系、图片数量
	const [relBindList, setRelBindList] = useState<any>({});

	/**
	 * 基础信息
	 */
	// 模板风格
	const getStyleList = async () => {
		let res = await getSlideStyleOption();
		setStyleList(res.data);
	};
	// 模板类型
	const getTypeList = async () => {
		let res: any = await getTempCateOptions();
		if (res.code == 200) {
			setTypeList(res.data);
		}
	};
	// 模板颜色
	const getColorList = async () => {
		let res = await getTempColorOptions();
		setColosList(res.data);
	};

	// header 回调
	const onHeaderCb = (val: any) => {
		if (val.type == 'back') {
			navigate(-1);
		}
		// if (val.type == 'save' && slideList.length > 0) {
		// 	handleSave(val.data);
		// }
		if (val.type == 'save') {
			handleSave(val.data);
		}
	};
	// 缩略图点击
	const onThumbClick = (idx: number) => {
		const item = slideList[idx];
		setSlideIdx(idx);
		setCurSlideId(item.id);
		setCurSlide(item);

		// console.log('item', JSON.stringify(item));

		let layoutEles: any[] = [];
		layoutList.forEach((slide) => {
			if (slide.name == item.layoutRef && slide.elements.length > 0) {
				layoutEles = slide.elements;
			}
		});

		setCurSlideEles([...layoutEles, ...item.elements]);

		if (relBindList[item.id]) {
			// console.log('sss', relBindList[item.id]);
			setCurSlideRel(JSON.parse(JSON.stringify(relBindList[item.id])));
		} else {
			// console.log('sss-1');
			setCurSlideRel(null);
		}
	};

	/**
	 * 上传
	 */
	// 遍历 layout，重置页面 ID、元素 ID
	const loopLayoutResetID = (elements: any[], startId: string) => {
		let eleId = parseInt(startId) + 1;
		elements.forEach((item) => {
			if (item.type == 'group') {
				item.id = eleId;
				eleId = loopLayoutResetID(item.elements, '' + eleId);
			} else {
				item.id = eleId;
				eleId++;
			}
		});
		return eleId;
	};

	// 上传 ppt 源文件
	const handleUpPpt = async (e: Event) => {
		try {
			setLoading(true);
			const el: any = e.target as HTMLInputElement;
			const file = el.files[0];
			let info: any = await uploadFile(file, typeEnum.pptx);

			let res = await postAnalysisPPT({
				key: info.key
			});
			let pptInfo: any = await axios.get(res.data.url);
			const parse = new PptParse({ useOriginData: false });

			const { slideList: resSlideList, viewportSize, layoutList, fontList, themeMap, masterList, defaultStyles } = parse.getData(pptInfo.data);

			console.log('resSlideList', fontList, resSlideList, themeMap);

			// 主题字体赋值
			let themeFont: any = [];
			Object.keys(themeMap).forEach((key) => {
				const item: any = themeMap[key];
				if (item?.font?.majorFont?.cs) {
					themeFont.push(item?.font?.majorFont?.cs);
				}
				if (item?.font?.majorFont?.ea) {
					themeFont.push(item?.font?.majorFont?.ea);
				}
				if (item?.font?.majorFont?.latin) {
					themeFont.push(item?.font?.majorFont?.latin);
				}
				if (item?.font?.minorFont?.cs) {
					themeFont.push(item?.font?.minorFont?.cs);
				}
				if (item?.font?.minorFont?.ea) {
					themeFont.push(item?.font?.minorFont?.ea);
				}
				if (item?.font?.minorFont?.latin) {
					themeFont.push(item?.font?.minorFont?.latin);
				}
			});
			// 加载字体
			const newFonts = [...new Set([...fontList, ...themeFont])];
			console.log('newFonts', newFonts);
			for (let i = 0; i < newFonts.length; i++) {
				let key = newFonts[i];
				if (fontMap[key]) {
					try {
						await addFontFamily(key, cdnUrl + fontMap[key]);
					} catch (error) {}
				}
			}

			const layouts: any[] = [];
			layoutList.forEach((item, index) => {
				const { layoutName, name, elements } = item;
				const id = `${index + 11}000`;
				if (elements.length > 0) {
					loopLayoutResetID(elements, id);
				}
				layouts.push({
					id,
					layoutName,
					name,
					elements
				});
			});

			resSlideList.forEach((item) => {
				if (editorType == 'layoutAdd') {
					item.type = 1;
				}
				if (editorType == 'templateAdd') {
					item.type = 0;
				}
				// 有ID 的情况下，是追加版式
				if (['layoutAppend', 'templateAppend'].includes(editorType)) {
					item.append = 1;
				}
			});

			let layoutEles: any[] = [];
			layoutList.forEach((slide: SlideData) => {
				if (slide.name == resSlideList[0].layoutRef && slide.elements.length > 0) {
					layoutEles = slide.elements;
				}
			});

			// 添加
			if (['layoutAdd', 'templateAdd'].includes(editorType)) {
				console.log('ssss-1');
				setThemeMap(themeMap);
				setViewportSize(viewportSize);
				setDefaultStyles(defaultStyles);
			}

			setSlideList([...resSlideList, ...slideList]);
			setFonts([...fonts, ...newFonts]);

			setLayoutList(layouts);
			setMasterList(masterList);

			setCurSlideId(resSlideList[0].id);
			setSlideIdx(0);
			setCurSlide(resSlideList[0]);

			// 当前页绑定关系
			setCurSlideEles([...layoutEles, ...resSlideList[0].elements]);
			setCurSlideRel(null);

			setLoading(false);
		} catch (error: any) {
			setLoading(false);
		}
	};

	// 上传缩略图到七牛云
	const handleUpThumb = async (id: any) => {
		let curSlideRef = thumbCanvasRef.current[id];
		if (curSlideRef) {
			const baseImg = await curSlideRef.exportImage('image/jpeg', { quality: 0.3 });
			const picBase64 = baseImg.split('data:image/jpeg;base64,')[1];
			const picBlob = base64ToBlob(picBase64, `image/jpeg`);
			let file = new window.File([picBlob], 'preview.jpeg', { type: 'image/jpeg' });
			let info: any = await uploadFile(file, typeEnum.preview);
			return info;
		} else {
			return null;
			// return Promise.reject('无法获取缩略图');
		}
	};

	/**
	 * 绑定关系回调
	 */
	const onRelBindCb = useCallback(
		(data: any) => {
			console.log('bindRel::-1', data);
			if (relBindList[curSlideId]) {
				console.log('bindRel::-2');
				if (relBindList[curSlideId].pd != data) {
					relBindList[curSlideId].pd = data;
					relBindList[curSlideId].isEdit = true;
				}
			} else {
				console.log('bindRel::-3');
				relBindList[curSlideId] = {
					layoutId: curSlideId,
					pd: data,
					imgCount: 0,
					isEdit: true
				};
			}
			setRelBindList({ ...relBindList });
		},
		[curSlideId]
	);

	// 图片数量回调
	const onImgBindCb = useCallback(
		(data: any) => {
			console.log('bindImg::-1', data);
			if (relBindList[curSlideId]) {
				console.log('bindImg::-2');
				if (relBindList[curSlideId].imgCount != data) {
					relBindList[curSlideId].imgCount = data;
					relBindList[curSlideId].isEdit = true;
				}
			} else {
				console.log('bindImg::-3');
				relBindList[curSlideId] = {
					layoutId: curSlideId,
					pd: '',
					imgCount: data,
					isEdit: true
				};
			}
			setRelBindList({ ...relBindList });
		},
		[curSlideId]
	);

	/**
	 * 保存逻辑
	 */
	const handleSave = async (values: any) => {
		// 版式编辑
		if (editorType == 'slide') {
			await onSaveSlidesEdit();
		}

		// 版式库新增
		if (editorType == 'layoutAdd') {
			await onSaveAddLayout(values);
		}
		// 版式库编辑
		if (editorType == 'layoutEdit') {
			await onSaveEditLayout();
		}
		// 版式库追加
		if (editorType == 'layoutAppend') {
			await onSaveAppendLayout(values);
		}

		// 模版新增
		if (editorType == 'templateAdd') {
			await onSaveAddTemplate(values);
		}
		// 模版编辑
		if (editorType == 'templateEdit') {
			await onSaveEditTemplate();
		}
		// 模版追加
		if (editorType == 'templateAppend') {
			await onSaveAppendTemplate(values);
		}
	};
	// 编辑单个版式
	const onSaveSlidesEdit = async () => {
		try {
			setSaveLoad(true);
			setLoading(true);
			setLoadTips('更新中...');
			let formData = {
				pd: [relBindList[curSlideId].pd],
				imgCount: relBindList[curSlideId].imgCount || 0
			};

			let info: any = await putSlideEdit(id, formData);
			if (info.code == 200) {
				Message.success('更新成功');
				navigate(-1);
			}
		} catch (error: any) {
		} finally {
			setSaveLoad(false);
			setLoading(false);
			setLoadTips('加载中...');
		}
	};

	/**
	 * 循环上传单个版式
	 * val: 版式数据
	 * list：需要上传的列表
	 * type：add-新增，append-追加
	 * sign：layout-版式，template-模版
	 */
	const loopUploadLayout = async (val: any, list: SlideData[], sign: string, type: string = 'add') => {
		const layoutIds = [],
			slideThumb = [],
			slideLayoutObj: any = {};
		// 循环上传单个版式
		for (let i = 0; i < list.length; i++) {
			const item: any = list[i];
			// 绑定关系
			const slideRel = relBindList[item.id];

			if (slideRel && slideRel.pd) {
				const idx = type == 'add' ? 1 : slideList.length + cacheSlideList.length;

				let formData = {
					preview: '',
					rawData: '',
					pd: [slideRel.pd],
					...val,
					name: `${val.name}${type == 'append' ? '-追加' : ''}-${i + idx}`,
					imgCount: slideRel.imgCount || 0,
					type: sign == 'layout' ? 1 : 0
				};

				if (item.append) {
					delete item.append;
				}
				if (item.type) {
					delete item.type;
				}

				// 上传版式 json
				const jsonBolbFile: any = encryptBase64(
					{
						...item,
						version: '2.0'
					},
					'layout'
				);
				const jsonRes = await uploadFile(jsonBolbFile, typeEnum.layout, true);
				formData.rawData = jsonRes.key;

				// 上传图片
				let thumbUrl: any = await handleUpThumb(item.id);
				formData.preview = thumbUrl.key;
				const res = await postSlide(formData);

				layoutIds.push(res.data);
				slideThumb.push(formData.preview);

				slideLayoutObj[res.data] = item;
			}
			const strTips = `版式已上传：${i + 1} / ${list.length}`;
			setLoadTips(strTips);
		}

		return {
			layoutIds,
			preview: slideThumb[0],
			slideLayoutObj
		};
	};
	// 上传母版、版式、主题、字体等信息
	const onUploadMaster = async () => {
		setLoadTips('母版上传中...');
		// 上传母版JSON
		const masterJson = {
			masterList,
			layoutList,
			theme: themeMap,
			fonts
		};
		const jsonBolbFile: any = encryptBase64(masterJson, 'master');
		const jsonRes = await uploadFile(jsonBolbFile, typeEnum.master, true);
		return jsonRes;
	};

	// 新增版式库
	const onSaveAddLayout = async (v: any) => {
		try {
			setSaveLoad(true);
			setLoading(true);

			const jsonRes = await onUploadMaster();

			setLoadTips('开始上传版式...');
			const { layoutIds, preview } = await loopUploadLayout(v, slideList, 'layout');
			if (layoutIds.length > 0) {
				setLoadTips('新增版式库创建中...');
				const formData = {
					libMaster: jsonRes.key,
					name: v.name,
					style: v.style,
					layoutIds,
					preview
				};
				await postLayout(formData);
				Message.success('版式库新增成功');
				navigate(-1);
			} else {
				Modal.warning({
					title: '提示',
					content: '请添加版式，选择绑定关系，再创建模版',
					okText: '确定'
				});
			}
		} catch (error: any) {
		} finally {
			setSaveLoad(false);
			setLoading(false);
			setLoadTips('加载中...');
		}
	};
	// 编辑版式库
	const onSaveEditLayout = async () => {
		try {
			setLoading(true);
			setSaveLoad(true);
			const updateSlides: any[] = Object.values(relBindList).filter((it: any) => it.isEdit);
			if (updateSlides.length > 0) {
				setLoadTips('开始上传修改的版式...');
				for (let i = 0; i < updateSlides.length; i++) {
					const item = updateSlides[i];

					let formData = {
						pd: [item.pd],
						imgCount: item.imgCount || 0
					};
					await putSlideEdit(item.layoutId, formData);
					const strTips = `更新版式已上传：${i + 1} / ${updateSlides.length}`;
					setLoadTips(strTips);
				}

				Message.success('已更新完毕');
				navigate(-1);
			} else {
				Message.warning('未修改版式');
				navigate(-1);
			}
		} catch (error) {
		} finally {
			setLoading(false);
			setSaveLoad(false);
			setLoadTips('加载中...');
		}
	};
	// 追加版式库
	const onSaveAppendLayout = async (v: any) => {
		try {
			setLoading(true);
			setSaveLoad(true);

			setLoadTips('开始上传追加的版式...');
			const appendSlides = slideList.filter((item: any) => item?.append);
			console.log('appendSlides', appendSlides);
			const { layoutIds } = await loopUploadLayout(v, appendSlides, 'layout', 'append');

			if (layoutIds.length > 0) {
				setLoadTips('追加版式库更新中...');
				await postLayoutAppend(id, { layoutIds });
				Message.success('版式库追加成功');

				// 更新母版等文件
				let libMaster: any = null;
				if (_.isEqual(cacheMaster, masterList) || _.isEqual(cacheLayout, layoutList) || _.isEqual(cacheFonts, fonts)) {
					libMaster = await onUploadMaster();
				}
				if (libMaster) {
					setLoadTips('更新版式母版信息中...');
					await putLayout(id, {
						libMaster: libMaster.key
					});
				}
				navigate(-1);
			} else {
				Message.warning('未追加版式或未选择绑定关系');
			}
		} catch (error) {
		} finally {
			setLoading(false);
			setSaveLoad(false);
			setLoadTips('加载中...');
		}
	};

	// 构建模版
	const onSaveAddTemplate = async (v: any) => {
		try {
			setLoading(true);
			setSaveLoad(true);
			// 上传模版的母版JSON(母版、版式、主题、字体)
			const jsonRes = await onUploadMaster();

			// 上传模版的个性化版式
			setLoadTips('开始上传模版版式...');
			// 排除版式库版式
			const templateLayoutList = slideList.filter((item: any) => item.type == 0);
			const { layoutIds, preview } = await loopUploadLayout(v, templateLayoutList, 'template');

			if (layoutIds.length > 0) {
				setLoadTips('新增模版创建中...');
				const formData = {
					master: jsonRes.key,
					layoutIds,
					preview,
					...v,
					price: Math.round(v.price * 100),
					sort: 0
				};

				await postTemplate(routerState?.libId, formData);

				Message.success('模版创建成功');

				navigate('/layout/template');
			} else {
				Modal.warning({
					title: '提示',
					content: '请添加版式，选择绑定关系，再创建模版',
					okText: '确定'
				});
			}
		} catch (error) {
		} finally {
			setLoading(false);
			setSaveLoad(false);
			setLoadTips('加载中...');
		}
	};
	// 编辑模版
	const onSaveEditTemplate = async () => {
		try {
			setLoading(true);
			setSaveLoad(true);
			const updateSlides: any[] = Object.values(relBindList).filter((it: any) => it.isEdit);
			if (updateSlides.length > 0) {
				setLoadTips('开始上传修改的版式...');
				for (let i = 0; i < updateSlides.length; i++) {
					const item = updateSlides[i];

					let formData = {
						pd: [item.pd],
						imgCount: item.imgCount || 0
					};
					await putSlideEdit(item.layoutId, formData);
					const strTips = `更新版式已上传：${i + 1} / ${updateSlides.length}`;
					setLoadTips(strTips);
				}
				Message.success('已更新完毕');
				navigate(-1);
			} else {
				Message.warning('未修改版式');
				navigate(-1);
			}
		} catch (error) {
		} finally {
			setSaveLoad(false);
			setLoading(false);
		}
	};
	// 追加模版版式
	const onSaveAppendTemplate = async (v: any) => {
		try {
			setLoading(true);
			setSaveLoad(true);

			let libMaster: any = null;
			if (_.isEqual(cacheMaster, masterList) || _.isEqual(cacheLayout, layoutList) || _.isEqual(cacheFonts, fonts)) {
				libMaster = await onUploadMaster();
			}

			setLoadTips('开始上传追加的模版版式...');
			const appendSlides = slideList.filter((item: any) => item?.append);
			const { layoutIds } = await loopUploadLayout(v, appendSlides, 'template', 'append');

			setLoadTips('追加模板更新中...');
			const res = await postAppendTempLayout(id, {
				layoutIds
			});
			// 更新母版等文件
			if (libMaster) {
				await putTempEdit(id, {
					master: libMaster.key
				});
			}

			if (res.code == 200) {
				Message.success('模版版式追加成功');
				navigate('/layout/template?page=1');
			}
		} catch (error: any) {
			// Message.error(error?.msg || error);
		} finally {
			setSaveLoad(false);
			setLoading(false);
		}
	};

	/**
	 * 编辑 - 获取详情
	 */
	// 单个版式详情
	const getSlideInfo = async () => {
		try {
			setLoading(true);
			// 获取详情
			const { data }: any = await getSlideDetail(id);

			// 获取母版数据
			const { master } = routerState;
			const { data: masterStr, status: masterStatus } = await axios.get(cdnUrl + master);

			// 获取版式数据
			const { data: slide, status } = await axios.get(data.rawData);

			if (masterStatus == 200 && status == 200) {
				const { masterList, layoutList, theme, fonts } = decryptBase64(masterStr);
				const slideData = decryptBase64(slide);
				slideData.id = data.id;

				if (fonts) {
					// 加载字体
					for (let i = 0; i < fonts.length; i++) {
						let key = fonts[i];
						if (fontMap[key]) {
							await addFontFamily(key, cdnUrl + fontMap[key]);
						}
					}
				}

				let layoutEles: any[] = [];
				layoutList.forEach((slide: SlideData) => {
					if (slide.name == slideData.layoutRef && slide.elements.length > 0) {
						layoutEles = slide.elements;
					}
				});

				setEditInfo({
					id: data.id,
					name: data.name
				});

				const rel = {
					[slideData.id]: {
						layoutId: data.id,
						pd: data.pd[0] || '',
						imgCount: data.imgCount || 0,
						isEdit: false
					}
				};
				console.log('rel', rel);

				setRelBindList(rel);

				console.log('slideData', theme, slideData);

				setSlideList([slideData]);
				setThemeMap(theme);
				setMasterList(masterList);
				setLayoutList(layoutList || defaultJson.layoutList);
				setViewportSize(defaultJson.viewportSize);

				setCurSlideId(slideData.id);
				setSlideIdx(0);
				setCurSlide(slideData);
				// 当前页绑定关系
				setCurSlideEles([...layoutEles, ...slideData.elements]);
				setCurSlideRel(JSON.parse(JSON.stringify(rel[data.id])));
			}
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	// 版式库详情
	const getLayoutInfo = async (type: string = 'layoutAdd', id: string | number) => {
		try {
			setLoading(true);
			const { code, data }: any = await getLayoutDetail(id);
			if (code == 200) {
				const { name, style, slides, libMaster } = data;

				setEditInfo({ name, style: parseInt(style) });

				if (type.indexOf('layout') > -1) {
					let slideList = [],
						bindRel: any = {},
						cacheSlideList: any = [],
						layoutEles: any[] = [];

					// 获取母版、主题色、字体
					const { data } = await axios.get(cdnUrl + libMaster);
					const { masterList, layoutList, theme, fonts } = decryptBase64(data);

					console.log('layout-data');

					// 版式库编辑
					if (type == 'layoutEdit') {
						for (let i = 0; i < slides.length; i++) {
							const item = slides[i];
							if (i <= 30) {
								const { data } = await axios.get(item.rawData);
								const slideData = decryptBase64(data);
								// 版式绑定关系
								bindRel[item.id] = {
									layoutId: item.id,
									pd: item?.pd[0] || '',
									imgCount: item.imgCount || 0,
									isEdit: false
								};
								// 将版式 ID作为slideId
								slideData.id = item.id;
								slideList.push(slideData);
							} else {
								cacheSlideList.push(item);
							}
						}
						setRelBindList(bindRel);
						layoutList.forEach((slide: SlideData) => {
							if (slide.name == slideList[0].layoutRef && slide.elements.length > 0) {
								layoutEles = slide.elements;
							}
						});

						setSlideList(slideList);

						// 设置当前展示页
						setCurSlideId(slideList[0].id);
						setSlideIdx(0);
						setCurSlide(slideList[0]);

						// 当前页绑定关系
						setCurSlideEles([...layoutEles, ...slideList[0].elements]);
						if (bindRel[slideList[0].id]) {
							setCurSlideRel(JSON.parse(JSON.stringify(bindRel[slideList[0].id])));
						}
					} else {
						cacheSlideList = [...slides];
					}

					if (fonts) {
						// 加载字体
						for (let i = 0; i < fonts.length; i++) {
							let key = fonts[i];
							if (fontMap[key]) {
								await addFontFamily(key, cdnUrl + fontMap[key]);
							}
						}
					}

					console.log('sf', { name, style: parseInt(style) });

					setCacheSlideList(cacheSlideList);

					setThemeMap(theme);
					setMasterList(masterList);
					setLayoutList(layoutList || defaultJson.layoutList);
					setViewportSize(defaultJson.viewportSize);

					// 编辑时缓存数据
					setCacheMaster(JSON.parse(JSON.stringify(masterList)));
					setCacheLayout(JSON.parse(JSON.stringify(layoutList || [])));
					setCacheFonts(JSON.parse(JSON.stringify(fonts)));
				}
				// 模板新增
				if (type == 'templateAdd') {
					setCacheSlideList(slides);
				}
			}
		} catch (error) {
			console.log('error', error);
		} finally {
			setLoading(false);
		}
	};

	// 模版详情
	const getTempInfo = async () => {
		try {
			setLoading(true);
			let { code, data }: any = await getTempDetail(id);
			if (code == 200) {
				const { name, style, color, master, slides, type, price } = data;

				let slideList = [],
					cacheSlideList: any = [],
					bindRel: any = {};

				for (let i = 0; i < slides.length; i++) {
					const item = slides[i];
					if (i < 10) {
						const pptInfo: any = await axios.get(item.rawData);
						const slideData = decryptBase64(pptInfo.data);
						// 将版式 ID作为slideId
						slideData.id = item.id;
						// 0 - 默认 1 - 官方版式库
						slideData.type = item.type;

						if (item.type == 0) {
							// 版式绑定关系
							bindRel[item.id] = {
								layoutId: item.id,
								pd: item?.pd[0] || '',
								imgCount: item.imgCount || 0,
								isEdit: false
							};
						}

						slideList.push(slideData);
					} else {
						cacheSlideList.push(item);
					}
				}

				// 获取母版
				const { data: masterStr } = await axios.get(cdnUrl + master);
				const { masterList, layoutList, theme, fonts } = decryptBase64(masterStr);

				// 加载字体
				for (let i = 0; i < fonts.length; i++) {
					let key = fonts[i];
					if (fontMap[key]) {
						await addFontFamily(key, cdnUrl + fontMap[key]);
					}
				}

				let layoutEles: any[] = [];
				layoutList.forEach((slide: SlideData) => {
					if (slide.name == slideList[0].layoutRef && slide.elements.length > 0) {
						layoutEles = slide.elements;
					}
				});

				setEditInfo({ name, style, color, type, price });

				setRelBindList(bindRel);

				setSlideList(slideList);
				setCacheSlideList(cacheSlideList);

				setLayoutList(layoutList);
				setThemeMap(theme);
				setMasterList(masterList);
				setViewportSize(defaultJson.viewportSize);

				console.log(
					JSON.stringify({
						theme,
						slides: slideList,
						fonts,
						masterList,
						layouts: layoutList,
						defaultStyles: defaultJson.defaultStyles
					})
				);

				setCurSlideId(slideList[0].id);
				setSlideIdx(0);
				setCurSlide(slideList[0]);

				// 当前页绑定关系
				setCurSlideEles([...layoutEles, ...slideList[0].elements]);
				if (bindRel[slideList[0].id]) {
					setCurSlideRel(JSON.parse(JSON.stringify(bindRel[slideList[0].id])));
				}

				// 缓存
				setCacheMaster(JSON.parse(JSON.stringify(masterList)));
				setCacheLayout(JSON.parse(JSON.stringify(layoutList || [])));
				setCacheFonts(JSON.parse(JSON.stringify(fonts)));
			}
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	// 加载更多模版样式
	const loadMoreLayout = async () => {
		setLoadMore(true);
		let cacheslide = [];

		const offsetStart = 10 * cacheSlidePage;
		const offsetPage = cacheSlidePage + 1;
		const offsetEnd = 10 * offsetPage > cacheSlideList.length ? cacheSlideList.length : 10 * offsetPage;

		for (let i = offsetStart; i < offsetEnd; i++) {
			const item = cacheSlideList[i];

			const pptInfo: any = await axios.get(item.rawData);
			const slideData = decryptBase64(pptInfo.data);
			// 将版式 ID作为slideId
			slideData.id = item.id;
			// 0 - 默认 1 - 官方版式库
			slideData.type = item.type;

			// 版式库版式，模板自身版式，可以编辑
			if (editorType.indexOf('layout') > -1 || (editorType.indexOf('template') > -1 && item.type == 0)) {
				// 版式绑定关系
				relBindList[item.id] = {
					layoutId: item.id,
					pd: item?.pd[0] || '',
					imgCount: item.imgCount || 0,
					isEdit: false
				};
			}
			if (slideData.append) {
				delete slideData.append;
			}
			cacheslide.push(slideData);
		}
		setSlideList([...slideList, ...cacheslide]);
		setRelBindList({ ...relBindList });

		setCacheSlidePage(offsetPage);

		setLoadMore(false);
	};
	// 是否显示加载更多按钮
	useEffect(() => {
		if (cacheSlideList.length > 0 && slideList.length > 0) {
			if (cacheSlidePage * 10 < cacheSlideList.length) {
				setIsShowMoreBtn(true);
			} else {
				setIsShowMoreBtn(false);
			}
		} else {
			setIsShowMoreBtn(false);
		}
	}, [cacheSlideList, slideList, cacheSlidePage]);

	// 计算是否显示绑定关系 panel
	useEffect(() => {
		// 追加，只有追加的版式可编辑
		if (['layoutAppend', 'templateAppend'].includes(editorType)) {
			if (curSlide?.append) {
				setIsShowRelBindTool(true);
			} else {
				setIsShowRelBindTool(false);
			}
		} else if (['templateAdd', 'templateEdit'].includes(editorType)) {
			// 模版编辑，只有模版自己的版式可以编辑
			if (curSlide?.type == 0) {
				setIsShowRelBindTool(true);
			} else {
				setIsShowRelBindTool(false);
			}
		} else {
			setIsShowRelBindTool(true);
		}
	}, [editorType, curSlide]);

	useEffect(() => {
		if (cdnUrl && Object.keys(fontMap).length > 0) {
			const { pathname } = location;
			let editorType = 'slide';
			// 单个版式编辑
			if (pathname.indexOf('/editor/slide') > -1 && id) {
				editorType = 'slide';
				getSlideInfo();
			}
			// 版式库添加
			if (pathname.indexOf('/editor/layout/add') > -1) {
				editorType = 'layoutAdd';
			}
			// 版式库编辑
			if (pathname.indexOf('/editor/layout/edit/') > -1) {
				editorType = 'layoutEdit';
				getLayoutInfo('layoutEdit', id);
			}
			// 版式库追加
			if (pathname.indexOf('/editor/layout/append/') > -1) {
				editorType = 'layoutAppend';
				getLayoutInfo('layoutAppend', id);
			}
			// 模版添加
			if (pathname.indexOf('/editor/template/add') > -1) {
				editorType = 'templateAdd';
				// 获取基于版式库的版式集合
				getLayoutInfo('templateAdd', routerState.libId);
			}
			// 模版编辑
			if (pathname.indexOf('/editor/template/edit/') > -1) {
				editorType = 'templateEdit';
				getTempInfo();
			}
			// 模版版式追加
			if (pathname.indexOf('/editor/template/append/') > -1) {
				editorType = 'templateAppend';
				getTempInfo();
			}
			setEditorType(editorType);
		}
	}, [location, id, cdnUrl, fontMap]);

	useEffect(() => {
		getStyleList();
		getTypeList();
		getColorList();
	}, []);

	return (
		<Spin loading={loading} size={50} tip={<span style={{ fontSize: '18px' }}>{loadTips}</span>}>
			<div className="ppt-editor">
				<Header info={editInfo} editorType={editorType} saveLoad={saveLoad} {...{ typeList, colosList, styleList }} onCb={onHeaderCb}>
					{!['slide', 'layoutEdit', 'templateEdit'].includes(editorType) && (
						<Button className="upload-btn" status="success">
							<input className="upload-file" type="file" accept=".pptx" onChange={(e: any) => handleUpPpt(e)} />
							<span>上传{editorType.indexOf('layout') > -1 ? '版式库' : '模版'}PPT</span>
						</Button>
					)}
				</Header>
				<Editor viewportSize={viewportSize} layoutList={layoutList} masterList={masterList} themeMap={themeMap} cdnPrefix={cdnUrl}>
					<div className="editor-warp flex">
						{/* 缩略图 */}
						<div className="editor-thumb">
							{slideList.map((item: any, index) => (
								<div className="thumb-item" key={index}>
									<span className="item-index">{index + 1}</span>
									{item?.append && <span className="item-append">追加版式</span>}
									{editorType.indexOf('template') > -1 && item.type == 1 && <span className="item-type">版式库版式</span>}
									<div className={index == slideIdx ? 'item-content active' : 'item-content'} onClick={() => onThumbClick(index)}>
										<Editor.Slide
											ref={(ref) => {
												if (ref) {
													thumbCanvasRef.current[item.id] = ref;
												}
											}}
											data={item}
											key={item.id}
										/>
									</div>
								</div>
							))}

							{isShowMoreBtn && (
								<div className="flex justify-center">
									<Button type="secondary" loading={loadMore} onClick={() => loadMoreLayout()}>
										加载更多
									</Button>
								</div>
							)}
						</div>
						{/* 画布 */}
						<div className="editor-list flex-1 flex justify-center items-center">
							{curSlide ? (
								<Editor.Slide
									data={curSlide}
									onInitLoaded={() => {
										console.log('Slide Render');
									}}
								/>
							) : null}
						</div>
						<div className="tool-panel">
							{/* 版式库：可全编辑；模版：模板版式可编辑，版式库版式不可编辑 */}
							{isShowRelBindTool && <StructureBind slideId={curSlideId} relInfo={curSlideRel} slideElement={curSlideEles} onRelBindCb={onRelBindCb} onImgBindCb={onImgBindCb} />}
						</div>
					</div>
				</Editor>
			</div>
		</Spin>
	);
};
