import { useCallback, useContext, useEffect, useState } from 'react'

import db from '../db'
import { SlideData } from '@/types/element'
import { MainContext } from '../context/main'

export type SnapshotType = 'slide' | 'theme'

export type ISnapshotSave = (data: SlideData, type?: SnapshotType) => void

export default function useSnapshot({ editable }: { editable: boolean }) {
  const { setCanRedo, setCanUndo } = useContext(MainContext).reducers
  const [index, setIndex] = useState(0)
  const [count, setCount] = useState(0)

  useEffect(() => {
    if (!editable) return
    setCanUndo?.(index > 0)
    setCanRedo?.(count > 1 && (index < count - 1))
  }, [index, count, setCanRedo, setCanUndo, editable])

  const handleGetCurrentData = useCallback(async (index: number): Promise<SlideData | void> => {
    const list = await db.snapshot.offset(index).limit(1).toArray()
    if (!list.length) return
    return JSON.parse(list[0].data)
  }, [])

  const checkLimit = useCallback(async () => {
    const limit = 30
    const count = await db.snapshot.count()
    if (count > limit - 1) {
      await db.snapshot.limit(count - limit + 1).delete()
    }
    return Math.min(count, limit - 1)
  }, [])

  const onSnapshotSave = useCallback<ISnapshotSave>(async (data, type = 'slide') => {
    let count = await checkLimit()

    count -= await db.snapshot.offset(index + 1).delete()
    
    await db.snapshot.add({
      data: JSON.stringify(data),
      time_at: Date.now(),
      type,
    })

    setIndex(count)
    setCount(count + 1)
  }, [checkLimit, index])

  const onSnapshotInit = useCallback(async (data: SlideData) => {
    await db.snapshot.clear()

    setIndex(0)

    await db.snapshot.add({
      data: JSON.stringify(data),
      time_at: Date.now(),
      type: 'slide',
    })

    setCount(1)
  }, [])

  const onSnapshotUndo = useCallback(async () => {
    if (index == 0) return
    const _index = index - 1
    setIndex(_index)
    return await handleGetCurrentData(_index)
  }, [index, handleGetCurrentData])

  const onSnapshotRedo = useCallback(async () => {
    const count = await db.snapshot.count()
    if (index == count - 1) return
    const _index = index + 1
    setIndex(_index)
    return await handleGetCurrentData(_index)
  }, [index, handleGetCurrentData])

  return {
    onSnapshotSave,
    onSnapshotInit,
    onSnapshotUndo,
    onSnapshotRedo,
  }
}