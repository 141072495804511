import { useEffect, useState, useContext } from 'react';
import { Button, Table, Tag } from '@arco-design/web-react';

import { formatDate } from '@/utils';

import { getLayoutList } from '@/api/layout';
import { GlobalContext } from '@/hooks/context';

import EditLayoutModal from './component/editLayoutModal';

const LayoutList = () => {
	const { cdnUrl } = useContext(GlobalContext);

	const columns: any = [
		{
			title: '示意图',
			dataIndex: 'preview',
			fixed: 'left',
			width: 120,
			render: (_: any, record: any) => {
				if (record.preview) {
					return <img style={{ width: '80px' }} src={cdnUrl + record.preview} />;
				}
				return null;
			}
		},
		{
			title: '版式名称',
			key: 'name',
			dataIndex: 'name'
		},
		{
			title: '是否用于文档美化',
			key: 'optimizes',
			dataIndex: 'optimizes',
			render: (_: any, record: any) => {
				if (record.optimizes) {
					return <Tag color="#00b42a">是</Tag>;
				}
				return <Tag>否</Tag>;
			}
		},
		{
			title: '修改人',
			dataIndex: 'modifierName'
		},
		{
			title: '修改时间',
			key: 'updateTime',
			render: (_: any, record: any) => <>{record.updateTime ? formatDate(record.updateTime) : '--'}</>
		},
		{
			title: '操作',
			key: 'action',
			fixed: 'right',
			width: 120,
			render: (_: any, record: any) => (
				<Button
					type="text"
					onClick={() => {
						setShowEditModalInfo(record);
						setShowEditModal(true);
					}}>
					编辑
				</Button>
			)
		}
	];

	const [loading, setLoading] = useState(false);
	const [list, setList] = useState<any[]>([]);

	const [showModal, setShowEditModal] = useState(false);
	const [showModalInfo, setShowEditModalInfo] = useState('');
	const getList = async () => {
		try {
			setLoading(true);
			const res = await getLayoutList();
			setList(res.data);
			console.log(res);
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getList();
	}, []);
	return (
		<>
			<div className="page-warp">
				<Table borderCell rowKey={(record) => record.id} loading={loading} columns={columns} data={list} pagination={false} />
			</div>
			<EditLayoutModal info={showModalInfo} visible={showModal} setVisible={setShowEditModal} onCallback={getList} />
		</>
	);
};

export default LayoutList;
