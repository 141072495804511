import { App, IUI } from 'leafer-ui'
import { Button } from '@arco-design/web-react'
import { useMemo, ReactNode, useEffect, useState, useRef } from 'react'

import ImageHandle, { replaceFillImage, checkReplaceImage } from '../Editor/utils/image'
import { ThemeValue } from '@/types/element'
import EditTextButton from './Buttons/EditText'
import EditTextAlign from './Buttons/TextAlign'
import EditFontSize from './Buttons/FontSize'
import EditFontColor from './Buttons/FontColor'
import EditTextBackground from './Buttons/TextBackgrond'

import './style.less'

type ImageCallBack = (u: string) => void

export interface PopoverToolProps {
  target: IUI
  canvas: HTMLCanvasElement
  app: App
  onUploadImage?(target: IUI, cb: ImageCallBack): void
  imageUploadRender?(opt: { replace: ReactNode, target?: IUI | null, cb: ImageCallBack }): ReactNode
  imageHandle: ImageHandle
  theme: ThemeValue['color']
}

export default function PopoverTool({ target, canvas, imageUploadRender, app, imageHandle, theme }: PopoverToolProps) {
  const { x, y } = target.getBounds()
  const elementType = useMemo(() => target.data?.type, [target])
  const canReplace = useMemo(() => checkReplaceImage(target), [target])
  const [left, setLeft] = useState(Math.max(x, 0))
  const [top, setTop] = useState(Math.max(y - 40, 0))
  const popupRef = useRef<HTMLDivElement | null>(null)
  const [popupWidth, setPopupWidth] = useState(0)

  const ImageButton = useMemo(() => <Button
    type='text'
    size='small'
  >
    {/* <PictureOne /> */}
    替换
  </Button>, [])

  const imageCallback = useMemo(() => {
    return async (url: string = '') => {
      await replaceFillImage(url, target, imageHandle)
      app.emit('changeData')
    }
  }, [target, app, imageHandle])

  useEffect(() => {
    const listener = () => {
      const { x, y } = target.getBounds()

      setLeft(Math.max(x, 0))
      setTop(Math.max(y - 40, 0))
    }
    target && target.on('drag', listener)
    return () => {
      target.off('drag', listener)
    }
  }, [target, canvas])

  useEffect(() => {
    setPopupWidth(popupRef.current?.offsetWidth || 0)
  }, [popupRef])

  return <div
    className='ppt-editor-popover-tool'
    ref={popupRef}
    style={{
      left: Math.min(left, canvas.offsetWidth - popupWidth),
      top,
    }}
  >
    {
      elementType == 'text' ? <>
        {target.data?.text.length ? <EditTextButton target={target} app={app} /> : null}
        {target.data?.text.length ? <EditFontSize target={target} app={app} /> : null}
        {target.data?.text.length ? <EditFontColor target={target} app={app} theme={theme} /> : null}
        <EditTextBackground target={target} app={app} theme={theme} imageHandle={imageHandle} />
        {target.data?.text.length ? <EditTextAlign target={target} app={app} /> : null}
      </> : null
    }
    {
      canReplace ? (imageUploadRender ? imageUploadRender({
        replace: ImageButton,
        target,
        cb: imageCallback,
      }) : null) : null
    }
  </div>
}