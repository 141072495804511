import { Text, registerUI, ITextInputData, ITextData } from 'leafer-ui';

@registerUI()
export default class CustomText extends Text {
	public get __tag() {
		return 'Text';
	}

	public declare __: ITextData;

	constructor(data: ITextInputData) {
		super(data);
	}

	// __draw(_canvas: ILeaferCanvas, _options: IRenderOptions): void {
	//   const { context } = _canvas
	//   const { width = 0, height = 0, text, x = 0, y = 0, fontSize = 0, fontFamily, fill } = this.__
	//   console.log(text)

	//   // _canvas.setStrokeOptions(this.__)  // 绘制描边前，需要设置一下描边选项（可选）。

	//   if (fill) context.fillStyle = fill
	//   // context.fillRect(0, 0, width / 2, height)

	//   // context.strokeStyle = 'blue'
	//   // context.strokeRect(width / 2, 0.5, width / 2, height - 1)
	//   context.font = `${fontSize}px ${fontFamily}`
	//   context.fillText(text || '', x, y, width)
	// }
}
