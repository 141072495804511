import { useState, useContext, useEffect } from 'react';
import { Modal, Message, Form, Input, Radio } from '@arco-design/web-react';
import { IconPlus } from '@arco-design/web-react/icon';
import uploadFile, { typeEnum } from '@/utils/uploadFileOss';
import { GlobalContext } from '@/hooks/context';

import { putLayoutEdit } from '@/api/layout';

import './editModal.less';

export default (props: any) => {
	const { info, visible, setVisible, onCallback } = props;
	const [form] = Form.useForm();
	const FormItem = Form.Item;
	const { cdnUrl } = useContext(GlobalContext);

	const [loading, setLoading] = useState(false);
	const [previewUrl, setPreviewUrl] = useState('');

	// 上传图片
	const handleUpPreview = async (e: Event) => {
		try {
			const el: any = e.target as HTMLInputElement;
			const file = el.files[0];
			if (file) {
				const info: any = await uploadFile(file, typeEnum.preview);
				setPreviewUrl(info.key);
				form.setFieldsValue({
					preview: info.key
				});
			}
		} catch (error: any) {
			console.log(error);
			Message.error(error?.data?.msg || '上传失败');

			const file: any = document.getElementById('upload-file');
			file.outerHTML = file.outerHTML;
		}
	};

	const handleSave = async () => {
		try {
			const values = await form.validate();

			if (values.optimizes && previewUrl == '') {
				Message.error('选择美化推荐，请上传示意图');
				return false;
			}

			setLoading(true);
			const res = await putLayoutEdit(info.id, {
				optimizes: values.optimizes ? 1 : 0,
				preview: previewUrl
			});
			setLoading(false);
			if (res.code == 200) {
				Message.success('更新成功');
				onCallback();
				hamdleCancel();
			}
		} catch (error) {}
	};
	const hamdleCancel = () => {
		setVisible(false);
		setPreviewUrl('');
		setLoading(false);
		form.resetFields();
	};

	useEffect(() => {
		if (visible && info) {
			form.setFieldsValue({
				name: info.name,
				preview: info.preview,
				optimizes: info.optimizes
			});
			if (info.preview.indexOf('?') > -1) {
				setPreviewUrl(info.preview.split('?')[0]);
			} else {
				setPreviewUrl(info.preview);
			}
		}
	}, [visible]);

	return (
		<Modal
			wrapClassName="edit-template-modal"
			title="版式结构信息编辑"
			visible={visible}
			confirmLoading={loading}
			onOk={handleSave}
			onCancel={hamdleCancel}
			autoFocus={false}
			focusLock={true}
			style={{ width: '540px' }}>
			<Form form={form} size="large">
				<FormItem label="版式名称" field="name" rules={[{ required: true, message: '请输入名称' }]}>
					<Input placeholder="请输入模板名称" disabled />
				</FormItem>

				<FormItem label="示意图" field="preview">
					<div className="edit-template-btn">
						<input
							className="upload-file"
							type="file"
							onChange={(e: any) => handleUpPreview(e)}
							name=""
							id="upload-file"
						/>
						{previewUrl ? (
							<img src={cdnUrl + previewUrl + '?x-oss-process=image/resize,m_fill,w_480,h_270'} alt="" />
						) : (
							<span>
								<IconPlus />
								上传图片
							</span>
						)}
					</div>
				</FormItem>
				<FormItem label="文档美化" field="optimizes" rules={[{ required: true }]}>
					<Radio.Group>
						<Radio value={1}>是</Radio>
						<Radio value={0}>否</Radio>
					</Radio.Group>
				</FormItem>
			</Form>
		</Modal>
	);
};
