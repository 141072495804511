import { useRoutes, RouteObject, Outlet } from 'react-router-dom';

import Default from '@/layout/default';
import Layout from '@/layout';
import Home from '@/views/dashboard';

import MaterialList from '@/views/material/list';
import MaterialCate from '@/views/material/cate';

import ThemeList from '@/views/themes/list';
import ThemeInfo from '@/views/themes/info';
import ThemeColors from '@/views/themes/colors';
import ThemeColorInfo from '@/views/themes/colors/info';
import ThemeFonts from '@/views/themes/fonts';
import ThemeFontInfo from '@/views/themes/fonts/info';

import ResourceFonts from '@/views/resource/fonts';
import ResourceHotWorks from '@/views/resource/hotWorks';
import ResourceGallery from '@/views/resource/gallery';

import Customer from '@/views/customer/list';

// 官方版式库 -------------------------
import LayoutSlides from '@/views/layout/slides';
import LayoutSlideList from '@/views/layout/slides/slideList';

import LayoutTemplate from '@/views/layout/template';
import TemplateSlides from '@/views/layout/template/slideList';

import TempType from '@/views/layout/setting/type';
import SlideStyle from '@/views/layout/setting/style';
import SlideStructure from '@/views/layout/setting/structure';
import SlideColors from '@/views/layout/setting/colors';
import SlideIndustry from '@/views/layout/setting/industry';

// -------------------------

// 活动
import ActivityList from '@/views/activity/list';

import SystemManage from '@/views/system/manage';
import OrderList from '@/views/order';

import Editor from '@/views/editor';

import Login from '@/views/login';
import Error404 from '@/views/error/404';

import Demo from '@/views/demo';

const layoutRouter: Array<RouteObject> = [
	{
		index: true,
		path: '',
		element: <Home />
	},
	// 素材管理
	{
		path: 'material',
		element: <Outlet />,
		children: [
			{
				path: 'list',
				element: <MaterialList />
			},
			{
				path: 'cate',
				element: <MaterialCate />
			}
		]
	},
	// 官方版式库
	{
		path: 'layout',
		element: <Outlet />,
		children: [
			{
				path: 'slides',
				element: <Outlet />,
				children: [
					{
						path: ':libId',
						element: <LayoutSlideList />
					},
					{
						path: '',
						element: <LayoutSlides />
					}
				]
			},
			{
				path: 'template',
				element: <Outlet />,
				children: [
					{
						path: ':tempId',
						element: <TemplateSlides />
					},
					{
						path: '',
						element: <LayoutTemplate />
					}
				]
			},
			{
				path: 'temp-type',
				element: <TempType />
			},
			{
				path: 'style',
				element: <SlideStyle />
			},
			{
				path: 'structure',
				element: <SlideStructure />
			},
			{
				path: 'colors',
				element: <SlideColors />
			},
			{
				path: 'industry',
				element: <SlideIndustry />
			}
		]
	},
	// 主题管理
	{
		path: 'theme',
		element: <Outlet />,
		children: [
			{
				path: 'list',
				element: <ThemeList />
			},
			{
				path: 'add',
				element: <ThemeInfo />
			},
			{
				path: 'edit/:id',
				element: <ThemeInfo />
			},
			{
				path: 'colors',
				element: <Outlet />,
				children: [
					{
						path: '',
						element: <ThemeColors />
					},
					{
						path: 'add',
						element: <ThemeColorInfo />
					},
					{
						path: 'edit/:id',
						element: <ThemeColorInfo />
					}
				]
			},
			{
				path: 'fonts',
				element: <Outlet />,
				children: [
					{
						path: '',
						element: <ThemeFonts />
					},
					{
						path: 'add',
						element: <ThemeFontInfo />
					},
					{
						path: 'edit/:id',
						element: <ThemeFontInfo />
					}
				]
			}
		]
	},

	// 资源管理
	{
		path: 'resource',
		element: <Outlet />,
		children: [
			{
				path: 'fonts',
				element: <ResourceFonts />
			},
			{
				path: 'hotWorks',
				element: <ResourceHotWorks />
			},
			{
				path: 'gallery',
				element: <ResourceGallery />
			}
		]
	},

	// 用户管理
	{
		path: 'customer',
		element: <Customer />
	},
	// 订单管理
	{
		path: 'order',
		element: <OrderList />
	},
	// 活动管理
	{
		path: 'activity',
		element: <ActivityList />
	},
	// 系统管理
	{
		path: 'system',
		element: <Outlet />,
		children: [
			{
				path: 'manage',
				element: <SystemManage />
			}
		]
	}
];

const routes: Array<RouteObject> = [
	{
		path: '/',
		element: <Default />,
		children: [
			{
				path: '',
				element: <Layout />,
				children: layoutRouter
			},
			// 编辑器
			{
				path: 'editor',
				element: <Outlet />,
				children: [
					// 版式
					{
						path: 'slide/:id',
						element: <Editor />
					},
					// 版式库
					{
						path: 'layout/add',
						element: <Editor />
					},
					{
						path: 'layout/edit/:id',
						element: <Editor />
					},
					{
						path: 'layout/append/:id',
						element: <Editor />
					},
					// 模板
					{
						path: 'template/add',
						element: <Editor />
					},
					{
						path: 'template/edit/:id',
						element: <Editor />
					},
					{
						path: 'template/append/:id',
						element: <Editor />
					}
				]
			},
			// demo
			{
				path: 'demo',
				element: <Demo />
			},
			{
				path: '/404',
				element: <Error404 />
			}
		]
	},
	{
		path: 'login',
		element: <Login />
	}
];

const Router = () => {
	let element = useRoutes(routes);
	return element;
};

export default Router;
